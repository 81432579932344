import React from 'react'
import { toAbsoluteUrl } from '../AssetHelpers'
type Props = {
  className?: string
  path: string
  alt?: string
}

const CircleIcon: React.FC<Props> = ({ className = '', path, alt = '' }) => {
  return (
    <img
      src={toAbsoluteUrl(path)} alt={alt}
      className={`rounded-circle ${className}`} />
  )
}

export { CircleIcon }
