/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useMemo} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {CircleIcon} from '../../../../_metronic/helpers/components/CircleIcon'
import {Blockchains} from '../../../enums'
import Skeleton from '../../../../_metronic/helpers/components/Skeleton'
import {toPercentageString, toPrettyString} from './ScoringFormatter'
import clsx from 'clsx'
import {Column, useTable, ColumnInstance, useSortBy} from 'react-table'
import {ContractModel} from '../../project/models/ContractModel'
import {ScorableModel} from '../../project/models/ScorableModel'
import {ScorableType} from '../../../enums/ScorableType'

type Props = {
  data?: Array<ScorableModel>
  type: ScorableType
}

type ColumnWithClassName = {className?: string} & Column<ScorableModel>
type ColumnInstanceWithClassName = {className?: string} & ColumnInstance<ScorableModel>

const ScoringTable: FC<Props> = ({data = [], type}) => {
  const columns: ColumnWithClassName[] = useMemo(() => {
    const firstPart: ColumnWithClassName[] = [
      {
        accessor: 'name',
        Header: 'Project',
        width: 300,
        Cell: (params) => (
          <>
            <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
              <Link to={toAbsoluteUrl(`/projects/${params.row.original.path}`)}>
                <div
                  className='symbol-label'
                  data-bs-toggle='tooltip'
                  data-bs-placement='right'
                  data-bs-trigger='hover'
                  data-bs-original-title={params.row.original.name}
                >
                  <CircleIcon
                    path={`/media/img/${params.row.original.image}`}
                    alt={params.row.original.name}
                    className='w-100'
                  />
                </div>
              </Link>
            </div>
            <div className='d-flex flex-column'>
              <Link
                to={toAbsoluteUrl(`/projects/${params.row.original.path}`)}
                className='text-gray-800 text-hover-primary mb-1'
              >
                {params.row.original.name}
              </Link>
            </div>
          </>
        ),
        className: 'd-flex align-items-center',
        sortType: 'basic',
      },
    ]

    const chainsColumn: ColumnWithClassName = {
      accessor: 'contracts',
      Header: 'Chains',
      width: 250,
      Cell: (params) => (
        <div className='symbol-group symbol-hover fs-8'>
          {params.row.original.contracts.map((blockchainContract: ContractModel, index: number) => {
            const blockchain = Blockchains.find(
              (blockchain) => blockchain.id === blockchainContract.blockchainId
            )!
            return (
              <div
                key={index}
                className='symbol symbol-25px symbol-circle'
                data-bs-toggle='tooltip'
                title=''
                data-bs-original-title={blockchain.name}
              >
                <img alt={blockchain.name} src={toAbsoluteUrl(`/media/img/${blockchain.image}`)} />
              </div>
            )
          })}
        </div>
      ),
      disableSortBy: true,
    }

    const secondPart: ColumnWithClassName[] = [
      {
        accessor: 'totalBitcoinLocked',
        Header: 'TBL',
        width: 100,
        Cell: (params) => {
          const btcLocked = toPrettyString(params.row.original.totalBitcoinLocked.btcLocked)

          if (btcLocked.loading) {
            return <Skeleton />
          }

          return <>{`${btcLocked.number!.toString()}${btcLocked.suffix}`}</>
        },
        sortType: (rowA, rowB) =>
          rowB.original.totalBitcoinLocked.loading ||
          rowB.original.totalBitcoinLocked.btcLocked === undefined ||
          rowB.original.totalBitcoinLocked.btcLocked === null ||
          (!rowA.original.totalBitcoinLocked.loading &&
            rowA.original.totalBitcoinLocked.btcLocked !== undefined &&
            rowA.original.totalBitcoinLocked.btcLocked !== null &&
            rowA.original.totalBitcoinLocked.btcLocked > rowB.original.totalBitcoinLocked.btcLocked)
            ? 1
            : -1,
      },
      {
        accessor: 'path',
        Header: 'TBL (USD)',
        width: 100,
        Cell: (params) => {
          const btcLockedInUsd = toPrettyString(
            params.row.original.totalBitcoinLocked.btcLockedInUsd
          )

          if (btcLockedInUsd.loading) {
            return <Skeleton />
          }

          return <>{`${btcLockedInUsd.number!.toString()}${btcLockedInUsd.suffix}`}</>
        },
        sortType: (rowA, rowB) =>
          rowB.original.totalBitcoinLocked.loading ||
          rowB.original.totalBitcoinLocked.btcLockedInUsd === undefined ||
          rowB.original.totalBitcoinLocked.btcLockedInUsd === null ||
          (!rowA.original.totalBitcoinLocked.loading &&
            rowA.original.totalBitcoinLocked.btcLockedInUsd !== undefined &&
            rowA.original.totalBitcoinLocked.btcLockedInUsd !== null &&
            rowA.original.totalBitcoinLocked.btcLockedInUsd >
              rowB.original.totalBitcoinLocked.btcLockedInUsd)
            ? 1
            : -1,
      },
    ]

    const tvlColumn: ColumnWithClassName = {
      accessor: 'totalValueLocked',
      Header: 'TVL',
      width: 100,
      Cell: (params) => {
        const tvl = toPrettyString(params.row.original.totalValueLocked.tvl)

        if (tvl.loading) {
          return <Skeleton />
        }

        return <>{`${tvl.number!.toString()}${tvl.suffix}`}</>
      },
      sortType: (rowA, rowB) =>
        rowB.original.totalValueLocked.loading ||
        rowB.original.totalValueLocked.tvl === undefined ||
        rowB.original.totalValueLocked.tvl === null ||
        (!rowA.original.totalValueLocked.loading &&
          rowA.original.totalValueLocked.tvl !== undefined &&
          rowA.original.totalValueLocked.tvl !== null &&
          rowA.original.totalValueLocked.tvl > rowB.original.totalValueLocked.tvl)
          ? 1
          : -1,
    }

    const thirdPart: ColumnWithClassName[] = [
      {
        accessor: 'reddit',
        Header: () => <div className='text-end'>TBL vol mov (24 H)</div>,
        width: 110,
        Cell: (params) => {
          const btcLockedEvolutionFromYesterday = toPercentageString(
            params.row.original.totalBitcoinLocked.btcLockedEvolutionFromYesterday
          )

          if (btcLockedEvolutionFromYesterday.loading) {
            return <Skeleton />
          }

          const number = btcLockedEvolutionFromYesterday.number!

          return (
            <>
              <KTSVG
                path={`/media/icons/duotune/arrows/${
                  number > 0 ? 'arr066.svg' : number < 0 ? 'arr065.svg' : 'arr090.svg'
                }`}
                className={clsx(
                  'svg-icon-3',
                  number > 0
                    ? 'svg-icon-success'
                    : number < 0
                    ? 'svg-icon-danger'
                    : 'svg-icon-alternative',
                  'me-2'
                )}
              />
              {Math.abs(number).toString() + btcLockedEvolutionFromYesterday.suffix}
            </>
          )
        },
        className: 'text-end',
        sortType: (rowA, rowB) =>
          rowB.original.totalBitcoinLocked.loading ||
          rowB.original.totalBitcoinLocked.btcLockedEvolutionFromYesterday === undefined ||
          rowB.original.totalBitcoinLocked.btcLockedEvolutionFromYesterday === null ||
          (!rowA.original.totalBitcoinLocked.loading &&
            rowA.original.totalBitcoinLocked.btcLockedEvolutionFromYesterday !== undefined &&
            rowA.original.totalBitcoinLocked.btcLockedEvolutionFromYesterday !== null &&
            rowA.original.totalBitcoinLocked.btcLockedEvolutionFromYesterday >
              rowB.original.totalBitcoinLocked.btcLockedEvolutionFromYesterday)
            ? 1
            : -1,
      },
    ]

    if (type === ScorableType.Blockchain) {
      return [...firstPart, ...secondPart, ...thirdPart]
    }

    return [...firstPart, chainsColumn, ...secondPart, tvlColumn, ...thirdPart]
  }, [type])

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: 'totalBitcoinLocked',
            desc: true,
          },
        ],
      },
    },
    useSortBy
  )

  return (
    <div className='table-scoring overflow-hidden'>
      {/* begin::Table */}
      <table
        {...getTableProps({className: 'table align-middle table-row-dashed fs-6 gy-5 no-footer'})}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps({
                className: 'text-start text-muted fw-bolder fs-7 text-uppercase gs-0',
              })}
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps([
                    column.getSortByToggleProps(),
                    {className: `w-${column.width}px`},
                  ])}
                >
                  {column.render('Header')}
                  {column.isSorted && (
                    <span>
                      {column.isSortedDesc ? (
                        <KTSVG className='px-3' path='/media/icons/duotune/arrows/arr072.svg' />
                      ) : (
                        <KTSVG className='px-3' path='/media/icons/duotune/arrows/arr073.svg' />
                      )}
                    </span>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps({className: 'text-gray-600 fw-bold'})}>
          {rows.map((row) => {
            prepareRow(row)

            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps({
                      className: (cell.column as ColumnInstanceWithClassName).className,
                    })}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </table>
      {/* end::Table */}
    </div>
  )
}

export {ScoringTable}
