import axios from 'axios'
import {PeriodEnum} from '../../../enums/PeriodEnum'
import {ScorableId} from '../../../enums/ScorableId'
import {LogoScorableModel} from '../../home/models/LogoScorableModel'
import {PeriodModel} from '../../project/models/PeriodModel'
import {InitialDataModel} from '../../project/models/InitialDataModel'

const API_URL = process.env.REACT_APP_API_URL || 'api'
const CONTROLLER = `Scoring`

export const GET_INITIAL_DATA_URL = `${API_URL}/${CONTROLLER}/getInitialData`
export const GET_MORE_DATA_URL = `${API_URL}/${CONTROLLER}/getMoreData`
export const GET_LOGO_DATA_URL = `${API_URL}/${CONTROLLER}/getLogoData`
export const GET_STORIES_BY_SCORABLE_AND_PERIOD_URL = `${API_URL}/${CONTROLLER}/getStoriesByScorableAndPeriod`

export function getInitialData() {
  return axios.get<InitialDataModel>(GET_INITIAL_DATA_URL)
}

export function getMoreData() {
  return axios.get<InitialDataModel>(GET_MORE_DATA_URL)
}

export function getLogoData() {
  return axios.get<Array<LogoScorableModel>>(GET_LOGO_DATA_URL)
}

export function getStoriesByScorableAndPeriod(scorableId: ScorableId, period: PeriodEnum) {
  return axios.get<PeriodModel>(GET_STORIES_BY_SCORABLE_AND_PERIOD_URL, {
    params: {scorableId, period},
  })
}
