import {call, put, takeLatest} from 'redux-saga/effects'
import {IActionWithPayload} from '../../../../interfaces/IActionWithPayload'
import {GitHubEventsModel} from '../models/GitHubEventsModel'
import {getEvents} from './GitHubApi'
import {ScorableId} from '../../../../enums/ScorableId'
import {GitHubEventsReduxModel} from '../models/GitHubEventsReduxModel'

export const actionTypes = {
  GitHubEventsRequested: 'GITHUB_EVENTS_REQUESTED',
  GitHubEventsReceived: 'GITHUB_EVENTS_RECEIVED',
  GitHubEventsFailed: 'GITHUB_EVENTS_FAILED',
}

const initialState: GitHubEventsReduxModel = {
  events: [],
  loading: true,
}

export const reducer = (
  state: GitHubEventsReduxModel = initialState,
  action: IActionWithPayload<any>
) => {
  switch (action.type) {
    case actionTypes.GitHubEventsRequested: {
      return {
        ...state,
        events: [],
        loading: true,
      }
    }

    case actionTypes.GitHubEventsReceived: {
      const events = action.payload
      return {
        ...state,
        events,
        loading: false,
      }
    }

    case actionTypes.GitHubEventsFailed: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    }

    default:
      return state
  }
}

export const actions = {
  requestGitHubEvents: (scorableId: ScorableId) => ({
    type: actionTypes.GitHubEventsRequested,
    payload: {scorableId},
  }),
  receiveGitHubEvents: (events: GitHubEventsModel[]) => ({
    type: actionTypes.GitHubEventsReceived,
    payload: events,
  }),
  gitHubEventsFailed: (message: string) => ({
    type: actionTypes.GitHubEventsFailed,
    payload: message,
  }),
}

function* fetchGitHubEvents(action: any) {
  try {
    const {data: gitHubEvents} = yield call(getEvents, action.payload.scorableId)
    yield put(actions.receiveGitHubEvents(gitHubEvents))
  } catch (e: any) {
    console.log(e)
    yield put(actions.gitHubEventsFailed(e.message))
  }
}

export function* watchGetGitHubEventsAsync() {
  yield takeLatest(actionTypes.GitHubEventsRequested, fetchGitHubEvents)
}
