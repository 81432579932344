/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { RoadmapPage } from '../../modules/roadmap/components/RoadmapPage'

const RoadmapWrapper: FC = () => {
  return (
    <>
      {/* begin::Post */}
      <RoadmapPage />
      {/* end::Post */}
    </>
  )
}

export { RoadmapWrapper }
