import React, {FC} from 'react'
import {ScoringModel} from '../../models/ScoringModel'

type Props = {
  scoring: ScoringModel
}

const ScoringPieDetails: FC<Props> = ({scoring}) => {
  return (
    <>
      <div className='col-sm-6'>
        <div className='d-flex align-items-sm-center mb-1'>
          <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
            <div className='flex-grow-1 me-2'>
              <div className='text-gray-800 fs-8'>
                <span className='badge badge-light fw-bolder my-1 me-5 bg-baiColor min-w-40px'>
                  {scoring.bitcoinAffinityIndex.value}
                </span>
                Bitcoin Affinity Index
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex align-items-sm-center mb-1'>
          <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
            <div className='flex-grow-1 me-2'>
              <div className='text-gray-800 fs-8'>
                <span className='badge badge-light fw-bolder my-1 me-5 bg-siColor min-w-40px'>
                  {scoring.securityIndex.value}
                </span>
                Security Index
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex align-items-sm-center mb-1'>
          <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
            <div className='flex-grow-1 me-2'>
              <div className='text-gray-800 fs-8'>
                <span className='badge badge-light fw-bolder my-1 me-5 bg-diColor min-w-40px'>
                  {scoring.decentralizationIndex.value}
                </span>
                Decentralization Index
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex align-items-sm-center mb-1'>
          <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
            <div className='flex-grow-1 me-2'>
              <div className='text-gray-800 fs-8'>
                <span className='badge badge-light fw-bolder my-1 me-5 bg-piColor min-w-40px'>
                  {scoring.privacyIndex.value}
                </span>
                Privacy Index
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='col-sm-6'>
        <div className='d-flex align-items-sm-center mb-1'>
          <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
            <div className='flex-grow-1 me-2'>
              <div className='text-gray-800 fs-8'>
                <span className='badge badge-light fw-bolder my-1 me-5 bg-sciColor min-w-40px'>
                  {scoring.scalabilityIndex.value}
                </span>
                Scalability Index
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex align-items-sm-center mb-1'>
          <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
            <div className='flex-grow-1 me-2'>
              <div className='text-gray-800 fs-8'>
                <span className='badge badge-light fw-bolder my-1 me-5 bg-fiColor min-w-40px'>
                  {scoring.fairnessIndex.value}
                </span>
                Fairness Index
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex align-items-sm-center mb-1'>
          <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
            <div className='flex-grow-1 me-2'>
              <div className='text-gray-800 fs-8'>
                <span className='badge badge-light fw-bolder my-1 me-5 bg-eiColor min-w-40px'>
                  {scoring.economicIndex.value}
                </span>
                Economic Index
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex align-items-sm-center mb-1'>
          <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
            <div className='flex-grow-1 me-2'>
              <div className='text-gray-800 fs-8'>
                <span className='badge badge-light fw-bolder my-1 me-5 bg-ctiColor min-w-40px'>
                  {scoring.communityTractionIndex.value}
                </span>
                Community Traction Index
              </div>
            </div>
          </div>
        </div>
        {/* <div className='d-flex align-items-sm-center mb-1'>
          <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
            <div className='flex-grow-1 me-2'>
              <div className='text-gray-800 fs-8'>
                <span className='badge badge-light fw-bolder my-1 me-5 bg-oiColor min-w-40px'>
                  {scoring.oracleIndex.value}
                </span>
                Oracle Index
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  )
}

export default ScoringPieDetails
