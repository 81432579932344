/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {SocketStatus} from '../../../app/modules/scoring/components/SocketStatus'
import {useLayout} from '../core'

const Footer: FC = () => {
  const {classes} = useLayout()

  return (
    <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        {/* begin::Copyright */}
        <div className='text-dark mb-2'>
          <span className='text-muted fw-bold me-2'>{`${new Date().getFullYear()} \u00a9 Defi4Bitcoin`}</span>
        </div>
        {/* end::Copyright */}
        {/* begin::Connection status */}
        <div className='d-flex text-white justify-content-sm-end mb-2'>
          <SocketStatus />
        </div>
        {/* end::Connection status */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export {Footer}
