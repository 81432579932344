import {FC} from 'react'
import {ScorableModel} from '../../models/ScorableModel'

const About: FC<{scorable: ScorableModel}> = ({scorable}) => (
  <div className='card card-flush mb-5'>
    <div className='card-header mt-6'>
      <div className='card-title flex-column'>
        <h3 className='fw-bolder mb-1'>Project Summary</h3>
        <div className='fs-6 fw-bold text-gray-400'>What is {scorable.name}?</div>
      </div>
    </div>
    <div className='card-body p-9 pt-5'>
      <div className='fs-6 text-gray-700'>
        <h5>About</h5>
        <p>{scorable.about}</p>
      </div>
    </div>
  </div>
)

export default About
