import {LogoScorableModel} from '../models/LogoScorableModel'

export class HomeTableMock {
  public static table: Array<LogoScorableModel> = [
    {
      name: 'Aave',
      image: 'aave.svg',
    },
    {
      name: 'Compound',
      image: 'compound.svg',
    },
    {
      name: 'Aave',
      image: 'aave.svg',
    },
    {
      name: 'Compound',
      image: 'compound.svg',
    },
    {
      name: 'Aave',
      image: 'aave.svg',
    },
    {
      name: 'Compound',
      image: 'compound.svg',
    },
    {
      name: 'Aave',
      image: 'aave.svg',
    },
    {
      name: 'Compound',
      image: 'compound.svg',
    },
    {
      name: 'Aave',
      image: 'aave.svg',
    },
    {
      name: 'Compound',
      image: 'compound.svg',
    },
  ]
}
