/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {Formik, Form} from 'formik'
import Input from './Input'
import Textarea from './Textarea'
import * as yup from 'yup'
import {KTSVG} from '../../../../_metronic/helpers'
import clsx from 'clsx'
import {sendMessage} from '../redux/ContactApi'
import {logAxiosError} from '../../../utils/errors'

interface FormValues {
  fullName: string
  email: string
  message: string
}

const validationSchema = yup.object().shape({
  fullName: yup.string().required('Full name is required'),
  email: yup.string().email('Invalid email address').required('Email is required'),
  message: yup.string().required('Message is required'),
})

const mail: string = process.env.REACT_APP_MAIL!

const initialValues: FormValues = {fullName: '', email: '', message: ''}

const ContactPage: FC = () => {
  const [modal, setModal] = useState('')

  return (
    <div className='card'>
      <div className='card-body p-lg-20'>
        <div className='mb-17'>
          <h3 className='text-black mb-7'>Contact Us</h3>
          <div className='separator separator-dashed mb-9'></div>
          <div className='row'>
            <div className='col-md-6'>
              <div className='h-100 d-flex flex-column justify-content-between pe-lg-6 mb-lg-0 mb-10'>
                <div className='mb-5'>
                  <p className='fs-4 text-dark fw-bolder text-dark lh-base'>
                    You love them... Invest wisely
                  </p>
                  <div className='fw-bold fs-5 text-gray-600 text-dark mt-4'>
                    Proin efficitur ante nulla, ut ornare ligula tempor vel. Cras nec tincidunt mi.
                    Aliquam gravida mi eget vestibulum elementum. Vestibulum eu faucibus metus.
                    Morbi sodales neque placerat placerat placerat. Proin elit magna, finibus ut
                    erat viverra, mollis tempus diam. Etiam sit amet lorem vel eros fermentum
                    interdum non mollis ipsum. Etiam sit amet elementum nisl. Etiam nec arcu mauris.
                    Sed lobortis nibh sed risus tempor finibus.
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6 justify-content-between d-flex flex-column'>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (values, actions) => {
                  actions.setSubmitting(true)
                  sendMessage(values.fullName, values.email, values.message)
                    .then(() => {
                      setModal('success')
                      actions.setSubmitting(false)
                      actions.resetForm()
                    })
                    .catch(function (error) {
                      logAxiosError(error)
                      setModal('error')
                      actions.setSubmitting(false)
                      actions.resetForm()
                    })
                }}
              >
                {({isSubmitting, setFieldValue}) => (
                  <Form id='kt_inbox_compose_form'>
                    <div className='d-block'>
                      <div className='min-h-45px'>
                        <Input
                          name='fullName'
                          placeholder='Full name'
                          autoFocus={true}
                          icon={
                            <span className='svg-icon svg-icon-1'>
                              <KTSVG path='/media/svg/user.svg' />
                            </span>
                          }
                          disabled={isSubmitting}
                        />
                      </div>
                      <div className='min-h-45px mt-4'>
                        <Input
                          name='email'
                          placeholder='Email'
                          icon={
                            <span className='svg-icon svg-icon-1'>
                              <KTSVG path='/media/svg/atsign.svg' />
                            </span>
                          }
                          disabled={isSubmitting}
                        />
                      </div>
                      <div className='min-h-45px mt-4'>
                        <Textarea name='message' placeholder='Message' disabled={isSubmitting} />
                      </div>
                      <div className='d-flex mt-5 justify-content-end'>
                        <button type='submit' className='btn btn-secondary' disabled={isSubmitting}>
                          Send message
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <div
        className={clsx('modal fade', modal !== '' && 'show')}
        id='kt_modal_1'
        style={{backgroundColor: 'rgba(0,0,0, .5)'}}
      >
        <div className='modal-dialog text-center'>
          <div className='modal-content mt-20'>
            <div className='modal-body p-15'>
              <div>
                {modal === 'success' && (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 512 512'
                    fill='#50CD89'
                    style={{width: '90px', margin: '0 auto'}}
                  >
                    <path d='M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z' />
                  </svg>
                )}
                {modal === 'error' && (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 512 512'
                    fill='#f1462f'
                    style={{width: '90px', margin: '0 auto'}}
                  >
                    <path d='M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM232 152C232 138.8 242.8 128 256 128s24 10.75 24 24v128c0 13.25-10.75 24-24 24S232 293.3 232 280V152zM256 400c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 385.9 273.4 400 256 400z' />
                  </svg>
                )}
              </div>
              {modal === 'success' && (
                <p className='p-10 fs-5'>Your form has been successfully submitted!</p>
              )}
              {modal === 'error' && (
                <div className='p-10 fs-5'>
                  <p>Your form couldn't be submitted!</p>
                  <p>
                    Please, retry or contact us on{' '}
                    <a href={`mailto:${mail}`} target='_blank' rel='noreferrer'>
                      {mail}
                    </a>
                  </p>
                </div>
              )}
              <div>
                <button type='button' className='btn btn-primary' onClick={() => setModal('')}>
                  Ok, got it!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ContactPage}
