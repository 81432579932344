/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
//import { Timeline } from 'react-twitter-widgets';
import clsx from 'clsx'
import D4B from './d4b/D4B'
import News from './news/News'
import Overview from './overview/Overview'
import CountUp from 'react-countup'
import {RootState} from '../../../../setup'
import Skeleton from '../../../../_metronic/helpers/components/Skeleton'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Blockchains} from '../../../enums'
import {
  calculateFinancialValues,
  toPercentageString,
  toPrettyString,
} from '../../scoring/components/ScoringFormatter'
import {ScorableModel} from '../models/ScorableModel'
import {
  DiscordIcon,
  EmailIcon,
  FacebookIcon,
  ForumIcon,
  GitHubIcon,
  InstagramIcon,
  MediumIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  WebsiteIcon,
  YoutubeIcon,
} from './BrandsIcons'
import {toLocaleDateTimeShortString} from '../../../utils/date'
import {ScorableType} from '../../../enums/ScorableType'

type Props = {
  path: string
}

const ProjectPage: FC<Props> = ({path}) => {
  const [tab, setTab] = useState('Overview')
  const [scorable, setScorable] = useState<ScorableModel | null>(null)
  const scoring = useSelector((state: RootState) => state.scoring)

  useEffect(() => {
    if (!scoring) return

    const scorable = scoring.data.scorables.find((scorable) => scorable.path === path)!
    const formattedScorable = calculateFinancialValues(scorable, false)
    setScorable(formattedScorable)
  }, [path, scoring])

  if (!scorable) return null

  const tbl = toPrettyString(scorable.totalBitcoinLocked.btcLocked)
  const tvl = toPrettyString(scorable.totalValueLocked.tvl)
  const btcLockedEvolutionFromYesterday = toPercentageString(
    scorable.totalBitcoinLocked.btcLockedEvolutionFromYesterday
  )
  const tvlEvolutionFromYesterday = toPercentageString(
    scorable.totalValueLocked.tvlEvolutionFromYesterday
  )

  return (
    <>
      <div className='card mb-6 mb-xl-9'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-6'>
            <div className='d-flex flex-center flex-shrink-0 bg-light rounded w-100px h-100px w-lg-150px h-lg-150px me-7 mb-4'>
              <img
                className='mw-100px mw-lg-100px'
                src={toAbsoluteUrl(`/media/img/${scorable.image}`)}
                alt={scorable.name}
              />
            </div>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-1'>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href={`https://${scorable.website}`}
                      className='text-gray-800 text-hover-primary fs-2 fw-bolder me-3'
                    >
                      {scorable.name}
                    </a>
                    {scorable.totalBitcoinLocked?.fetchAt && (
                      <span className='badge badge-light-success me-auto'>{`Last Update ${toLocaleDateTimeShortString(
                        scorable.totalBitcoinLocked.fetchAt
                      )}`}</span>
                    )}
                  </div>
                </div>
                <div className='d-flex project-links flex-wrap'>
                  <WebsiteIcon scorable={scorable} />
                  <EmailIcon scorable={scorable} />
                  <MediumIcon scorable={scorable} />
                  <RedditIcon scorable={scorable} />
                  <DiscordIcon scorable={scorable} />
                  <TelegramIcon scorable={scorable} />
                  <YoutubeIcon scorable={scorable} />
                  <ForumIcon scorable={scorable} />
                  <InstagramIcon scorable={scorable} />
                  <FacebookIcon scorable={scorable} />
                  <TwitterIcon scorable={scorable} />
                  <GitHubIcon scorable={scorable} />
                </div>
              </div>
              <div className='d-flex flex-wrap justify-content-start'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 w-100 w-sm-auto'>
                    <div className='d-flex align-items-center'>
                      {btcLockedEvolutionFromYesterday.loading ? (
                        <Skeleton />
                      ) : (
                        <>
                          <KTSVG
                            path={`/media/icons/duotune/arrows/${
                              btcLockedEvolutionFromYesterday.number! > 0
                                ? 'arr066.svg'
                                : btcLockedEvolutionFromYesterday.number! < 0
                                ? 'arr065.svg'
                                : 'arr090.svg'
                            }`}
                            className={clsx(
                              'svg-icon-3',
                              btcLockedEvolutionFromYesterday.number! > 0
                                ? 'svg-icon-success'
                                : btcLockedEvolutionFromYesterday.number! < 0
                                ? 'svg-icon-danger'
                                : 'svg-icon-alternative',
                              'me-2'
                            )}
                          />
                          <CountUp
                            style={{fontSize: 'xx-small'}}
                            suffix={btcLockedEvolutionFromYesterday.suffix}
                            start={0}
                            end={Math.abs(btcLockedEvolutionFromYesterday.number!)}
                            decimals={2}
                          />
                        </>
                      )}
                      {tbl.loading ? (
                        <Skeleton />
                      ) : (
                        <CountUp
                          className='fs-4 fw-bolder'
                          suffix={tbl.suffix}
                          start={0}
                          end={tbl.number!}
                          decimals={2}
                        />
                      )}
                    </div>
                    <div className='fw-bold fs-6 text-gray-400'>TBL</div>
                  </div>
                  {scorable.type !== ScorableType.Blockchain && (
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 w-100 w-sm-auto'>
                      <div className='d-flex align-items-center'>
                        {tvlEvolutionFromYesterday.loading ? (
                          <Skeleton />
                        ) : (
                          <>
                            <KTSVG
                              path={`/media/icons/duotune/arrows/${
                                tvlEvolutionFromYesterday.number! > 0
                                  ? 'arr066.svg'
                                  : tvlEvolutionFromYesterday.number! < 0
                                  ? 'arr065.svg'
                                  : 'arr090.svg'
                              }`}
                              className={clsx(
                                'svg-icon-3',
                                tvlEvolutionFromYesterday.number! > 0
                                  ? 'svg-icon-success'
                                  : tvlEvolutionFromYesterday.number! < 0
                                  ? 'svg-icon-danger'
                                  : 'svg-icon-alternative',
                                'me-2'
                              )}
                            />
                            <CountUp
                              style={{fontSize: 'xx-small'}}
                              suffix={tvlEvolutionFromYesterday.suffix}
                              start={0}
                              end={Math.abs(tvlEvolutionFromYesterday.number!)}
                              decimals={2}
                            />
                          </>
                        )}
                        {tvl.loading ? (
                          <Skeleton />
                        ) : (
                          <CountUp
                            className='fs-4 fw-bolder'
                            suffix={tvl.suffix}
                            start={0}
                            end={tvl.number!}
                            decimals={2}
                          />
                        )}
                      </div>
                      <div className='fw-bold fs-6 text-gray-400'>TVL</div>
                    </div>
                  )}
                </div>
                <div className='symbol-group symbol-hover mb-3 ms-0 ms-sm-10px"'>
                  {scorable.contracts.map((blockchainContract, index) => {
                    const blockchain = Blockchains.find(
                      (blockchain) => blockchain.id === blockchainContract.blockchainId
                    )!

                    return (
                      <div
                        key={index}
                        className='symbol symbol-35px symbol-circle me-0 me-sm-10px'
                        data-bs-toggle='tooltip'
                        title={blockchain.name}
                      >
                        <img
                          alt={blockchain.name}
                          src={toAbsoluteUrl(`/media/img/${blockchain.image}`)}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className='separator'></div>
          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item'>
                <a
                  className={clsx('nav-link text-active-primary me-6', {
                    active: tab === 'Overview',
                  })}
                  onClick={() => setTab('Overview')}
                  role='tab'
                >
                  Overview
                </a>
              </li>
              {(scorable.medium || scorable.twitter) && (
                <li className='nav-item'>
                  <a
                    className={clsx('nav-link text-active-primary me-6', {active: tab === 'News'})}
                    onClick={() => setTab('News')}
                    role='tab'
                  >
                    News
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
      {tab === 'Overview' && <Overview scorable={scorable} />}
      {tab === 'D4B' && <D4B scorable={scorable} />}
      {tab === 'News' && <News scorable={scorable} />}
    </>
  )
}

export {ProjectPage}
