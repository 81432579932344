/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {blockchainIds, BlockchainsSelectOptions} from '../../../enums'
import Select, {MultiValue} from 'react-select'
import {IReactSelectOption} from '../../../interfaces/IReactSelectOption'
import {ScorableId} from '../../../enums/ScorableId'
import {KTSVG} from '../../../../_metronic/helpers'
import {useLocation} from 'react-router-dom'
import {MenuComponent} from '../../../../_metronic/assets/ts/components'

type Props = {
  blockchains: any
  onChangeBlockchains: any
}

const Filter: FC<Props> = ({blockchains, onChangeBlockchains}) => {
  const [tempSelectedBlockchains, setTempSelectedBlockchains] = useState(blockchainIds)
  const {pathname} = useLocation()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [pathname])

  const handleOpenModal = () => {
    setTempSelectedBlockchains(blockchains)
  }

  const handleApplyFilter = () => {
    onChangeBlockchains(
      tempSelectedBlockchains.length === blockchainIds.length ? [] : tempSelectedBlockchains
    )
  }

  const handleBlockchainsFilterChange: (
    newValue: MultiValue<IReactSelectOption<ScorableId> | undefined>
  ) => void = (newValue) => {
    setTempSelectedBlockchains(newValue.map((x) => x!.value))
  }

  const handleResetClick = () => {
    onChangeBlockchains([])
  }

  const selectedBlockchains = tempSelectedBlockchains.map((blockchain) =>
    BlockchainsSelectOptions.find((option) => option.value === blockchain)
  )

  return (
    <div>
      <button
        className='btn btn-primary btn-flex fw-bolder'
        onClick={handleOpenModal}
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/img/filter.svg' className='svg-icon-5 svg-icon-gray-500 me-1' />{' '}
        <span className='d-none d-sm-inline'>Filter</span>
      </button>
      <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>

        <div className='separator border-gray-200'></div>

        <div className='px-7 py-5'>
          <div className='mb-10'>
            <label className='form-label fw-bold'>Chain:</label>
            <div>
              <Select
                classNamePrefix='avoidCloseMenu'
                isMulti
                isClearable
                placeholder='Select a Chain'
                options={BlockchainsSelectOptions}
                onChange={handleBlockchainsFilterChange}
                value={selectedBlockchains}
              />
            </div>
          </div>
          <div className='d-flex justify-content-end'>
            <button
              className='btn btn-sm btn-light btn-active-light-primary me-2'
              data-kt-menu-dismiss='true'
              onClick={handleResetClick}
            >
              Reset
            </button>
            <button
              className='btn btn-sm btn-primary'
              data-kt-menu-dismiss='true'
              onClick={handleApplyFilter}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Filter}
