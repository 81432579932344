/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {toMMMddyyyy} from '../../../utils/date'
import {ArticleModel} from '../models/ArticleModel'

type Props = {
  article: ArticleModel
}

const Article: FC<Props> = ({article}) => {
  return (
    <div className='flex-lg-row-fluid me-xl-15'>
      <div className='mb-17'>
        <div className='mb-8'>
          <h1>
            {article.title}
            <br />
            <div className='d-flex flex-stack flex-wrap'>
              <span className='fw-bolder text-muted fs-5 ps-1'>{`${article.readTime}`} read</span>
              <span className='fw-bolder text-muted fs-5 ps-1'>
                {toMMMddyyyy(article.publishDate)}
              </span>
            </div>
          </h1>
          <div className='overlay mt-8'>
            <div
              className='bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-350px'
              style={{
                backgroundImage: `url('${toAbsoluteUrl(`/media/img/posts/${article.image}`)}')`,
              }}
            ></div>
          </div>
        </div>
        {/* <div className='d-flex mb-4 project-links justify-content-end'>
          <a
            href='https://twitter.com/intent/tweet?text=THIS__URL'
            className='btn btn-sm btn-bg-light  me-3'
          >
            <i className='fab fa-twitter fa-lg'></i>
          </a>
          <a
            href='https://www.linkedin.com/shareArticle?mini=true&url=THIS__URL&title=NOTE__TITLE&summary=NOTE__Summary'
            className='btn btn-sm btn-bg-light  me-3'
          >
            <i className='fab fa-linkedin-in fa-lg'></i>
          </a>
          <a
            href='https://www.facebook.com/sharer/sharer.php?u=THIS__URL'
            className='btn btn-sm btn-bg-light  me-3'
          >
            <i className='fab fa-facebook fa-lg'></i>
          </a>
        </div> */}
        <div className='fs-5 fw-bold text-gray-600'>
          <p className='mb-17'>
            <i>{article.description}</i>
          </p>
          <div dangerouslySetInnerHTML={{__html: article.content}}></div>
        </div>
        <div className='d-flex align-items-center border-dashed card-rounded p-5 p-lg-10 mb-14'>
          <div className='text-center flex-shrink-0 me-7 me-lg-13'>
            <div className='symbol symbol-70px symbol-circle mb-2'>
              <img src={toAbsoluteUrl(`/media/img/${article.author.avatar}`)} alt='' />
            </div>
            <div className='mb-0'>
              <Link
                className='text-gray-700 fw-bolder text-hover-primary'
                to={`/community/author/${article.author.id}`}
              >
                {article.author.name}
              </Link>
              <span className='text-gray-400 fs-7 fw-bold d-block mt-1'>
                {article.author.position}
              </span>
            </div>
          </div>
          <div className='mb-0 fs-6'>
            <div className='text-muted fw-bold lh-lg mb-2'>{article.author.bio}</div>
            {/* <a href="#" className="fw-bold link-primary">Author’s Profile</a> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export {Article}
