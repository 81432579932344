/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import React, {useEffect} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {MenuComponent} from '../../../assets/ts/components'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {Header} from './Header'
import {DefaultTitle} from './page-title/DefaultTitle'
import {Topbar} from './Topbar'

export function HeaderWrapper() {
  const {pathname} = useLocation()
  const {config, classes, attributes} = useLayout()
  const {header, aside} = config

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [pathname])

  return (
    <div
      id='kt_header'
      className={clsx('landing-header', classes.header.join(' '))}
      data-kt-sticky='true'
      data-kt-sticky-name='landing-header'
      data-kt-sticky-offset="{default: '200px', lg: '300px'}"
      {...attributes.headerMenu}
    >
      <div className={clsx(classes.headerContainer.join(' '))}>
        {/* begin::Wrapper */}
        <div className='d-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center flex-equal'>
            <div
              className='d-flex topbar align-items-center d-lg-none ms-n2 me-3'
              title='Show aside menu'
            >
              <div
                className='btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px'
                id='kt_header_menu_mobile_toggle'
              >
                <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-2hx' />
              </div>
            </div>
            {/* begin::Aside mobile toggle */}
            {aside.display && (
              <>
                <button
                  className='btn btn-icon btn-active-color-primary me-3 d-flex d-lg-none'
                  id='kt_landing_menu_toggle'
                ></button>
                <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-2hx' />
              </>
            )}
            {/* end::Aside mobile toggle */}
            <Link to='/home'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/d4b-logo.svg')}
                className='logo-default h-40px h-lg-50px'
              />
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/d4b-logo-dark.svg')}
                className='logo-sticky h-40px h-lg-50px'
              />
            </Link>
          </div>

          {/* begin::Navbar */}
          {header.left === 'menu' && (
            <div className='d-lg-block' id='kt_header_nav'>
              <Header />
            </div>
          )}

          {header.left === 'page-title' && (
            <div className='d-flex align-items-center' id='kt_header_nav'>
              <DefaultTitle />
            </div>
          )}

          <Topbar />
        </div>
        {/* end::Wrapper */}
      </div>
    </div>
  )
}
