/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {ErrorsPage} from '../modules/errors/ErrorsPage'

const AppRoutes: FC = () => {
  return (
    <Routes>
      <Route path='*' element={<MasterLayout />} />
      <Route path='/error/*' element={<ErrorsPage />} />
    </Routes>
  )
}

export {AppRoutes}
