/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { CommunityPage } from '../../modules/community/components/CommunityPage'

const CommunityWrapper: FC = () => {
  return (
    <>
      {/* begin::Post */}
      <CommunityPage />
      {/* end::Post */}
    </>
  )
}

export { CommunityWrapper }
