/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { HomePage } from '../../modules/home/components/HomePage'

const HomeWrapper: FC = () => {
  return (
    <>
      {/* begin::Post */}
      <HomePage />
      {/* end::Post */}
    </>
  )
}

export { HomeWrapper }
