/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import { FC, useState } from 'react';
import Buttons from './Buttons';

const mail = process.env.REACT_APP_MAIL
const InfoMail: FC = () => <a href={`mailto:${mail}`} target="_blank" rel="noreferrer">{mail}</a>

const FaqsPage: FC = () => {

    const [showed1, setShowed1] = useState<boolean>(false);
    const [showed2, setShowed2] = useState<boolean>(false);
    const [showed3, setShowed3] = useState<boolean>(false);
    const [showed4, setShowed4] = useState<boolean>(false);
    const [showed5, setShowed5] = useState<boolean>(false);
    const [showed6, setShowed6] = useState<boolean>(false);
    const [showed7, setShowed7] = useState<boolean>(false);
    const [showed8, setShowed8] = useState<boolean>(false);
    const [showed9, setShowed9] = useState<boolean>(false);
    const [showed10, setShowed10] = useState<boolean>(false);
    const [showed11, setShowed11] = useState<boolean>(false);
    const [showed12, setShowed12] = useState<boolean>(false);
    const [showed13, setShowed13] = useState<boolean>(false);

    return (
        <div className="card">
            <div className="card-body p-lg-15">
                <div className="mb-13">
                    <div className="mb-15">
                        <h4 className="fs-2x text-gray-800 w-bolder mb-6">Frequently Asked Questions</h4>
                        {/* <p className="fw-bold fs-4 text-gray-600 mb-2">First, a disclaimer – the entire
                            process of writing often takes more than a couple of hours, even if you can
                            type eighty words as per minute and your writing skills are sharp.</p> */}
                    </div>
                    <div className="row mb-12">
                        <div className="col-md-6 pe-md-10 mb-10 mb-md-0">
                            <h2 className="text-gray-800 fw-bolder mb-4">DeFi4Bitcoin Fundamentals</h2>
                            <div className="m-0">
                                <div className={clsx("d-flex align-items-center collapsible py-3 toggle mb-0", !showed1 && "collapsed")} onClick={() => setShowed1(showed => !showed)}>
                                    <Buttons />
                                    <h4 className="text-gray-700 fw-bolder cursor-pointer mb-0">What is Defi4Bitcoin?</h4>
                                </div>
                                <div id="question1" className={clsx("fs-6 ms-1 collapse", showed1 && "show")}>
                                    <div className="mb-4 text-gray-600 fw-bold fs-6 ps-10">
                                        Defi4Bitcoin is a community that seeks to facilitate access to financial services to Bitcoin holders and promote the Bitcoin-Friendly platforms in the new decentralized open financial system.
                                    </div>
                                </div>
                                <div className="separator separator-dashed">
                                </div>
                            </div>
                            <div className="m-0">
                                <div className={clsx("d-flex align-items-center collapsible py-3 toggle mb-0", !showed2 && "collapsed")} onClick={() => setShowed2(showed => !showed)}>
                                    <Buttons />
                                    <h4 className="text-gray-700 fw-bolder cursor-pointer mb-0">What is Defi4Bitcoin.com?</h4>
                                </div>
                                <div id="question2" className={clsx("fs-6 ms-1 collapse", showed2 && "show")}>
                                    <div className="mb-4 text-gray-600 fw-bold fs-6 ps-10">
                                        The Defi4Bitcoin.com (D4B site) is a tool for Bitcoiners to learn about DeFi, find new projects and how they relate to Bitcoin, and communicate with other Bitcoiners interested in DeFi. We plan to provide a curated list of DeFi projects that align with Bitcoin ethos of decentralization, security, privacy, openness, and censorship-resistance. While Defi4Bitcoin.com is still in BETA, we have big plans!. Check out our roadmap to learn about future milestones.
                                    </div>
                                </div>
                                <div className="separator separator-dashed">
                                </div>
                            </div>
                            <div className="m-0">
                                <div className={clsx("d-flex align-items-center collapsible py-3 toggle mb-0", !showed3 && "collapsed")} onClick={() => setShowed3(showed => !showed)}>
                                    <Buttons />
                                    <h4 className="text-gray-700 fw-bolder cursor-pointer mb-0">What information can I find in Defi4Bitcoin.com?</h4>
                                </div>
                                <div id="question3" className={clsx("fs-6 ms-1 collapse", showed3 && "show")}>
                                    <div className="mb-4 text-gray-600 fw-bold fs-6 ps-10">You can find the platforms that allow you to transact in Bitcoin and those that allow you to lock your bitcoins to receive other benefits. We center our analysis in each project’s Bitcoin affinity. We score projects that solve real problems or provide real benefits to bitcoins while keeping the fundamental security and decentralization properties of Bitcoin.</div>
                                </div>
                                <div className="separator separator-dashed">
                                </div>
                            </div>
                            <div className="m-0">
                                <div className={clsx("d-flex align-items-center collapsible py-3 toggle mb-0", !showed4 && "collapsed")} onClick={() => setShowed4(showed => !showed)}>
                                    <Buttons />
                                    <h4 className="text-gray-700 fw-bolder cursor-pointer mb-0">How can you build Defi on Bitcoin?</h4>
                                </div>
                                <div id="question4" className={clsx("fs-6 ms-1 collapse", showed4 && "show")}>
                                    <div className="mb-4 text-gray-600 fw-bold fs-6 ps-10">While Bitcoin supports payments and some forms of prediction markets, it does not support advanced DeFi protocols internally. All existent DeFi4Bitcoin projects rely on transferring the Bitcoins to other blockchains where they can participate in liquidity pools, be lended, and potentially other sources of returns. We understand that due to current limitations in the Bitcoin protocol it is not possible to develop a DeFi ecosystem without additional trust assumptions. However, we believe that Bitcoin sidechains and blockchain bridges are evolving and Bitcoiners can use some of them with sufficient peace of mind.</div>
                                    <div className="mb-4 text-gray-600 fw-bold fs-6 ps-10">At the same time, we’ll work with the community to prepare proposals for Bitcoin to enable trustless sidechains.</div>
                                </div>
                                <div className="separator separator-dashed">
                                </div>
                            </div>
                            <div className="m-0">
                                <div className={clsx("d-flex align-items-center collapsible py-3 toggle mb-0", !showed5 && "collapsed")} onClick={() => setShowed5(showed => !showed)}>
                                    <Buttons />
                                    <h4 className="text-gray-700 fw-bolder cursor-pointer mb-0">I noticed that some of the founders of Defi4Bitcoin.com are part of the RSK community. Does this mean you will dedicate the site to RSK?</h4>
                                </div>
                                <div id="question5" className={clsx("fs-6 ms-1 collapse", showed5 && "show")}>
                                    <div className="mb-4 text-gray-600 fw-bold fs-6 ps-10">Not at all. The site attempts to be a fair and neutral platform to compare DeFi4Bitcoin projects across objective metrics. To construct the Defi4Bitcoin index, we weight certain properties according to a predefined criteria. This criteria values DeFi projects whose tokenomics are helping Bitcoin acquire its store of value property above tokenomics that do the opposite. Therefore, when listing projects according to the Defi4Bitcoin index, bitcoiners may notice a prioritization of Bitcoin sidechains.</div>
                                </div>
                                <div className="separator separator-dashed">
                                </div>
                            </div>
                            <div className="m-0">
                                <div className={clsx("d-flex align-items-center collapsible py-3 toggle mb-0", !showed6 && "collapsed")} onClick={() => setShowed6(showed => !showed)}>
                                    <Buttons />
                                    <h4 className="text-gray-700 fw-bolder cursor-pointer mb-0">Who is behind Defi4Bitcoin.com?</h4>
                                </div>
                                <div id="question6" className={clsx("fs-6 ms-1 collapse", showed6 && "show")}>
                                    <div className="mb-4 text-gray-600 fw-bold fs-6 ps-10">We are a group of Bitcoiners that value DeFi and we’ve been working since 2014 in expanding Bitcoin reach in this new field. We have special interest in technologies that scale Bitcoin to enable faster and cheaper payments, projects that improve the Bitcoin ecosystem, improving Bitcoin technology with BIPs (Bitcoin Improvement Proposals), and helping developers find Bitcoin friendly platforms to launch their DeFi projects.</div>
                                </div>
                                <div className="separator separator-dashed">
                                </div>
                            </div>
                            <div className="m-0">
                                <div className={clsx("d-flex align-items-center collapsible py-3 toggle mb-0", !showed7 && "collapsed")} onClick={() => setShowed7(showed => !showed)}>
                                    <Buttons />
                                    <h4 className="text-gray-700 fw-bolder cursor-pointer mb-0">Aren’t Bitcoin holding and Defi4Bitcoin contradictory?</h4>
                                </div>
                                <div id="question7" className={clsx("fs-6 ms-1 collapse", showed7 && "show")}>
                                    <div className="mb-4 text-gray-600 fw-bold fs-6 ps-10">They are not. Bitcoin holdlers need Bitcoin to expand to reach its full potential as a global store of value. However hodlers know that success is not guaranteed. Blockchain technology is evolving at glazing speed. Hodlers also want to see a growing, open, and welcoming ecosystem full of possibilities for new developers to innovate, to find the ways to solve more financial problems and conquer every place on earth. Therefore, hodlers also participate in Defi4Bitcoin projects, either as investors, or as users.</div>
                                </div>
                                <div className="separator separator-dashed">
                                </div>
                            </div>
                            <div className="m-0">
                                <div className={clsx("d-flex align-items-center collapsible py-3 toggle mb-0", !showed8 && "collapsed")} onClick={() => setShowed8(showed => !showed)}>
                                    <Buttons />
                                    <h4 className="text-gray-700 fw-bolder cursor-pointer mb-0">There is a Defi4Bitcoin PRO Service?</h4>
                                </div>
                                <div id="question8" className={clsx("fs-6 ms-1 collapse", showed8 && "show")}>
                                    <div className="mb-4 text-gray-600 fw-bold fs-6 ps-10">At this moment we provide our basic statistics free of charge to all our users. Our research into DeFi protocols is limited to public information that can be gathered by running and indexing blockchain transactions that interact with the DeFi contracts and addresses. The information we provide does not constitute investment advice and we encourage users to do their own research.</div>
                                    <div className="mb-4 text-gray-600 fw-bold fs-6 ps-10">In the future we may add specific charts and analysis that may require a monthly subscription fee.</div>
                                </div>
                                <div className="separator separator-dashed">
                                </div>
                            </div>
                            <div className="m-0">
                                <div className={clsx("d-flex align-items-center collapsible py-3 toggle mb-0", !showed9 && "collapsed")} onClick={() => setShowed9(showed => !showed)}>
                                    <Buttons />
                                    <h4 className="text-gray-700 fw-bolder cursor-pointer mb-0">Does Defi4Bitcoin provide an API data service?</h4>
                                </div>
                                <div id="question9" className={clsx("fs-6 ms-1 collapse", showed9 && "show")}>
                                    <div className="mb-4 text-gray-600 fw-bold fs-6 ps-10">We currently do not have an open API endpoint. If you’re interested in receiving information regarding Defi4Bitcoin, please contact us.</div>
                                </div>
                                <div className="separator separator-dashed">
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 ps-md-10">
                            <div className="ps-md-10 mb-12">
                                <h2 className="text-gray-800 fw-bolder mb-4">Technical Questions</h2>
                                <div className="m-0">
                                    <div className={clsx("d-flex align-items-center collapsible py-3 toggle mb-0", !showed10 && "collapsed")} onClick={() => setShowed10(showed => !showed)}>
                                        <Buttons />
                                        <h4 className="text-gray-700 fw-bolder cursor-pointer mb-0">How fast is DeFi4Bitcoin.com statistics updated?</h4>
                                    </div>
                                    <div id="question10" className={clsx("fs-6 ms-1 collapse", showed10 && "show")}>
                                        <div className="mb-4 text-gray-600 fw-bold fs-6 ps-10">Values are updated and historical values are provided every 5 minutes.</div>
                                    </div>
                                    <div className="separator separator-dashed">
                                    </div>
                                </div>
                                <div className="m-0">
                                    <div className={clsx("d-flex align-items-center collapsible py-3 toggle mb-0", !showed11 && "collapsed")} onClick={() => setShowed11(showed => !showed)}>
                                        <Buttons />
                                        <h4 className="text-gray-700 fw-bolder cursor-pointer mb-0">How does DeFi4Bitcoin.com obtain its data?</h4>
                                    </div>
                                    <div id="question11" className={clsx("fs-6 ms-1 collapse", showed11 && "show")}>
                                        <div className="mb-4 text-gray-600 fw-bold fs-6 ps-10">We collect transaction data from different blockchains and some online price feeds. While our plan is to run a node of every blockchain we monitor, currently we rely on third party companies to provide nodes in the cloud. Therefore, as we cannot guarantee the correctness and liveness of all the data we provide on our website. However we will do our best effort to quickly identify any misleading information and fix it as soon as possible. If you detect any anomaly in our data, please contact <InfoMail /> and we’ll investigate it.</div>
                                    </div>
                                    <div className="separator separator-dashed">
                                    </div>
                                </div>
                            </div>
                            <div className="ps-md-10">
                                <h2 className="text-gray-800 fw-bolder mb-4">Defi4Bitcoin Community</h2>
                                <div className="m-0">
                                    <div className={clsx("d-flex align-items-center collapsible py-3 toggle mb-0", !showed12 && "collapsed")} onClick={() => setShowed12(showed => !showed)}>
                                        <Buttons />
                                        <h4 className="text-gray-700 fw-bolder cursor-pointer mb-0">I want to help you. How can I participate?</h4>
                                    </div>
                                    <div id="question12" className={clsx("fs-6 ms-1 collapse", showed12 && "show")}>
                                        <div className="mb-4 text-gray-600 fw-bold fs-6 ps-10">If you feel aligned with the Defi4Bitcoin community, we're thrilled to hear your ideas. We plan to open a forum for bitcoiners to discuss DeFi4Bitcoin projects. Meanwhile, if you are eager to communicate with the DeFi4Bitcoin staff, you can reach us to <InfoMail />.</div>
                                    </div>
                                    <div className="separator separator-dashed">
                                    </div>
                                </div>
                                <div className="m-0">
                                    <div className={clsx("d-flex align-items-center collapsible py-3 toggle mb-0", !showed13 && "collapsed")} onClick={() => setShowed13(showed => !showed)}>
                                        <Buttons />
                                        <h4 className="text-gray-700 fw-bolder cursor-pointer mb-0">I want to work with you. Do you have open job positions?</h4>
                                    </div>
                                    <div id="question13" className={clsx("fs-6 ms-1 collapse", showed13 && "show")}>
                                        <div className="mb-4 text-gray-600 fw-bold fs-6 ps-10">We’re currently looking for developers and DeFi advocates who can review and score the many DeFi protocols that exist in our ecosystem. If you think you like this job, contact us asap! Our e-mail is <InfoMail />.</div>
                                    </div>
                                    <div className="separator separator-dashed">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}

export { FaqsPage }