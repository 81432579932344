import React, {FC, useEffect} from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import {Bar} from 'react-chartjs-2'
import {useDispatch, useSelector} from 'react-redux'
import {ScorableId} from '../../../../enums/ScorableId'
import {actions} from '../redux/GitHubRedux'
import {RootState} from '../../../../../setup'
import {toMMMdd} from '../../../../utils/date'
import {GitHubEventsReduxModel} from '../models/GitHubEventsReduxModel'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
  },
}

type Props = {
  scorableId: ScorableId
}

const DeveloperActivityBar: FC<Props> = ({scorableId}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.requestGitHubEvents(scorableId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, scorableId])

  const github: GitHubEventsReduxModel = useSelector((state: RootState) => state.github)

  if (!github || !github.events) {
    return <div>No data</div>
  }

  if (github.loading) {
    return <div>Loading...</div>
  }

  const labels = github.events.map((x) => toMMMdd(x.date))

  const data = {
    labels,
    datasets: [
      {
        label: 'GitHub Events',
        data: github.events.map((x) => x.count),
        backgroundColor: 'rgba(0, 158, 247, 0.5)',
      },
    ],
  }

  return <Bar options={options} data={data} />
}

export default DeveloperActivityBar
