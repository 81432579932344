import {HubConnectionBuilder, HubConnectionState, LogLevel, HubConnection} from '@microsoft/signalr'
import {actions as scoringActions} from '../../app/modules/scoring'
import {AppStore} from '../redux/Store'
import {actions as socketActions} from './SocketRedux'

const isDev = process.env.NODE_ENV === 'development'

const options = {
  logMessageContent: isDev,
  logger: isDev ? LogLevel.Warning : LogLevel.Error,
}

let store: AppStore

export const injectStore = (_store: AppStore) => {
  store = _store
}

export const createWebSocket: () => {socket: HubConnection} = () => {
  const socket = new HubConnectionBuilder()
    .withUrl(`${process.env.REACT_APP_SOCKET_URL}/hubs/notifications`, options)
    .withAutomaticReconnect()
    .configureLogging(LogLevel.Information)
    .build()

  // Note: to keep the connection open the serverTimeout should be
  // larger than the KeepAlive value that is set on the server
  // keepAliveIntervalInMilliseconds default is 15000 and we are using default
  // serverTimeoutInMilliseconds default is 30000 and we are using 120000 set below
  socket.serverTimeoutInMilliseconds = 120000

  // re-establish the connection if connection dropped
  socket.onclose((error) => {
    console.assert(socket.state === HubConnectionState.Disconnected)
    store.dispatch(socketActions.disconnect())
    console.log(
      'SignalR connection closed due to error. Try refreshing this page to restart the connection',
      error
    )
  })

  socket.onreconnecting((error) => {
    console.assert(socket.state === HubConnectionState.Reconnecting)
    store.dispatch(socketActions.reconnect())
    console.log('SignalR connection lost due to error. Reconnecting.', error)
  })

  socket.onreconnected((connectionId) => {
    console.assert(socket.state === HubConnectionState.Connected)
    store.dispatch(socketActions.connect())
    store.dispatch(scoringActions.requestInitialData())
    store.dispatch(scoringActions.requestMoreData())
    console.log('SignalR connection reestablished. Connected with connectionId', connectionId)
  })

  socket
    .start()
    .then(() => {
      console.assert(socket.state === HubConnectionState.Connected)
      store.dispatch(socketActions.connect())
      console.log('SignalR connection started')
    })
    .catch((err) => {
      console.error(err.toString())
    })

  return {socket}
}
