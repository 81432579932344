import React, { FC } from 'react'
import { AsideDefault } from './components/aside/AsideDefault'
import { Footer } from './components/Footer'
import { HeaderWrapper } from './components/header/HeaderWrapper'
import { ScrollTop } from './components/ScrollTop'
import { Content } from './components/Content'
import { MasterInit } from './MasterInit'
import { PageDataProvider } from './core'
import clsx from 'clsx'
import { PublicRoutes } from '../../app/routing/PublicRoutes'

const MasterLayout: FC = () => {

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper />
          <div id='kt_content_container' className={clsx('d-flex flex-row-fluid align-items-start container-xxl')}>
            <AsideDefault />
            <Content>
              <PublicRoutes />
            </Content>
          </div>
          <Footer />
        </div>
      </div>

      <MasterInit />
      <ScrollTop />
    </PageDataProvider>
  )
}

export { MasterLayout }
