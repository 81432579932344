/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react'
import clsx from 'clsx'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import Buttons from './Buttons'

const WhatisPage: FC = () => {

    const [showed1, setShowed1] = useState<boolean>(false);
    const [showed2, setShowed2] = useState<boolean>(false);
    const [showed3, setShowed3] = useState<boolean>(false);
    const [showed4, setShowed4] = useState<boolean>(false);
    const [showed5, setShowed5] = useState<boolean>(false);
    const [showed6, setShowed6] = useState<boolean>(false);
    const [showed7, setShowed7] = useState<boolean>(false);
    const [showed8, setShowed8] = useState<boolean>(false);
    const [showed9, setShowed9] = useState<boolean>(false);
    const [showed10, setShowed10] = useState<boolean>(false);

    return (
        <div className="row g-6 g-xl-9">
            <div className="col-lg-12">
                <div className="card card-flush h-lg-100">
                    <div className="card-body p-lg-20">
                        <div className="d-flex flex-column flex-xl-row">
                            <div className="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">
                                <h3 className="fw-bolder mb-1">What is D4B?</h3>
                                <div className="fs-6 text-gray-700">
                                    <p><b>Defi4Bitcoin</b> is a meme and a movement that seeks to facilitate access to financial services to Bitcoin holders and promote the  Bitcoin-Friendly platforms in the new decentralized open financial system.</p>
                                    <p>The Defi4Bitcoin Projects aims to become the center of gravity of the Defi4Bitcoin movement.</p>
                                    <p>It starts with an online presence with analytics, rankings, relevant information, indexes about Defi protocols and “Bitcoin friendly” smart contracts platforms.</p>
                                </div>
                                <div className="bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-350px mt-9 mb-9"
                                    style={{
                                        backgroundImage: `url('${toAbsoluteUrl(
                                            '/media/img/thumb-7.png'
                                        )}')`,
                                    }}>
                                </div>
                            </div>
                            <div className="flex-lg-row-fluid ms-xl-15">
                                <h3 className="fw-bolder mb-1">D4B Scoring</h3>

                                <div className="fs-6 text-gray-700">
                                    <p>The Defi4Bitcoin index is a weighted index that indicates how well a certain platform, DeFi protocol or bridge adjusts to Bitcoin ethos. We characterize the Bitcoin ethos as a weighted average over 9 different properties or sub-indexes. Here we present the properties:</p>

                                    <div className="row mb-12">
                                        <div className="col-md-12">
                                            <div className="m-0">
                                                <div className={clsx("d-flex align-items-center collapsible py-3 toggle mb-0", !showed1 && "collapsed")} onClick={() => setShowed1(showed => !showed)}>
                                                    <Buttons />
                                                    <h4 className="text-gray-700 fw-bolder cursor-pointer mb-0">Master Index</h4>
                                                </div>
                                                <div id="question1" className={clsx("fs-6 ms-1 collapse", showed1 && "show")}>
                                                    <div className="mb-4 text-gray-600 fw-bold fs-6 ps-10">
                                                        <p>We assign each project a master and a child index. The child index depends solely on the project properties, and the master index considers the platform where the project runs and tokens it uses. For example, for dapps we add the project child index, with the weighted average of the wrapped bitcoin index, and the weighted average of the blockchain index. This way, we assign each dapp a master Index. </p>
                                                        <p>The master index gives an idea of how secure the dapp is in general, considering all possible ways the user can lose their bitcoins based on blockchain, bridge or dapp failures.</p>
                                                        <p>All projects will receive a child score based on the 9 properties presented before, but each property contributes with a different number of points. The property that contributes most is security, because we understand that no financial protocol is beneficial if it is insecure. For every property, several sub-properties have been devised, and each sub-property contributes with a percentage of the property score. This way we can add more sub-properties without altering the prioritization of properties already established.</p>
                                                        <p>Of course, no scoring method is perfect, and we will be improving it as we receive community feedback, and by means of periodic polls. If we adjust our score weights or if we add more properties or sub-properties, we’ll increase the version of our index and the Defi4bicoin users will be notified of the change.</p>
                                                    </div>
                                                </div>
                                                <div className="separator separator-dashed">
                                                </div>
                                            </div>
                                            <div className="m-0">
                                                <div className={clsx("d-flex align-items-center collapsible py-3 toggle mb-0", !showed2 && "collapsed")} onClick={() => setShowed2(showed => !showed)}>
                                                    <Buttons />
                                                    <h4 className="text-gray-700 fw-bolder cursor-pointer mb-0">Bitcoin Affinity Index</h4>
                                                </div>
                                                <div id="question2" className={clsx("fs-6 ms-1 collapse", showed2 && "show")}>
                                                    <div className="mb-4 text-gray-600 fw-bold fs-6 ps-10">
                                                        <p>The Bitcoin Affinity index measures how well the platform contributes to increasing the value of Bitcoin. In our 1.0 version, we only consider Total Bitcoins Locked. There are two kinds of locked bitcoins: those which are staked and therefore are not traded, and those which are wrapped, which means that they can be traded in DEXes and transferred into CEXes.</p>
                                                        <p>The former contribute directly to increasing the value of Bitcoin by reducing the offer on exchanges, the latter contribute to increase the awareness of Bitcoin on other platforms.</p>
                                                        <p>For simplification, we consider both equal, and we award points to projects according to the amount of bitcoins locked. We also give a special score to a project that uses only Bitcoin as collateral.</p>
                                                        <p>In our next version of the Affinity index, we plan to include in our score the vision, mision, marketing, social network posts,  and ideology behind each project, and try to evaluate how well it matches Bitcoin ethos. The challenge is to do it objectively.</p>
                                                    </div>
                                                </div>
                                                <div className="separator separator-dashed">
                                                </div>
                                            </div>
                                            <div className="m-0">
                                                <div className={clsx("d-flex align-items-center collapsible py-3 toggle mb-0", !showed3 && "collapsed")} onClick={() => setShowed3(showed => !showed)}>
                                                    <Buttons />
                                                    <h4 className="text-gray-700 fw-bolder cursor-pointer mb-0">Security Index</h4>
                                                </div>
                                                <div id="question3" className={clsx("fs-6 ms-1 collapse", showed3 && "show")}>
                                                    <div className="mb-4 text-gray-600 fw-bold fs-6 ps-10">
                                                        <p>The security index measures how secure the platform/protocol is. Is is the index with higher number of components to take into consideration. We first reward formalized algorithms and protocols, valuing the publication of formal proofs, simulations, papers and peer reviews. We value external security audits, and give extra score if the security corresponds the the latest version of the source code. We value bug bounties, the higher the prizes, the better. We give extra points if there is a public record of prizes paid. We also think it is important the number of consecutive years the project has been active and fully functional without a security vulnerability being exploited. Therefore, older projects with good track records will score better than new projects, even if none had vulnerabilities publicly disclosed. We evaluate the Founders and programmers track record, prioritizing the use or real identities, and having a good reputation from previous projects in the crypto ecosystem. Having code created and maintained by the project's team is valued more than using inherited or mostly cloned code. A good secure development cycle, including deterministic builds, checksummed and signed releases, public PR peer-reviews. We give points for having operational security, including providen ways to authenticate the dapp frontends, having website certificates, providing several options to validate the same information by different parties, enabling the use of hardware wallet and the capability to clearly verify each message signed in the hardware wallet display. In terms of governance, we assign more points when all governance actions (i.e. change of fees) are enforced by vote, and when polls actions are time-delayed, so that users can choose to exit the dapp if they disagree with the majority votes.</p>
                                                        <p>Also voting sessions that are public, auditable and attributable are preferred. </p>
                                                        <p>When the governance is a closed group, we value when governance keys are stored in HSM devices, and more if the HSM devices follow consensus and prevent any unauthorized signing. If the HSM devices provide public firmware attestation, that's also a plus.</p>
                                                        <p>We think that allowing security-sensitive code upgrades (such as smart-contracts) is a drawback, but we give points according to the minimum number of keys required to perform the code upgrade. We also value forced-delays prior upgrades to give users that disagree with the change to exist the dapp. The more time, the better.</p>
                                                        <p>Also regarding upgrades, we value the existence of public websites that notify of upcoming upgrades or results of voting polls. If there is a third party (non-founders) website that provides the same information, then this is a plus.</p>
                                                    </div>
                                                </div>
                                                <div className="separator separator-dashed">
                                                </div>
                                            </div>
                                            <div className="m-0">
                                                <div className={clsx("d-flex align-items-center collapsible py-3 toggle mb-0", !showed4 && "collapsed")} onClick={() => setShowed4(showed => !showed)}>
                                                    <Buttons />
                                                    <h4 className="text-gray-700 fw-bolder cursor-pointer mb-0">Decentralization index</h4>
                                                </div>
                                                <div id="question4" className={clsx("fs-6 ms-1 collapse", showed4 && "show")}>
                                                    <div className="mb-4 text-gray-600 fw-bold fs-6 ps-10">
                                                        <p>In version 1.0 of this index, we estimate the cost of full validation of the project transactions. The higher the cost, the worse the decentralization and the less score the project gets.</p>
                                                        <p>In the decentralization index 2.0 we plan to add a score representing the gini coefficient of the project governance tokens: more centralization meaning lower score.</p>
                                                    </div>
                                                </div>
                                                <div className="separator separator-dashed"></div>
                                            </div>
                                            <div className="m-0">
                                                <div className={clsx("d-flex align-items-center collapsible py-3 toggle mb-0", !showed5 && "collapsed")} onClick={() => setShowed5(showed => !showed)}>
                                                    <Buttons />
                                                    <h4 className="text-gray-700 fw-bolder cursor-pointer mb-0">Privacy Index</h4>
                                                </div>
                                                <div id="question5" className={clsx("fs-6 ms-1 collapse", showed5 && "show")}>
                                                    <div className="mb-4 text-gray-600 fw-bold fs-6 ps-10">
                                                        <p>In the privacy index we attempt to measure the amount of privacy a user gets while interacting with the dapp, bridge or blockchain. </p>
                                                        <p>Private amounts/balances are rewarded, and also supporting anonymous identities. Minor score is given if only pseudonymous identities are supported. Currently all projects evaluated only support pseudonymous identities.</p>
                                                        <p>Finally, we penalize projects which allow MEV in their harmful forms, suchs as front-running. </p>
                                                    </div>
                                                </div>
                                                <div className="separator separator-dashed">
                                                </div>
                                            </div>
                                            <div className="m-0">
                                                <div className={clsx("d-flex align-items-center collapsible py-3 toggle mb-0", !showed6 && "collapsed")} onClick={() => setShowed6(showed => !showed)}>
                                                    <Buttons />
                                                    <h4 className="text-gray-700 fw-bolder cursor-pointer mb-0">Scalability Index</h4>
                                                </div>
                                                <div id="question6" className={clsx("fs-6 ms-1 collapse", showed6 && "show")}>
                                                    <div className="mb-4 text-gray-600 fw-bold fs-6 ps-10">
                                                        <p>The idea is to measure how scalable the platform is with the current technology, not based on promises. An unscalable platform can only provide short term benefits, but Bitcoiners are usually interested in the long term effects of technology. We measure scalability in terms of the number of active users assuming uniform use.</p>
                                                    </div>
                                                </div>
                                                <div className="separator separator-dashed">
                                                </div>
                                            </div>
                                            <div className="m-0">
                                                <div className={clsx("d-flex align-items-center collapsible py-3 toggle mb-0", !showed7 && "collapsed")} onClick={() => setShowed7(showed => !showed)}>
                                                    <Buttons />
                                                    <h4 className="text-gray-700 fw-bolder cursor-pointer mb-0">Fairness Index</h4>
                                                </div>
                                                <div id="question7" className={clsx("fs-6 ms-1 collapse", showed7 && "show")}>
                                                    <div className="mb-4 text-gray-600 fw-bold fs-6 ps-10">
                                                        <p>In this property we penalize protocols that allow censorship, especially if it can go undetected. We also penalize protocols that can selectively charge more fees to certain users, specially if this can go undetected. We also focus on the initial coin distribution, if any. No coin distribution (sidechains or dapps using other tokens) gets the best score.Next in the prioritization list are: tokens distributed according to Based on proof-of-work, tokens distributed based on airdrop on the past platform users, distributions based on open ICO without pre-sales, and finally other distributions with pre-sales, insiders and high gini coefficients.</p>
                                                    </div>
                                                </div>
                                                <div className="separator separator-dashed">
                                                </div>
                                            </div>
                                            <div className="m-0">
                                                <div className={clsx("d-flex align-items-center collapsible py-3 toggle mb-0", !showed8 && "collapsed")} onClick={() => setShowed8(showed => !showed)}>
                                                    <Buttons />
                                                    <h4 className="text-gray-700 fw-bolder cursor-pointer mb-0">Usability Index</h4>
                                                </div>
                                                <div id="question8" className={clsx("fs-6 ms-1 collapse", showed8 && "show")}>
                                                    <div className="mb-4 text-gray-600 fw-bold fs-6 ps-10">
                                                        <p>The index 1.0 does not contain any score for usability, because we think usability is a widespread problem that affects most blockchains that support DeFi applications. In our next version of the index we hope to reward applications that allow performing its main and most common function with less number of clicks, and forcing the user to take the lowest number of irrelevant decisions, while still being secure.</p>
                                                    </div>
                                                </div>
                                                <div className="separator separator-dashed">
                                                </div>
                                            </div>
                                            <div className="m-0">
                                                <div className={clsx("d-flex align-items-center collapsible py-3 toggle mb-0", !showed9 && "collapsed")} onClick={() => setShowed9(showed => !showed)}>
                                                    <Buttons />
                                                    <h4 className="text-gray-700 fw-bolder cursor-pointer mb-0">Economic Index</h4>
                                                </div>
                                                <div id="question9" className={clsx("fs-6 ms-1 collapse", showed9 && "show")}>
                                                    <div className="mb-4 text-gray-600 fw-bold fs-6 ps-10"><p>In this property we value projects that allow using or transacting in Bitcoin at a low cost. We also value projects that provide high APYs to bitcoin stakers.</p></div>
                                                </div>
                                                <div className="separator separator-dashed">
                                                </div>
                                            </div>
                                            <div className="m-0">
                                                <div className={clsx("d-flex align-items-center collapsible py-3 toggle mb-0", !showed10 && "collapsed")} onClick={() => setShowed10(showed => !showed)}>
                                                    <Buttons />
                                                    <h4 className="text-gray-700 fw-bolder cursor-pointer mb-0">Community Traction Index</h4>
                                                </div>
                                                <div id="question10" className={clsx("fs-6 ms-1 collapse", showed10 && "show")}>
                                                    <div className="mb-4 text-gray-600 fw-bold fs-6 ps-10">
                                                        <p>In the Community index we value projects with high bitcoin transaction activity, including, if appropriate, payments, peg.ins and outs, stakings, deposits and withdrawals. We also weight the number of monthly active bitcoin users performing the aforementioned transactions, based on the number of unique accounts participating. Finally, we estimate the whole user base based on past interactions with the protocol.</p>
                                                        <p>In our index version 2.0 we plan to include information extracted from social network interactions, pòsitive mentions, github commits, etc.</p>
                                                    </div>
                                                </div>
                                                <div className="separator separator-dashed">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { WhatisPage }