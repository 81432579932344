import { FC } from "react"
import ContentLoader from "react-content-loader"

type Props = {
    width: number
}

const BtcLockedGraphSkeleton: FC<Props> = ({ width }) =>
    <ContentLoader
        speed={2}
        height={300}
        width={width}
        viewBox={`0 0 ${width} 300`}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        style={{ width: '100%' }}
    >
        <rect x="0" y="0" rx="2" ry="2" width={width} height="300" />
    </ContentLoader>


export default BtcLockedGraphSkeleton