import Chart from 'react-apexcharts'
import {FC} from 'react'
import {ApexOptions} from 'apexcharts'
import BtcLockedGraphSkeleton from './BtcLockedGraphSkeleton'
import {PeriodEnum} from '../../../../../enums/PeriodEnum'
import {toLocaleDateTimeShortString} from '../../../../../utils/date'
import {PeriodModel} from '../../../models/PeriodModel'
import {Blockchains} from '../../../../../enums'
import {UnitEnum} from '../../../../../enums/UnitEnum'
import {PeriodStoryModel} from '../../../models/PeriodStoryModel'

type Props = {
  project: PeriodModel
  parentWidth: number
  period: PeriodEnum
  unit: UnitEnum
}

const getPropertyByUnit = (x: PeriodStoryModel, unit: UnitEnum): number => {
  let retValue
  switch (unit) {
    case UnitEnum.Btc:
      retValue = x.btcLocked
      break
    case UnitEnum.Usd:
      retValue = x.btcLockedInUsd
      break
  }
  return retValue
}

const BtcLockedGraph: FC<Props> = ({project, parentWidth, period, unit}) => {
  if (project.loading) return <BtcLockedGraphSkeleton width={parentWidth} />

  if (project.error) return <div>{project.error}</div>

  const options: ApexOptions = {
    chart: {
      type: 'area',
      height: 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      x: {
        show: true,
        format:
          period === PeriodEnum.OneDay || period === PeriodEnum.FiveDays
            ? 'dd MMM - HH:mm'
            : 'dd MMM',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },
    labels: project.stories.map((x) => toLocaleDateTimeShortString(x.fetchAt)),
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeUTC: false,
      },
    },
    yaxis: {
      opposite: false,
      labels: {
        formatter: function (val) {
          let decimals
          switch (unit) {
            case UnitEnum.Btc:
              decimals = 2
              break
            case UnitEnum.Usd:
              decimals = 0
              break
          }
          return val.toFixed(decimals)
        },
      },
    },
    legend: {
      horizontalAlign: 'left',
    },
    noData: {
      text: 'Loading...',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: '14px',
        fontFamily: undefined,
      },
    },
  }

  const series = [
    {
      name: 'Total',
      data: project.stories.map(
        (story) => Math.round((getPropertyByUnit(story, unit) + Number.EPSILON) * 100) / 100
      ),
    },
    ...project.blockchainStories.map((blockchainStories) => ({
      name: Blockchains.find((x) => x.id === blockchainStories.blockchainId)!.name,
      data: blockchainStories.stories.map(
        (story) => Math.round((getPropertyByUnit(story, unit) + Number.EPSILON) * 100) / 100
      ),
    })),
  ]

  return (
    <Chart
      options={options}
      series={series}
      type='area'
      height={350}
      className='card-rounded-bottom'
    />
  )
}

export {BtcLockedGraph}
