/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { Navigate, useParams } from 'react-router-dom';
import { ArticlePage } from '../../modules/community/components/ArticlePage';

const ArticleWrapper: FC = () => {
  const { title } = useParams();

  if (!title) {
    return <Navigate to='/error/404' />
  }
  
  return (
    <>
      {/* begin::Post */}
      <ArticlePage title={title} />
      {/* end::Post */}
    </>
  )
}

export { ArticleWrapper }
