export enum ScorableId {
  WBtc = 1,
  HBtc = 2,
  RenBtc = 3,
  PBtc = 4,
  OBtc = 5,
  BtcB = 6,
  ImBtc = 7,
  BBtc = 8,
  TBtc = 9,
  SBtc = 10,
  AnyBtc = 11,
  VBtc = 12,
  MBtc = 13,
  BtcPlusPlus = 14,
  AcBtc = 15,
  DForce = 16,
  MoC = 17,
  Sovryn = 18,
  RskSwap = 19,
  Aave = 21,
  Compound = 22,
  Polygon = 23,
  MakerDao = 24,
  SushiSwap = 25,
  CurveFi = 26,
  Balancer = 27,
  Bancor = 28,
  UniSwap = 29,
  Saddle = 31,
  MStable = 32,
  Uma = 33,
  Tranchess = 34,
  Venus = 35,
  Alpaca = 36,
  PancakeSwap = 37,
  Qubit = 38,
  QuickSwap = 39,
  Iron = 40,
  Dfyn = 41,
  Gravity = 42,
  Gmx = 43,
  Dodo = 44,
  RBtc = 45,
  Ethereum = 46,
  Rsk = 47,
  Binance = 48,
  Arbitrum = 49,
  Tropykus = 50,
}
