import clsx from 'clsx'
import {FC} from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../setup'

const SocketStatus: FC = () => {
  const socket = useSelector((state: RootState) => state.socket)

  return (
    <div className='site-status site-status__connected text-gray-400'>
      <span
        className={clsx(
          'bullet bullet-dot',
          socket.connected ? 'bg-success' : socket.reconnecting ? 'bg-warning' : 'bg-secondary',
          'translate-middle top-0 start-50 animation-blink'
        )}
      ></span>
      {` ${
        socket.connected ? 'Connected' : socket.reconnecting ? 'Reconnecting...' : 'Disconnected'
      }`}
    </div>
  )
}

export {SocketStatus}
