import React, { FC } from 'react'
import { Navigate, useParams } from 'react-router';
import { ProjectPage } from '../../modules/project/components/ProjectPage';

const ProjectWrapper: FC = () => {
  const { path } = useParams();

  if (!path) {
    return <Navigate to='/error/404' />
  }

  return (
    <>
      {/* begin::Post */}
      <ProjectPage path={path} />
      {/* end::Post */}
    </>
  )
}

export { ProjectWrapper }
