import React, {Suspense, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'
import {RootState} from '../setup'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {actions as scoringActions} from './modules/scoring'
import {actions as homeActions} from './modules/home'
import {AppRoutes} from './routing/AppRoutes'
import {MathJaxContext} from 'better-react-mathjax'

type Props = {
  basename: string
}

const App: React.FC<Props> = ({basename}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(homeActions.requestLogoData())
    dispatch(scoringActions.requestInitialData())
    dispatch(scoringActions.requestMoreData())
  }, [dispatch])

  const loading = useSelector((state: RootState) => state.home.loading)
  if (loading) return <LayoutSplashScreen />

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <I18nProvider>
          <LayoutProvider>
            <MathJaxContext>
              <AppRoutes />
            </MathJaxContext>
          </LayoutProvider>
        </I18nProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export {App}
