import clsx from 'clsx'
import {useField} from 'formik'
import React, {FC} from 'react'

interface Props {
  name: string
  placeholder: string
  icon: JSX.Element
  disabled?: boolean
  autoFocus?: boolean
}

const Input: FC<Props> = ({name, placeholder, icon, disabled = false, autoFocus = false}) => {
  const [field, meta] = useField(name)

  return (
    <>
      <div className='input-group input-group-solid mb-5'>
        <span className='input-group-text' id={`${name}Description`}>
          {icon}
        </span>
        <input
          type='text'
          className={clsx('form-control', meta.touched && meta.error && 'is-invalid')}
          aria-label={placeholder}
          aria-describedby={`${name}Description`}
          placeholder={placeholder}
          autoFocus={autoFocus}
          inputMode='text'
          disabled={disabled}
          {...field}
        />
        {meta.error && meta.touched && (
          <div id='validationServerUsernameFeedback' className='invalid-feedback text-end'>
            {meta.error}
          </div>
        )}
      </div>
    </>
  )
}

export default Input
