import {call, put, takeLatest} from 'redux-saga/effects'
import {PeriodEnum} from '../../../enums/PeriodEnum'
import {getStoriesByScorableAndPeriod} from '../../scoring/redux/ScoringApi'
import {IActionWithPayload} from '../../../interfaces/IActionWithPayload'
import {PeriodModel} from '../models/PeriodModel'

export const actionTypes = {
  PeriodStoriesRequested: 'PERIOD_STORIES_REQUESTED',
  PeriodStoriesReceived: 'PERIOD_STORIES_RECEIVED',
  PeriodStoriesFailed: 'PERIOD_STORIES_FAILED',
}

const initialState: PeriodModel = {
  stories: [],
  blockchainStories: [],
  loading: false,
}

export const reducer = (state: PeriodModel = initialState, action: IActionWithPayload<any>) => {
  switch (action.type) {
    case actionTypes.PeriodStoriesRequested: {
      return {
        ...state,
        stories: [],
        blockchainStories: [],
        loading: true,
      }
    }

    case actionTypes.PeriodStoriesReceived: {
      const period = action.payload
      return {
        ...period,
        loading: false,
        date: new Date(),
      }
    }

    case actionTypes.PeriodStoriesFailed: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    }

    default:
      return state
  }
}

export const actions = {
  requestPeriodStories: (projectId: number, period: PeriodEnum) => ({
    type: actionTypes.PeriodStoriesRequested,
    payload: {projectId, period},
  }),
  receivePeriodStories: (period: PeriodModel) => ({
    type: actionTypes.PeriodStoriesReceived,
    payload: period,
  }),
  periodStoriesFailed: (message: string) => ({
    type: actionTypes.PeriodStoriesFailed,
    payload: message,
  }),
}

function* fetchPeriodStories(action: any) {
  try {
    const {data: periodStories} = yield call(
      getStoriesByScorableAndPeriod,
      action.payload.projectId,
      action.payload.period
    )
    yield put(actions.receivePeriodStories(periodStories))
  } catch (e: any) {
    console.log(e)
    yield put(actions.periodStoriesFailed(e.message))
  }
}

export function* watchGetPeriodStoriesAsync() {
  yield takeLatest(actionTypes.PeriodStoriesRequested, fetchPeriodStories)
}
