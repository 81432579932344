import {InitialDataModel} from '../../project/models/InitialDataModel'
import {BitcoinNotificationModel} from '../models/BitcoinNotificationModel'
import {TblNotificationModel} from '../models/TblNotificationModel'
import {TvlNotificationModel} from '../models/TvlNotificationModel'

export type ScoringModel = {
  data: InitialDataModel
  loading: boolean
  loadingMoreData: boolean
  error?: string
}

export enum NotificationType {
  ContractsTbl = 1,
  ProjectsTvl = 2,
  Bitcoin = 3,
}

export const handleNotification = (notification: any, state: ScoringModel): ScoringModel => {
  switch (notification.notificationType) {
    case NotificationType.ContractsTbl:
      return updateTbl(notification, state)
    case NotificationType.ProjectsTvl:
      return updateTvl(notification, state)
    case NotificationType.Bitcoin:
      return updateBitcoin(notification, state)
    default:
      throw new Error('Notification unknown')
  }
}

const updateTbl = (tblNotification: TblNotificationModel, state: ScoringModel): ScoringModel => {
  const newState = {...state}

  tblNotification.scorableNotifications.forEach((tblScorableNotification) => {
    const inStateScorable = newState.data.scorables.find(
      (scorable) => scorable.id === tblScorableNotification.scorableId
    )

    if (!inStateScorable || !inStateScorable.totalBitcoinLocked) return

    inStateScorable.totalBitcoinLocked.error = tblScorableNotification.error
    inStateScorable.totalBitcoinLocked.errorMessage = tblScorableNotification.errorMessage
    if (tblScorableNotification.error) {
      console.error(tblScorableNotification.errorMessage)
      return
    }

    inStateScorable.totalBitcoinLocked.btcLocked = tblScorableNotification.btcLocked
    inStateScorable.totalBitcoinLocked.btcLockedInUsd = tblScorableNotification.btcLockedInUsd
    inStateScorable.totalBitcoinLocked.btcLockedFromYesterday =
      tblScorableNotification.btcLockedFromYesterday
    inStateScorable.totalBitcoinLocked.btcLockedEvolutionFromYesterday =
      tblScorableNotification.btcLockedEvolutionFromYesterday
    inStateScorable.totalBitcoinLocked.fetchAt = tblScorableNotification.fetchAt

    tblScorableNotification.tblContractNotifications.forEach((tblContractNotifications) => {
      const inStateContract = inStateScorable.contracts.find(
        (inStateContract) => inStateContract.blockchainId === tblContractNotifications.blockchainId
      )
      if (!inStateContract) return

      inStateContract.totalBitcoinLocked.error = tblContractNotifications.error
      inStateContract.totalBitcoinLocked.errorMessage = tblContractNotifications.errorMessage
      if (tblScorableNotification.error) {
        console.error(tblContractNotifications.errorMessage)
        return
      }

      inStateContract.totalBitcoinLocked.btcLocked = tblContractNotifications.btcLocked
      inStateContract.totalBitcoinLocked.btcLockedInUsd = tblContractNotifications.btcLockedInUsd
      inStateContract.totalBitcoinLocked.btcLockedFromYesterday =
        tblContractNotifications.btcLockedFromYesterday
      inStateContract.totalBitcoinLocked.btcLockedEvolutionFromYesterday =
        tblContractNotifications.btcLockedEvolutionFromYesterday
      inStateContract.totalBitcoinLocked.fetchAt = tblContractNotifications.fetchAt
    })
  })

  return newState
}

const updateTvl = (tvlNotification: TvlNotificationModel, state: ScoringModel): ScoringModel => {
  const newState = {...state}

  const inStateScorable = newState.data.scorables.find(
    (scorable) => scorable.id === tvlNotification.scorableId
  )
  if (!inStateScorable) return newState

  inStateScorable.totalValueLocked.error = tvlNotification.error
  inStateScorable.totalValueLocked.errorMessage = tvlNotification.errorMessage
  if (tvlNotification.error) {
    console.error(tvlNotification.errorMessage)
    return newState
  }

  inStateScorable.totalValueLocked.tvl = tvlNotification.tvl
  inStateScorable.totalValueLocked.tvlEvolutionFromYesterday =
    tvlNotification.tvlEvolutionFromYesterday
  inStateScorable.totalValueLocked.fetchAt = tvlNotification.fetchAt

  return newState
}

const updateBitcoin = (
  bitcoinNotification: BitcoinNotificationModel,
  state: ScoringModel
): ScoringModel => {
  const newState = {...state}
  newState.data.bitcoin = {...bitcoinNotification}
  return newState
}
