import {ScorableId} from '../../../enums/ScorableId'
import {ScorableType} from '../../../enums/ScorableType'
import {ScorableModel} from '../../project/models/ScorableModel'

export class ScoringTableMock {
  public static table: Array<ScorableModel> = [
    {
      id: 1,
      name: 'Wrapped BTC (WBTC)',
      video: 'dTCwssZ116A',
      path: 'wbtc',
      type: ScorableType.Token,
      totalValueLocked: {
        loading: false,
        tvl: 11367492360.039999,
        fetchAt: new Date('2022-04-11T14:57:28.3154462'),
      },
      totalBitcoinLocked: {
        btcLocked: 288330.35118457,
        btcLockedInUsd: 11790116390.288252,
        fetchAt: new Date('2022-04-11T15:00:06.9188835'),
      },
      image: 'wbtc.svg',
      website: 'wbtc.network',
      email: '',
      gitHub: {
        organization: 'WrappedBTC',
        repository: 'bitcoin-token-smart-contracts',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.4474238'),
      },

      reddit: '',
      discord: '',
      twitter: {
        username: 'WrappedBTC',
      },
      youtube: '',
      forum: '',
      instagram: '',
      facebook: '',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 46,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'totalSupply',
              address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
              target: '',
              pairsTokenAddresses: [],
            },
          ],
          gas: {
            source: '0xac73d87af4f22467009a85c878cf66e1f98338e023618cca9b517256571846b1',
            transferGasUsed: 54411,
          },
          totalBitcoinLocked: {
            btcLocked: 275829.66608794,
            btcLockedInUsd: 11278950876.001955,
            fetchAt: new Date('2022-04-11T15:00:08.1964614'),
          },
        },
        {
          blockchainId: 23,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'totalSupply',
              address: '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
              target: '',
              pairsTokenAddresses: [],
            },
          ],
          gas: {
            source: '0x93e6b07097a525cb49ee06fae0100c23ae800026dfe6807ed30126024b058a39',
            transferGasUsed: 35382,
          },
          totalBitcoinLocked: {
            btcLocked: 10180.54342377,
            btcLockedInUsd: 416292601.14137906,
            fetchAt: new Date('2022-04-11T15:00:07.2409893'),
          },
        },
        {
          blockchainId: 49,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'totalSupply',
              address: '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
              target: '',
              pairsTokenAddresses: [],
            },
          ],
          gas: {
            source: '0x667490c2754e8c804915ae68c7dab200cde0969d858782480da8dc13a1d9781a',
            transferGasUsed: 479810,
          },
          totalBitcoinLocked: {
            btcLocked: 2320.14167286,
            btcLockedInUsd: 94872913.14491826,
            fetchAt: new Date('2022-04-11T15:00:06.9188835'),
          },
        },
      ],
    },
    {
      id: 6,
      name: 'Binance-Peg BTCB Token (BTCB)',
      video: 'dTCwssZ116A',
      path: 'btcb',
      type: ScorableType.Token,
      totalValueLocked: {
        loading: false,
        tvl: 0,
        fetchAt: new Date('2022-04-11T14:57:28.6685363'),
      },
      totalBitcoinLocked: {
        btcLocked: 112501,
        btcLockedInUsd: 4600278391,
        fetchAt: new Date('2022-04-11T15:00:07.353794'),
      },
      image: 'binance.svg',
      website: 'binance.com',
      email: '',
      gitHub: {
        organization: 'binance',
        repository: 'binance-spot-api-docs',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.4474471'),
      },

      reddit: 'binance',
      discord: 'vKNp6qxw7a',
      youtube: '',
      forum: '',
      instagram: 'binance',
      facebook: 'binance',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 48,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'totalSupply',
              address: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
              target: '',
              pairsTokenAddresses: [],
            },
          ],
          gas: {
            source: '',
            transferGasUsed: 0,
          },
          totalBitcoinLocked: {
            btcLocked: 112501,
            btcLockedInUsd: 4600278391,
            fetchAt: new Date('2022-04-11T15:00:07.353794'),
          },
        },
      ],
    },
    {
      id: 21,
      name: 'Aave',
      video: 'dTCwssZ116A',
      path: 'aave',
      type: ScorableType.FinancialApp,
      totalValueLocked: {
        loading: false,
        tvl: 12971416578.937143,
        fetchAt: new Date('2022-04-11T14:57:29.9765374'),
      },
      totalBitcoinLocked: {
        btcLocked: 44673.43429116,
        btcLockedInUsd: 1826741401.5998235,
        fetchAt: new Date('2022-04-11T15:00:07.2409893'),
      },
      image: 'aave.svg',
      website: 'aave.com',
      email: '',
      gitHub: {
        organization: 'aave',
        repository: 'aave-protocol',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.4474867'),
      },

      reddit: '',
      discord: 'CvKUrqM',
      telegram: {
        channel: 'Aavesome',
        membersCount: 15871,
        updatedDate: new Date('2022-04-10T21:37:19.311142'),
      },
      youtube: '',
      forum: '',
      instagram: 'aave.aave',
      facebook: '',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 46,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'balanceOf',
              address: '0x9ff58f4ffb29fa2266ab25e75e2a8b3503311656',
              target: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
              pairsTokenAddresses: [],
            },
            {
              function: 'balanceOf',
              address: '0x3dfd23a6c5e8bbcfc9581d2e864a68feb6a076d3',
              target: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
              pairsTokenAddresses: [],
            },
          ],

          totalBitcoinLocked: {
            btcLocked: 38023.72885871,
            btcLockedInUsd: 1554828296.7615106,
            fetchAt: new Date('2022-04-11T15:00:08.1964614'),
          },
        },
        {
          blockchainId: 23,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'balanceOf',
              address: '0x5c2ed810328349100a66b82b78a1791b101c9d61',
              target: '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
              pairsTokenAddresses: [],
            },
          ],

          totalBitcoinLocked: {
            btcLocked: 6649.70543245,
            btcLockedInUsd: 271913104.8383129,
            fetchAt: new Date('2022-04-11T15:00:07.2409893'),
          },
        },
      ],
    },
    {
      id: 22,
      name: 'Compound',
      video: 'dTCwssZ116A',
      path: 'compound',
      type: ScorableType.FinancialApp,
      totalValueLocked: {
        loading: false,
        tvl: 6324002564.807493,
        fetchAt: new Date('2022-04-11T14:57:29.9958278'),
      },
      totalBitcoinLocked: {
        btcLocked: 35656.06709281,
        btcLockedInUsd: 1458012239.4920938,
        fetchAt: new Date('2022-04-11T15:00:08.1964614'),
      },
      image: 'compound.svg',
      website: 'compound.finance',
      email: '',
      gitHub: {
        organization: 'compound-finance',
        repository: 'compound-protocol',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.4474892'),
      },
      medium: {page: 'medium.com/compound-finance'},
      reddit: '',
      discord: 'fq6JSPkpJn',

      youtube: '',
      forum: 'www.comp.xyz',
      instagram: '',
      facebook: '',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 46,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'balanceOf',
              address: '0xc11b1268c1a384e55c48c2391d8d480264a3a7f4',
              target: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
              pairsTokenAddresses: [],
            },
            {
              function: 'balanceOf',
              address: '0xccf4429db6322d5c611ee964527d42e5d685dd6a',
              target: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
              pairsTokenAddresses: [],
            },
          ],

          totalBitcoinLocked: {
            btcLocked: 35656.06709281,
            btcLockedInUsd: 1458012239.4920938,
            fetchAt: new Date('2022-04-11T15:00:08.1964614'),
          },
        },
      ],
    },
    {
      id: 24,
      name: 'MakerDAO',
      video: 'dTCwssZ116A',
      path: 'makerdao',
      type: ScorableType.FinancialApp,
      totalValueLocked: {
        loading: false,
        tvl: 14482094146.917871,
        fetchAt: new Date('2022-04-11T14:57:30.0314174'),
      },
      totalBitcoinLocked: {
        btcLocked: 26700.98043804,
        btcLockedInUsd: 1091829791.0918937,
        fetchAt: new Date('2022-04-11T15:00:08.1964614'),
      },
      image: 'makerdao.svg',
      website: 'makerdao.com',
      email: '',
      gitHub: {
        organization: 'makerdao',
        repository: 'go-ethereum',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.4474941'),
      },

      reddit: 'MakerDAO',
      discord: '',
      telegram: {
        channel: 'makerdaoOfficial',
        membersCount: 12508,
        updatedDate: new Date('2022-04-10T21:37:19.311147'),
      },
      youtube: 'MakerDAO',
      forum: 'forum.makerdao.com',
      instagram: '',
      facebook: '',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 46,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'balanceOf',
              address: '0xbf72da2bd84c5170618fbe5914b0eca9638d5eb5',
              target: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
              pairsTokenAddresses: [],
            },
          ],

          totalBitcoinLocked: {
            btcLocked: 26700.98043804,
            btcLockedInUsd: 1091829791.0918937,
            fetchAt: new Date('2022-04-11T15:00:08.1964614'),
          },
        },
      ],
    },
    {
      id: 26,
      name: 'CurveFi',
      video: 'dTCwssZ116A',
      path: 'curvefi',
      type: ScorableType.FinancialApp,
      totalValueLocked: {
        loading: false,
        tvl: 20120150220.76662,
        fetchAt: new Date('2022-04-11T14:57:30.0643208'),
      },
      totalBitcoinLocked: {
        btcLocked: 18661.38032081847,
        btcLockedInUsd: 763082502.698588,
        fetchAt: new Date('2022-04-11T15:00:06.9188835'),
      },
      image: 'curvefi.svg',
      website: 'curve.fi',
      email: '',
      gitHub: {
        organization: 'curvefi',
        repository: 'curve-contract',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.447499'),
      },

      reddit: '',
      discord: '9uEHakc',
      telegram: {
        channel: 'curvefi',
        membersCount: 17028,
        updatedDate: new Date('2022-04-10T21:37:19.3111495'),
      },
      youtube: '',
      forum: '',
      instagram: '',
      facebook: '',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 46,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'balanceOf',
              address: '0x4ca9b3063ec5866a4b82e437059d2c43d1be596f',
              target: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
              pairsTokenAddresses: [],
            },
            {
              function: 'balanceOf',
              address: '0x7fc77b5c7614e1533320ea6ddc2eb61fa00a9714',
              target: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
              pairsTokenAddresses: [],
            },
            {
              function: 'balanceOf',
              address: '0x93054188d876f558f4a66b2ef1d97d16edf0895b',
              target: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
              pairsTokenAddresses: [],
            },
            {
              function: 'balanceOf',
              address: '0x071c661b4deefb59e2a3ddb20db036821eee8f4b',
              target: '0x9be89d2a4cd102d8fecc6bf9da793be995c22541',
              pairsTokenAddresses: [],
            },
            {
              function: 'balanceOf',
              address: '0x4ca9b3063ec5866a4b82e437059d2c43d1be596f',
              target: '0x0316eb71485b0ab14103307bf65a021042c6d380',
              pairsTokenAddresses: [],
            },
            {
              function: 'balanceOf',
              address: '0xd81da8d904b52208541bade1bd6595d8a251f8dd',
              target: '0x8064d9ae6cdf087b1bcd5bdf3531bd5d8c537a68',
              pairsTokenAddresses: [],
            },
            {
              function: 'balanceOf',
              address: '0x7f55dde206dbad629c080068923b36fe9d6bdbef',
              target: '0x5228a22e72ccc52d415ecfd199f99d0665e7733b',
              pairsTokenAddresses: [],
            },
            {
              function: 'balanceOf',
              address: '0x7fc77b5c7614e1533320ea6ddc2eb61fa00a9714',
              target: '0xfe18be6b3bd88a2d2a7f928d00292e7a9963cfc6',
              pairsTokenAddresses: [],
            },
            {
              function: 'balanceOf',
              address: '0xc25099792e9349c7dd09759744ea681c7de2cb66',
              target: '0x8daebade922df735c38c80c7ebd708af50815faa',
              pairsTokenAddresses: [],
            },
            {
              function: 'balanceOf',
              address: '0x93054188d876f558f4a66b2ef1d97d16edf0895b',
              target: '0xeb4c2781e4eba804ce9a9803c67d0893436bb27d',
              pairsTokenAddresses: [],
            },
            {
              function: 'balanceOf',
              address: '0x7fc77b5c7614e1533320ea6ddc2eb61fa00a9714',
              target: '0xeb4c2781e4eba804ce9a9803c67d0893436bb27d',
              pairsTokenAddresses: [],
            },
          ],

          totalBitcoinLocked: {
            btcLocked: 17073.82558108847,
            btcLockedInUsd: 698165801.8362886,
            fetchAt: new Date('2022-04-11T15:00:08.1964614'),
          },
        },
        {
          blockchainId: 23,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'balanceOf',
              address: '0xc2d95eef97ec6c17551d45e77b590dc1f9117c67',
              target: '0xdbf31df14b66535af65aac99c32e9ea844e14501',
              pairsTokenAddresses: [],
            },
          ],

          totalBitcoinLocked: {
            btcLocked: 285.57355591,
            btcLockedInUsd: 11677388.27471581,
            fetchAt: new Date('2022-04-11T15:00:07.2409893'),
          },
        },
        {
          blockchainId: 49,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'balanceOf',
              address: '0x3e01dd8a5e1fb3481f0f589056b428fc308af0fb',
              target: '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
              pairsTokenAddresses: [],
            },
            {
              function: 'balanceOf',
              address: '0x960ea3e3c7fb317332d990873d354e18d7645590',
              target: '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
              pairsTokenAddresses: [],
            },
            {
              function: 'balanceOf',
              address: '0x3e01dd8a5e1fb3481f0f589056b428fc308af0fb',
              target: '0xdbf31df14b66535af65aac99c32e9ea844e14501',
              pairsTokenAddresses: [],
            },
            {
              function: 'balanceOf',
              address: '0x960ea3e3c7fb317332d990873d354e18d7645590',
              target: '0xdbf31df14b66535af65aac99c32e9ea844e14501',
              pairsTokenAddresses: [],
            },
          ],

          totalBitcoinLocked: {
            btcLocked: 1301.98118382,
            btcLockedInUsd: 53239312.58758362,
            fetchAt: new Date('2022-04-11T15:00:06.9188835'),
          },
        },
      ],
    },
    {
      id: 35,
      name: 'Venus',
      video: 'dTCwssZ116A',
      path: 'venus',
      type: ScorableType.FinancialApp,
      totalValueLocked: {
        loading: false,
        tvl: 1498987569.3326643,
        fetchAt: new Date('2022-04-11T14:57:30.4464165'),
      },
      totalBitcoinLocked: {
        btcLocked: 13715.735351930349,
        btcLockedInUsd: 560850134.2757839,
        fetchAt: new Date('2022-04-11T15:00:07.353794'),
      },
      image: 'venus.svg',
      website: 'venus.io',
      email: '',
      gitHub: {
        organization: 'VenusProtocol',
        repository: 'Venus-Protocol',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.4475223'),
      },
      medium: {page: 'medium.com/VenusProtocol'},
      reddit: '',
      discord: '',
      telegram: {
        channel: 'VenusProtocol',
        membersCount: 21139,
        updatedDate: new Date('2022-04-10T21:37:19.3111643'),
      },
      youtube: '',
      forum: '',
      instagram: '',
      facebook: '',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 48,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'balanceOf',
              address: '0x3e01dd8a5e1fb3481f0f589056b428fc308af0fb',
              target: '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
              pairsTokenAddresses: [],
            },
          ],

          totalBitcoinLocked: {
            btcLocked: 13715.735351930349,
            btcLockedInUsd: 560850134.2757839,
            fetchAt: new Date('2022-04-11T15:00:07.353794'),
          },
        },
      ],
    },
    {
      id: 3,
      name: 'renBTC (renBTC)',
      video: 'dTCwssZ116A',
      path: 'renbtc',
      type: ScorableType.Token,
      totalValueLocked: {
        loading: false,
        tvl: 1123001095.8508193,
        fetchAt: new Date('2022-04-11T14:57:28.6529683'),
      },
      totalBitcoinLocked: {
        btcLocked: 13694.465008,
        btcLockedInUsd: 559980368.642128,
        fetchAt: new Date('2022-04-11T15:00:06.9188835'),
      },
      image: 'renbtc.svg',
      website: 'renproject.io',
      email: '',
      gitHub: {
        organization: 'renproject',
        repository: 'bridge-v2',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.4474379'),
      },
      medium: {page: 'medium.com/renproject'},
      reddit: 'renproject',
      discord: 'QeVXaTWVWn',

      youtube: '',
      forum: 'forum.renproject.io',
      instagram: '',
      facebook: '',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 46,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'totalSupply',
              address: '0xeb4c2781e4eba804ce9a9803c67d0893436bb27d',
              target: '',
              pairsTokenAddresses: [],
            },
          ],
          gas: {
            source: '0xb7a2e7da7b45a7d7057d18b4071502749c3464178e941a279c40c171317f8be1',
            transferGasUsed: 37037,
          },
          totalBitcoinLocked: {
            btcLocked: 12487.72261279,
            btcLockedInUsd: 510635465.3595959,
            fetchAt: new Date('2022-04-11T15:00:08.1964614'),
          },
        },
        {
          blockchainId: 23,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'totalSupply',
              address: '0xdbf31df14b66535af65aac99c32e9ea844e14501',
              target: '',
              pairsTokenAddresses: [],
            },
          ],
          gas: {
            source: '0x7ea991190e1dd7472687d9748ec10a76f3abd33b961a764aea5aa5abe0f82d05',
            transferGasUsed: 54313,
          },
          totalBitcoinLocked: {
            btcLocked: 609.31414352,
            btcLockedInUsd: 24915464.64267632,
            fetchAt: new Date('2022-04-11T15:00:07.2409893'),
          },
        },
        {
          blockchainId: 49,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'totalSupply',
              address: '0xdbf31df14b66535af65aac99c32e9ea844e14501',
              target: '',
              pairsTokenAddresses: [],
            },
          ],
          gas: {
            source: '0x2de59f4f482acae93df24299d340e8c54197e5aaec5c0389da533ddc664bccf2',
            transferGasUsed: 911373,
          },
          totalBitcoinLocked: {
            btcLocked: 597.42825169,
            btcLockedInUsd: 24429438.63985579,
            fetchAt: new Date('2022-04-11T15:00:06.9188835'),
          },
        },
      ],
    },
    {
      id: 34,
      name: 'Tranchess',
      video: 'dTCwssZ116A',
      path: 'tranchess',
      type: ScorableType.FinancialApp,
      totalValueLocked: {
        loading: false,
        tvl: 560073721.9767032,
        fetchAt: new Date('2022-04-11T14:57:30.4348469'),
      },
      totalBitcoinLocked: {
        btcLocked: 10430.271586470786,
        btcLockedInUsd: 426504235.4423769,
        fetchAt: new Date('2022-04-11T15:00:07.353794'),
      },
      image: 'tranchess.svg',
      website: 'tranchess.com',
      email: '',
      gitHub: {
        organization: 'tranchess',
        repository: 'contract-core',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.4475193'),
      },
      medium: {page: 'medium.com/@tranchess'},
      reddit: '',
      discord: 'tKxAq78VBr',
      telegram: {
        channel: 'tranchess',
        membersCount: 9729,
        updatedDate: new Date('2022-04-10T21:37:19.3111618'),
      },
      youtube: '',
      forum: '',
      instagram: '',
      facebook: '',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 48,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'balanceOf',
              address: '0x3e01dd8a5e1fb3481f0f589056b428fc308af0fb',
              target: '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
              pairsTokenAddresses: [],
            },
          ],

          totalBitcoinLocked: {
            btcLocked: 10430.271586470786,
            btcLockedInUsd: 426504235.4423769,
            fetchAt: new Date('2022-04-11T15:00:07.353794'),
          },
        },
      ],
    },
    {
      id: 23,
      name: 'Polygon',
      video: 'dTCwssZ116A',
      path: 'polygon',
      type: ScorableType.Blockchain,
      totalValueLocked: {
        loading: false,
        tvl: 191846.30083276937,
        fetchAt: new Date('2022-04-11T14:57:30.0089121'),
      },
      totalBitcoinLocked: {
        btcLocked: 10262.54613827872,
        btcLockedInUsd: 419645774.1403551,
        fetchAt: new Date('2022-04-11T15:00:08.1964614'),
      },
      image: 'polygon.svg',
      website: 'polygon.technology',
      email: '',
      gitHub: {
        organization: 'maticnetwork',
        repository: 'go-ethereum',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.4474917'),
      },

      reddit: '0xPolygon',
      discord: 'polygon',
      telegram: {
        channel: 'polygonofficial',
        membersCount: 68871,
        updatedDate: new Date('2022-04-10T21:37:19.3111445'),
      },
      youtube: 'PolygonTV',
      forum: 'forum.matic.network',
      instagram: '',
      facebook: '',
      about: 'This is the about text',
      contracts: [],
    },
    {
      id: 8,
      name: 'Binance Wrapped BTC (BBTC)',
      video: 'dTCwssZ116A',
      path: 'bbtc',
      type: ScorableType.Token,
      totalValueLocked: {
        loading: false,
        tvl: 0,
        fetchAt: new Date('2022-04-11T14:57:28.6823375'),
      },
      totalBitcoinLocked: {
        btcLocked: 9900,
        btcLockedInUsd: 404820900,
        fetchAt: new Date('2022-04-11T15:00:08.1964614'),
      },
      image: 'binance.svg',
      website: 'binance.com',
      email: '',
      gitHub: {
        organization: 'binance',
        repository: 'binance-spot-api-docs',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.4474546'),
      },

      reddit: 'binance',
      discord: 'vKNp6qxw7a',

      youtube: '',
      forum: '',
      instagram: 'binance',
      facebook: 'binance',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 46,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'totalSupply',
              address: '0x9be89d2a4cd102d8fecc6bf9da793be995c22541',
              target: '',
              pairsTokenAddresses: [],
            },
          ],
          gas: {
            source: '',
            transferGasUsed: 0,
          },
          totalBitcoinLocked: {
            btcLocked: 9900,
            btcLockedInUsd: 404820900,
            fetchAt: new Date('2022-04-11T15:00:08.1964614'),
          },
        },
      ],
    },
    {
      id: 29,
      name: 'UniSwap',
      video: 'dTCwssZ116A',
      path: 'uniswap',
      type: ScorableType.FinancialApp,
      totalValueLocked: {
        loading: false,
        tvl: 7647758221.512747,
        fetchAt: new Date('2022-04-11T14:57:30.2351923'),
      },
      totalBitcoinLocked: {
        btcLocked: 9016.912074573655,
        btcLockedInUsd: 368710551.64139134,
        fetchAt: new Date('2022-04-11T15:00:08.1964614'),
      },
      image: 'uniswap.svg',
      website: 'uniswap.org',
      email: 'contact@uniswap.org',
      gitHub: {
        organization: 'Uniswap',
        repository: 'v3-core',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.4475063'),
      },

      reddit: 'UniSwap',
      discord: 'FCfyBSbCU5',

      youtube: '',
      forum: 'gov.uniswap.org',
      instagram: '',
      facebook: '',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 46,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: true,
          howWeGetTbl: [
            {
              function: 'totalSupply',
              address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
              target: '',
              pairsTokenAddresses: [],
            },
          ],

          totalBitcoinLocked: {
            btcLocked: 9016.912074573655,
            btcLockedInUsd: 368710551.64139134,
            fetchAt: new Date('2022-04-11T15:00:08.1964614'),
          },
        },
      ],
    },
    {
      id: 27,
      name: 'Balancer',
      video: 'dTCwssZ116A',
      path: 'balancer',
      type: ScorableType.FinancialApp,
      totalValueLocked: {
        loading: false,
        tvl: 3435571344.590598,
        fetchAt: new Date('2022-04-11T14:57:30.2064172'),
      },
      totalBitcoinLocked: {
        btcLocked: 4377.683537849552,
        btcLockedInUsd: 179007857.546206,
        fetchAt: new Date('2022-04-11T15:00:06.9188835'),
      },
      image: 'balancer.svg',
      website: 'balancer.fi',
      email: 'contact@balancer.finance',
      gitHub: {
        organization: 'balancer-labs',
        repository: 'balancer-core',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.4475014'),
      },
      medium: {page: 'medium.com/balancer-protocol'},
      reddit: '',
      discord: 'cmERQ9dK',

      youtube: '',
      forum: '',
      instagram: '',
      facebook: '',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 46,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'pools',
              address: '0x3e01dd8a5e1fb3481f0f589056b428fc308af0fb',
              target: '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
              pairsTokenAddresses: [],
            },
          ],

          totalBitcoinLocked: {
            btcLocked: 3395.8904527895515,
            btcLockedInUsd: 138861356.50501755,
            fetchAt: new Date('2022-04-11T15:00:08.1964614'),
          },
        },
        {
          blockchainId: 23,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'balanceOf',
              address: '0x3e01dd8a5e1fb3481f0f589056b428fc308af0fb',
              target: '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
              pairsTokenAddresses: [],
            },
          ],

          totalBitcoinLocked: {
            btcLocked: 811.93615303,
            btcLockedInUsd: 33200881.23354973,
            fetchAt: new Date('2022-04-11T15:00:07.2409893'),
          },
        },
        {
          blockchainId: 49,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'totalSupply',
              address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
              target: '',
              pairsTokenAddresses: [],
            },
          ],

          totalBitcoinLocked: {
            btcLocked: 169.85693203,
            btcLockedInUsd: 6945619.80763873,
            fetchAt: new Date('2022-04-11T15:00:06.9188835'),
          },
        },
      ],
    },
    {
      id: 25,
      name: 'SushiSwap',
      video: 'dTCwssZ116A',
      path: 'sushiswap',
      type: ScorableType.FinancialApp,
      totalValueLocked: {
        loading: false,
        tvl: 3781881069.2188597,
        fetchAt: new Date('2022-04-11T14:57:30.0515036'),
      },
      totalBitcoinLocked: {
        btcLocked: 3810.360581862594,
        btcLockedInUsd: 155809454.55294332,
        fetchAt: new Date('2022-04-11T15:00:07.2409893'),
      },
      image: 'sushi.svg',
      website: 'sushi.com',
      email: 'core@sushi.com',
      gitHub: {
        organization: 'sushiswap',
        repository: 'sushiswap',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.4474965'),
      },
      medium: {page: 'sushichef.medium.com'},
      reddit: '',
      discord: 'CD2YdZzb7Z',

      youtube: '',
      forum: 'forum.sushi.com',
      instagram: '',
      facebook: '',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 46,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: true,
          howWeGetTbl: [
            {
              function: 'pairs',
              address: '',
              target: '',
              pairsTokenAddresses: [
                '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
                '0x0316eb71485b0ab14103307bf65a021042c6d380',
                '0xeb4c2781e4eba804ce9a9803c67d0893436bb27d',
                '0x5228a22e72ccc52d415ecfd199f99d0665e7733b',
                '0x8064d9ae6cdf087b1bcd5bdf3531bd5d8c537a68',
                '0x3212b29e33587a00fb1c83346f5dbfa69a458923',
                '0x9be89d2a4cd102d8fecc6bf9da793be995c22541',
                '0x8daebade922df735c38c80c7ebd708af50815faa',
                '0xfe18be6b3bd88a2d2a7f928d00292e7a9963cfc6',
                '0x51600b0cff6bbf79e7767158c41fd15e968ec404',
                '0xe1406825186d63980fd6e2ec61888f7b91c4bae4',
                '0x945facb997494cc2570096c74b5f66a3507330a1',
                '0x0327112423f3a68efdf1fcf402f6c5cb9f7c33fd',
                '0xef6e45af9a422c5469928f927ca04ed332322e2e',
              ],
            },
          ],

          totalBitcoinLocked: {
            btcLocked: 3126.0526121025937,
            btcLockedInUsd: 127827417.36148715,
            fetchAt: new Date('2022-04-11T15:00:08.1964614'),
          },
        },
        {
          blockchainId: 23,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: true,
          howWeGetTbl: [
            {
              function: 'pairs',
              address: '',
              target: '',
              pairsTokenAddresses: [
                '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
                '0xdbf31df14b66535af65aac99c32e9ea844e14501',
                '0x90fb380ddebaf872cc1f8d8e8c604ff2f4697c19',
              ],
            },
          ],

          totalBitcoinLocked: {
            btcLocked: 684.30796976,
            btcLockedInUsd: 27982037.19145616,
            fetchAt: new Date('2022-04-11T15:00:07.2409893'),
          },
        },
      ],
    },
    {
      id: 28,
      name: 'Bancor',
      video: 'dTCwssZ116A',
      path: 'bancor',
      type: ScorableType.FinancialApp,
      totalValueLocked: {
        loading: false,
        tvl: 1070035193.882885,
        fetchAt: new Date('2022-04-11T14:57:30.2203595'),
      },
      totalBitcoinLocked: {
        btcLocked: 3698.38442723,
        btcLockedInUsd: 151230637.61386192,
        fetchAt: new Date('2022-04-11T15:00:08.1964614'),
      },
      image: 'bancor.svg',
      website: 'bancor.network',
      email: '',
      gitHub: {
        organization: 'bancorprotocol',
        repository: 'contracts-solidity',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.4475039'),
      },

      reddit: 'Bancor',
      discord: 'CAm3Ncyrxk',
      telegram: {
        channel: 'bancor',
        membersCount: 10181,
        updatedDate: new Date('2022-04-10T21:37:19.311152'),
      },
      youtube: '',
      forum: '',
      instagram: '',
      facebook: '',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 46,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'balanceOf',
              address: '0x3e01dd8a5e1fb3481f0f589056b428fc308af0fb',
              target: '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
              pairsTokenAddresses: [],
            },
          ],

          totalBitcoinLocked: {
            btcLocked: 3698.38442723,
            btcLockedInUsd: 151230637.61386192,
            fetchAt: new Date('2022-04-11T15:00:08.1964614'),
          },
        },
      ],
    },
    {
      id: 10,
      name: 'Synth sBTC (sBTC)',
      video: 'dTCwssZ116A',
      path: 'sbtc',
      type: ScorableType.Token,
      totalValueLocked: {
        loading: false,
        tvl: 974867050.5013657,
        fetchAt: new Date('2022-04-11T14:57:28.6973379'),
      },
      totalBitcoinLocked: {
        btcLocked: 3261.427574021595,
        btcLockedInUsd: 133363034.92931703,
        fetchAt: new Date('2022-04-11T15:00:08.1964614'),
      },
      image: 'sbtc.svg',
      website: 'synthetix.io',
      email: '',
      gitHub: {
        organization: 'synthetixio',
        repository: 'synthetix',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.4474597'),
      },

      reddit: '',
      discord: 'AEdUHzt',

      youtube: '',
      forum: '',
      instagram: '',
      facebook: '',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 46,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'totalSupply',
              address: '0xfe18be6b3bd88a2d2a7f928d00292e7a9963cfc6',
              target: '',
              pairsTokenAddresses: [],
            },
          ],
          gas: {
            source: '',
            transferGasUsed: 0,
          },
          totalBitcoinLocked: {
            btcLocked: 3261.427574021595,
            btcLockedInUsd: 133363034.92931703,
            fetchAt: new Date('2022-04-11T15:00:08.1964614'),
          },
        },
      ],
    },
    {
      id: 37,
      name: 'PancakeSwap',
      video: 'dTCwssZ116A',
      path: 'pancakeswap',
      type: ScorableType.FinancialApp,
      totalValueLocked: {
        loading: false,
        tvl: 4829399276.828765,
        fetchAt: new Date('2022-04-11T14:57:30.4868957'),
      },
      totalBitcoinLocked: {
        btcLocked: 2853.9621702571812,
        btcLockedInUsd: 116701367.10398641,
        fetchAt: new Date('2022-04-11T15:00:07.353794'),
      },
      image: 'pancake.svg',
      website: 'pancakeswap.finance',
      email: '',
      gitHub: {
        organization: 'pancakeswap',
        repository: 'pancake-farm',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.4475878'),
      },

      reddit: 'pancakeswap',
      discord: 'pancakeswap',
      telegram: {
        channel: 'pancakeswap',
        membersCount: 81805,
        updatedDate: new Date('2022-04-10T21:37:19.3111668'),
      },
      youtube: '',
      forum: '',
      instagram: 'pancakeswap_official',
      facebook: '',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 48,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: true,
          howWeGetTbl: [
            {
              function: 'totalSupply',
              address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
              target: '',
              pairsTokenAddresses: [],
            },
          ],

          totalBitcoinLocked: {
            btcLocked: 2853.9621702571812,
            btcLockedInUsd: 116701367.10398641,
            fetchAt: new Date('2022-04-11T15:00:07.353794'),
          },
        },
      ],
    },
    {
      id: 45,
      name: 'RSK Smart Bitcoin (RBTC)',
      video: 'dTCwssZ116A',
      path: 'rbtc',
      type: ScorableType.Token,
      totalValueLocked: {
        loading: false,
        tvl: 0,
        fetchAt: new Date('2022-04-11T14:57:31.373206'),
      },
      totalBitcoinLocked: {
        btcLocked: 2352.101996084837,
        btcLockedInUsd: 96179802.72190507,
        fetchAt: new Date('2022-04-11T15:00:06.8805803'),
      },
      image: 'rsk.svg',
      website: 'rsk.co',
      email: '',
      gitHub: {
        organization: 'rsksmart',
        repository: 'rskj',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.44761'),
      },
      medium: {page: 'medium.com/@RSKNews'},
      reddit: 'rootstock',
      discord: 'fPerbqcWGE',
      telegram: {
        channel: 'rskofficialcommunity',
        membersCount: 4304,
        updatedDate: new Date('2022-04-10T21:37:19.3111837'),
      },
      youtube: 'rsksmart',
      forum: '',
      instagram: 'rsksmart',
      facebook: 'RSKsmart',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 47,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'balance',
              address: '0x0000000000000000000000000000000001000006',
              target: '',
              pairsTokenAddresses: [],
            },
          ],

          totalBitcoinLocked: {
            btcLocked: 2352.101996084837,
            btcLockedInUsd: 96179802.72190507,
            fetchAt: new Date('2022-04-11T15:00:06.8805803'),
          },
        },
      ],
    },
    {
      id: 36,
      name: 'Alpaca',
      video: 'dTCwssZ116A',
      path: 'alpaca',
      type: ScorableType.FinancialApp,
      totalValueLocked: {
        loading: false,
        tvl: 837746412.4514061,
        fetchAt: new Date('2022-04-11T14:57:30.4596031'),
      },
      totalBitcoinLocked: {
        btcLocked: 1564.2670635507127,
        btcLockedInUsd: 63964444.49565219,
        fetchAt: new Date('2022-04-11T15:00:07.353794'),
      },
      image: 'alpaca.svg',
      website: 'alpaca.markets',
      email: '',
      gitHub: {
        organization: 'alpacahq',
        repository: 'marketstore',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.4475257'),
      },

      reddit: '',
      discord: '',
      youtube: '',
      forum: 'forum.alpaca.markets',
      instagram: '',
      facebook: 'alpacahq',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 48,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'balanceOf',
              address: '0x3e01dd8a5e1fb3481f0f589056b428fc308af0fb',
              target: '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
              pairsTokenAddresses: [],
            },
          ],

          totalBitcoinLocked: {
            btcLocked: 1564.2670635507127,
            btcLockedInUsd: 63964444.49565219,
            fetchAt: new Date('2022-04-11T15:00:07.353794'),
          },
        },
      ],
    },
    {
      id: 7,
      name: 'The Tokenized Bitcoin (imBTC)',
      video: 'dTCwssZ116A',
      path: 'imbtc',
      type: ScorableType.Token,
      totalValueLocked: {
        loading: false,
        tvl: 59834466.44,
        fetchAt: new Date('2022-04-11T14:57:28.6817074'),
      },
      totalBitcoinLocked: {
        btcLocked: 1451.86624046,
        btcLockedInUsd: 59368262.43864986,
        fetchAt: new Date('2022-04-11T15:00:08.1964614'),
      },
      image: 'imbtc.svg',
      website: 'https://tokenlon.im/imBTC',
      email: 'bd@tokenlon.im',
      gitHub: {
        organization: '',
        repository: '',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.4474502'),
      },
      medium: {page: 'medium.com/@tokenlon'},
      reddit: '',
      discord: 'ZFf4Cye',
      youtube: '',
      forum: 'forum.tokenlon.im',
      instagram: '',
      facebook: '',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 46,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'totalSupply',
              address: '0x3212b29e33587a00fb1c83346f5dbfa69a458923',
              target: '',
              pairsTokenAddresses: [],
            },
          ],
          gas: {
            source: '',
            transferGasUsed: 0,
          },
          totalBitcoinLocked: {
            btcLocked: 1451.86624046,
            btcLockedInUsd: 59368262.43864986,
            fetchAt: new Date('2022-04-11T15:00:08.1964614'),
          },
        },
      ],
    },
    {
      id: 9,
      name: 'tBTC (TBTC)',
      video: 'dTCwssZ116A',
      path: 'tbtc',
      type: ScorableType.Token,
      totalValueLocked: {
        loading: false,
        tvl: 0,
        fetchAt: new Date('2022-04-11T14:57:28.6829109'),
      },
      totalBitcoinLocked: {
        btcLocked: 835.43,
        btcLockedInUsd: 34161568.13,
        fetchAt: new Date('2022-04-11T15:00:08.1964614'),
      },
      image: 'tbtc.svg',
      website: 'tbtc.network',
      email: 'shh@keep.network',
      gitHub: {
        organization: 'keep-network',
        repository: 'tbtc',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.4474572'),
      },

      reddit: '',
      discord: 'TytRYBe',
      youtube: '',
      forum: '',
      instagram: '',
      facebook: '',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 46,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'totalSupply',
              address: '0x8daebade922df735c38c80c7ebd708af50815faa',
              target: '',
              pairsTokenAddresses: [],
            },
          ],
          gas: {
            source: '',
            transferGasUsed: 0,
          },
          totalBitcoinLocked: {
            btcLocked: 835.43,
            btcLockedInUsd: 34161568.13,
            fetchAt: new Date('2022-04-11T15:00:08.1964614'),
          },
        },
      ],
    },
    {
      id: 39,
      name: 'QuickSwap',
      video: 'dTCwssZ116A',
      path: 'quickswap',
      type: ScorableType.FinancialApp,
      totalValueLocked: {
        loading: false,
        tvl: 778066630.9423289,
        fetchAt: new Date('2022-04-11T14:57:30.5209341'),
      },
      totalBitcoinLocked: {
        btcLocked: 745.45709251,
        btcLockedInUsd: 30482485.96982641,
        fetchAt: new Date('2022-04-11T15:00:07.2409893'),
      },
      image: 'quickswap.svg',
      website: 'quickswap.exchange',
      email: '',
      gitHub: {
        organization: 'QuickSwap',
        repository: 'quickswap-core',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.4475953'),
      },

      reddit: '',
      discord: '',
      youtube: '',
      forum: '',
      instagram: '',
      facebook: '',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 23,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: true,
          howWeGetTbl: [
            {
              function: 'totalSupply',
              address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
              target: '',
              pairsTokenAddresses: [],
            },
          ],

          totalBitcoinLocked: {
            btcLocked: 745.45709251,
            btcLockedInUsd: 30482485.96982641,
            fetchAt: new Date('2022-04-11T15:00:07.2409893'),
          },
        },
      ],
    },
    {
      id: 43,
      name: 'Gmx',
      video: 'dTCwssZ116A',
      path: 'gmx',
      type: ScorableType.FinancialApp,
      totalValueLocked: {
        loading: false,
        tvl: 340064679.242458,
        fetchAt: new Date('2022-04-11T14:57:31.2154308'),
      },
      totalBitcoinLocked: {
        btcLocked: 681.04428843,
        btcLockedInUsd: 27848581.99819113,
        fetchAt: new Date('2022-04-11T15:00:06.9188835'),
      },
      image: 'gmx.svg',
      website: 'gmx.io',
      email: '',
      gitHub: {
        organization: 'gmx-io',
        repository: 'gmx-contracts',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.4476051'),
      },
      medium: {page: 'medium.com/@gmx.io'},
      reddit: '',
      discord: 'cxjZYR4gQK',
      telegram: {
        channel: 'GMX_IO',
        membersCount: 8432,
        updatedDate: new Date('2022-04-10T21:37:19.3111787'),
      },
      youtube: '',
      forum: '',
      instagram: '',
      facebook: '',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 49,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'totalSupply',
              address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
              target: '',
              pairsTokenAddresses: [],
            },
          ],

          totalBitcoinLocked: {
            btcLocked: 681.04428843,
            btcLockedInUsd: 27848581.99819113,
            fetchAt: new Date('2022-04-11T15:00:06.9188835'),
          },
        },
      ],
    },
    {
      id: 17,
      name: 'MoC',
      video: 'dTCwssZ116A',
      path: 'moc',
      type: ScorableType.FinancialApp,
      totalValueLocked: {
        loading: false,
        tvl: 43926957.69,
        fetchAt: new Date('2022-04-11T14:57:29.5875758'),
      },
      totalBitcoinLocked: {
        btcLocked: 628.5310829451266,
        btcLockedInUsd: 25701264.512709174,
        fetchAt: new Date('2022-04-11T15:00:06.8805803'),
      },
      image: 'moc.svg',
      website: 'moneyonchain.com',
      email: '',
      gitHub: {
        organization: 'money-on-chain',
        repository: 'main-RBTC-contract',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.4474769'),
      },

      reddit: '',
      discord: '9pt728aD5K',
      telegram: {
        channel: 'MoneyOnChainCommunity',
        membersCount: 737,
        updatedDate: new Date('2022-04-10T21:37:19.3111318'),
      },
      youtube: '',
      forum: 'forum.moneyonchain.com',
      instagram: '',
      facebook: '',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 47,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'balance',
              address: '0xf773b590af754d597770937fa8ea7abdf2668370',
              target: '',
              pairsTokenAddresses: [],
            },
          ],

          totalBitcoinLocked: {
            btcLocked: 628.5310829451266,
            btcLockedInUsd: 25701264.512709174,
            fetchAt: new Date('2022-04-11T15:00:06.8805803'),
          },
        },
      ],
    },
    {
      id: 4,
      name: 'pTokens BTC (pBTC)',
      video: 'dTCwssZ116A',
      path: 'pbtc',
      type: ScorableType.Token,
      totalValueLocked: {
        loading: false,
        tvl: 0,
        fetchAt: new Date('2022-04-11T14:57:28.653545'),
      },
      totalBitcoinLocked: {
        btcLocked: 609.0706978453373,
        btcLockedInUsd: 24905509.905593693,
        fetchAt: new Date('2022-04-11T15:00:08.1964614'),
      },
      image: 'pbtc.png',
      website: 'ptokens.io',
      email: 'info@provable.xyz',
      gitHub: {
        organization: 'provable-things',
        repository: 'ethereum-bridge',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.447441'),
      },
      medium: {page: '@provablethings'},
      reddit: 'pTokens',
      discord: '',
      telegram: {
        channel: 'pNetworkDefi',
        membersCount: 6780,
        updatedDate: new Date('2022-04-10T21:37:19.3111155'),
      },
      youtube: '',
      forum: '',
      instagram: '',
      facebook: '',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 46,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'totalSupply',
              address: '0x5228a22e72ccc52d415ecfd199f99d0665e7733b',
              target: '',
              pairsTokenAddresses: [],
            },
          ],
          gas: {
            source: '0x1d3bb2df0bd74386d3dd439357b63d19b3fc31f2c9e866edbb05169c12ed44d9',
            transferGasUsed: 43143,
          },
          totalBitcoinLocked: {
            btcLocked: 609.0706978453373,
            btcLockedInUsd: 24905509.905593693,
            fetchAt: new Date('2022-04-11T15:00:08.1964614'),
          },
        },
      ],
    },
    {
      id: 5,
      name: 'BoringDAO BTC (oBTC)',
      video: 'dTCwssZ116A',
      path: 'obtc',
      type: ScorableType.Token,
      totalValueLocked: {
        loading: false,
        tvl: 9966309.466516258,
        fetchAt: new Date('2022-04-11T14:57:28.6679406'),
      },
      totalBitcoinLocked: {
        btcLocked: 573.4231903821466,
        btcLockedInUsd: 23447847.677916355,
        fetchAt: new Date('2022-04-11T15:00:07.2409893'),
      },
      image: 'obtc.svg',
      website: 'boringdao.com',
      email: '',
      gitHub: {
        organization: 'BoringDAO',
        repository: 'boringDAO-bridge',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.4474441'),
      },
      medium: {page: 'theboringdao.medium.com'},
      reddit: '',
      discord: 'zCQ2ddp',
      telegram: {
        channel: 'boringDAO',
        membersCount: 15605,
        updatedDate: new Date('2022-04-10T21:37:19.3111193'),
      },
      youtube: '',
      forum: 'forum.boringdao.com',
      instagram: '',
      facebook: '',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 46,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'totalSupply',
              address: '0x8064d9ae6cdf087b1bcd5bdf3531bd5d8c537a68',
              target: '',
              pairsTokenAddresses: [],
            },
          ],
          gas: {
            source: '0xb675f62351f0d48a7678a5d14f2d17f982dacde8ae460a20877c1c5a6ba40ece',
            transferGasUsed: 36816,
          },
          totalBitcoinLocked: {
            btcLocked: 559.8354908341216,
            btcLockedInUsd: 22892233.05569807,
            fetchAt: new Date('2022-04-11T15:00:08.1964614'),
          },
        },
        {
          blockchainId: 23,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'totalSupply',
              address: '0x90fb380ddebaf872cc1f8d8e8c604ff2f4697c19',
              target: '',
              pairsTokenAddresses: [],
            },
          ],
          gas: {
            source: '0xcef11e1a64a95acf40f79a33d1cc1e23fa2e097bce6f5999f33d8c2d1aea0bd3',
            transferGasUsed: 32560,
          },
          totalBitcoinLocked: {
            btcLocked: 13.58769954802487,
            btcLockedInUsd: 555614.622218285,
            fetchAt: new Date('2022-04-11T15:00:07.2409893'),
          },
        },
      ],
    },
    {
      id: 18,
      name: 'Sovryn',
      video: 'dTCwssZ116A',
      path: 'sovryn',
      type: ScorableType.FinancialApp,
      totalValueLocked: {
        loading: false,
        tvl: 70371229.27556634,
        fetchAt: new Date('2022-04-11T14:57:29.6169049'),
      },
      totalBitcoinLocked: {
        btcLocked: 272.1258382974596,
        btcLockedInUsd: 11127497.653821422,
        fetchAt: new Date('2022-04-11T15:00:06.8805803'),
      },
      image: 'sovryn.svg',
      website: 'sovryn.app',
      email: 'community@sovryn.app',
      gitHub: {
        organization: 'DistributedCollective',
        repository: 'Sovryn-smart-contracts',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.4474794'),
      },

      reddit: '',
      discord: 'J22WS6z',
      telegram: {
        channel: 'SovrynBitcoin',
        membersCount: 8226,
        updatedDate: new Date('2022-04-10T21:37:19.3111343'),
      },
      youtube: '',
      forum: '',
      instagram: '',
      facebook: '',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 47,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'balance',
              address: '0xf773b590af754d597770937fa8ea7abdf2668370',
              target: '',
              pairsTokenAddresses: [],
            },
          ],

          totalBitcoinLocked: {
            btcLocked: 272.1258382974596,
            btcLockedInUsd: 11127497.653821422,
            fetchAt: new Date('2022-04-11T15:00:06.8805803'),
          },
        },
      ],
    },
    {
      id: 42,
      name: 'Gravity',
      video: 'dTCwssZ116A',
      path: 'gravity',
      type: ScorableType.FinancialApp,
      totalValueLocked: {
        loading: false,
        tvl: 110906.3001497954,
        fetchAt: new Date('2022-04-11T14:57:31.1995755'),
      },
      totalBitcoinLocked: {
        btcLocked: 152.24175996,
        btcLockedInUsd: 6225317.80652436,
        fetchAt: new Date('2022-04-11T15:00:07.2409893'),
      },
      image: 'gravityfinance.svg',
      website: 'gravityfinance.io',
      email: '',
      gitHub: {
        organization: 'inthenextversion',
        repository: 'gravity-core',

        type: 2,
        updatedDate: new Date('2022-04-10T21:51:02.4476027'),
      },
      medium: {page: 'gravityfinance.medium.com'},
      reddit: '',
      discord: 'fYAf4V3qXs',

      youtube: '',
      forum: '',
      instagram: '',
      facebook: '',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 23,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: true,
          howWeGetTbl: [
            {
              function: 'totalSupply',
              address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
              target: '',
              pairsTokenAddresses: [],
            },
          ],

          totalBitcoinLocked: {
            btcLocked: 152.24175996,
            btcLockedInUsd: 6225317.80652436,
            fetchAt: new Date('2022-04-11T15:00:07.2409893'),
          },
        },
      ],
    },
    {
      id: 13,
      name: 'mStable BTC (mBTC)',
      video: 'dTCwssZ116A',
      path: 'mbtc',
      type: ScorableType.Token,
      totalValueLocked: {
        loading: false,
        tvl: 93733482.53550905,
        fetchAt: new Date('2022-04-11T14:57:29.0491516'),
      },
      totalBitcoinLocked: {
        btcLocked: 79.79413747496383,
        btcLockedInUsd: 3262862.075488746,
        fetchAt: new Date('2022-04-11T15:00:08.1964614'),
      },
      image: 'mstable.svg',
      website: 'mstable.org',
      email: 'info@mstable.org',
      gitHub: {
        organization: 'mstable',
        repository: 'mStable-contracts',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.4474671'),
      },
      medium: {page: 'medium.com/mstable'},
      reddit: '',
      discord: 'pgCVG7e',
      telegram: {
        channel: 'mstableofficial',
        membersCount: 2291,
        updatedDate: new Date('2022-04-10T21:37:19.3111268'),
      },
      youtube: '',
      forum: '',
      instagram: '',
      facebook: '',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 46,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'totalSupply',
              address: '0x945facb997494cc2570096c74b5f66a3507330a1',
              target: '',
              pairsTokenAddresses: [],
            },
          ],
          gas: {
            source: '',
            transferGasUsed: 0,
          },
          totalBitcoinLocked: {
            btcLocked: 79.79413747496383,
            btcLockedInUsd: 3262862.075488746,
            fetchAt: new Date('2022-04-11T15:00:08.1964614'),
          },
        },
      ],
    },
    {
      id: 16,
      name: 'dForce',
      video: 'dTCwssZ116A',
      path: 'dforce',
      type: ScorableType.FinancialApp,
      totalValueLocked: {
        loading: false,
        tvl: 300629138.620881,
        fetchAt: new Date('2022-04-11T14:57:29.2558903'),
      },
      totalBitcoinLocked: {
        btcLocked: 69.0226441,
        btcLockedInUsd: 2822404.9398931,
        fetchAt: new Date('2022-04-11T15:00:06.9188835'),
      },
      image: 'dforce.svg',
      website: 'dforce.network',
      email: 'contact@dforce.network',
      gitHub: {
        organization: 'dforce-network',
        repository: 'dToken',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.4474745'),
      },
      medium: {page: 'medium.com/dforcenet'},
      reddit: 'dForceNetwork',
      discord: 'c2PC8SM',
      telegram: {
        channel: 'dforcenet',
        membersCount: 3683,
        updatedDate: new Date('2022-04-10T21:37:19.3111293'),
      },
      youtube: 'channel/UCvvI93uh1tHIc-49eejricQ',
      forum: 'forum.dforce.network',
      instagram: '',
      facebook: '',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 49,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'balanceOf',
              address: '0xd3204e4189becd9cd957046a8e4a643437ee0acc',
              target: '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
              pairsTokenAddresses: [],
            },
          ],

          totalBitcoinLocked: {
            btcLocked: 69.0226441,
            btcLockedInUsd: 2822404.9398931,
            fetchAt: new Date('2022-04-11T15:00:06.9188835'),
          },
        },
      ],
    },
    {
      id: 44,
      name: 'Dodo',
      video: 'dTCwssZ116A',
      path: 'dodo',
      type: ScorableType.FinancialApp,
      totalValueLocked: {
        loading: false,
        tvl: 167614848.85454503,
        fetchAt: new Date('2022-04-11T14:57:31.3724895'),
      },
      totalBitcoinLocked: {
        btcLocked: 58.16027279,
        btcLockedInUsd: 2378231.71465589,
        fetchAt: new Date('2022-04-11T15:00:06.9188835'),
      },
      image: 'dodoex.svg',
      website: 'dodoex.io',
      email: '',
      gitHub: {
        organization: 'DODOEX',
        repository: 'dodo-smart-contract',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.4476076'),
      },
      medium: {page: 'medium.com/dodoex'},
      reddit: 'DodoEx',
      discord: 'tyKReUK',
      telegram: {
        channel: 'dodoex_official',
        membersCount: 17538,
        updatedDate: new Date('2022-04-10T21:37:19.3111812'),
      },
      youtube: '',
      forum: 'community.dodoex.co',
      instagram: '',
      facebook: '',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 49,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'totalSupply',
              address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
              target: '',
              pairsTokenAddresses: [],
            },
          ],

          totalBitcoinLocked: {
            btcLocked: 58.16027279,
            btcLockedInUsd: 2378231.71465589,
            fetchAt: new Date('2022-04-11T15:00:06.9188835'),
          },
        },
      ],
    },
    {
      id: 41,
      name: 'Dfyn',
      video: 'dTCwssZ116A',
      path: 'dfyn',
      type: ScorableType.FinancialApp,
      totalValueLocked: {
        loading: false,
        tvl: 31739778.560232945,
        fetchAt: new Date('2022-04-11T14:57:30.8562654'),
      },
      totalBitcoinLocked: {
        btcLocked: 42.99672656,
        btcLockedInUsd: 1758179.14576496,
        fetchAt: new Date('2022-04-11T15:00:07.2409893'),
      },
      image: 'dfyn.svg',
      website: 'dfyn.network',
      email: 'contact@dfyn.network',
      gitHub: {
        organization: 'dfyn',
        repository: 'dfyn-exchange',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.4476002'),
      },
      medium: {page: 'dfyn-network.medium.com'},
      reddit: '',
      discord: 'yjM2fUUHvN',
      telegram: {
        channel: 'Dfyn_HQ',
        membersCount: 12256,
        updatedDate: new Date('2022-04-10T21:37:19.3111741'),
      },
      youtube: '',
      forum: '',
      instagram: '',
      facebook: '',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 23,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'balanceOf',
              address: '0x3e01dd8a5e1fb3481f0f589056b428fc308af0fb',
              target: '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
              pairsTokenAddresses: [],
            },
          ],

          totalBitcoinLocked: {
            btcLocked: 42.99672656,
            btcLockedInUsd: 1758179.14576496,
            fetchAt: new Date('2022-04-11T15:00:07.2409893'),
          },
        },
      ],
    },
    {
      id: 32,
      name: 'mStable',
      video: 'dTCwssZ116A',
      path: 'mstable',
      type: ScorableType.FinancialApp,
      totalValueLocked: {
        loading: false,
        tvl: 93733482.53550905,
        fetchAt: new Date('2022-04-11T14:57:30.4005143'),
      },
      totalBitcoinLocked: {
        btcLocked: 41.01036133559218,
        btcLockedInUsd: 1676954.6853736998,
        fetchAt: new Date('2022-04-11T15:00:08.1964614'),
      },
      image: 'mstable.svg',
      website: 'mstable.org',
      email: 'info@mstable.org',
      gitHub: {
        organization: 'mstable',
        repository: 'mStable-contracts',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.4475136'),
      },
      medium: {page: 'medium.com/mstable'},
      reddit: '',
      discord: 'pgCVG7e',
      telegram: {
        channel: 'mstableofficial',
        membersCount: 2291,
        updatedDate: new Date('2022-04-10T21:37:19.3111593'),
      },
      youtube: '',
      forum: '',
      instagram: '',
      facebook: '',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 46,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'balanceOf',
              address: '0x3e01dd8a5e1fb3481f0f589056b428fc308af0fb',
              target: '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
              pairsTokenAddresses: [],
            },
          ],

          totalBitcoinLocked: {
            btcLocked: 41.01036133559218,
            btcLockedInUsd: 1676954.6853736998,
            fetchAt: new Date('2022-04-11T15:00:08.1964614'),
          },
        },
      ],
    },
    {
      id: 33,
      name: 'UMA',
      video: 'dTCwssZ116A',
      path: 'uma',
      type: ScorableType.FinancialApp,
      totalValueLocked: {
        loading: false,
        tvl: 45709644.697246194,
        fetchAt: new Date('2022-04-11T14:57:30.4167156'),
      },
      totalBitcoinLocked: {
        btcLocked: 25.4273381,
        btcLockedInUsd: 1039749.2822471,
        fetchAt: new Date('2022-04-11T15:00:08.1964614'),
      },
      image: 'umaproject.svg',
      website: 'umaproject.org',
      email: 'hello@umaproject.org',
      gitHub: {
        organization: 'umaprotocol',
        repository: 'protocol',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.4475161'),
      },
      medium: {page: 'medium.com/uma-project'},
      reddit: '',
      discord: 'jsb9XQJ',
      youtube: '',
      forum: '',
      instagram: '',
      facebook: '',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 46,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'balanceOf',
              address: '0x3e01dd8a5e1fb3481f0f589056b428fc308af0fb',
              target: '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
              pairsTokenAddresses: [],
            },
          ],

          totalBitcoinLocked: {
            btcLocked: 25.4273381,
            btcLockedInUsd: 1039749.2822471,
            fetchAt: new Date('2022-04-11T15:00:08.1964614'),
          },
        },
      ],
    },
    {
      id: 12,
      name: 'Strudel BTC (VBTC)',
      video: 'dTCwssZ116A',
      path: 'vbtc',
      type: ScorableType.Token,
      totalValueLocked: {
        loading: false,
        tvl: 1498987569.3326643,
        fetchAt: new Date('2022-04-11T14:57:29.030501'),
      },
      totalBitcoinLocked: {
        btcLocked: 23.27238764,
        btcLockedInUsd: 951631.20298724,
        fetchAt: new Date('2022-04-11T15:00:08.1964614'),
      },
      image: 'strudel.svg',
      website: 'strudel.finance',
      email: '',
      gitHub: {
        organization: 'strudel-finance',
        repository: 'monorepo',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.4474646'),
      },
      medium: {page: 'strudel-finance.medium.com'},
      reddit: '',
      discord: 'CcEE5mC',
      telegram: {
        channel: 'StrudelFinanceOfficial',
        membersCount: 2724,
        updatedDate: new Date('2022-04-10T21:37:19.3111244'),
      },
      youtube: 'channel/UC6Znj2lAHKGjNLYrKs_SYwg',
      forum: '',
      instagram: 'strudel.finance',
      facebook: '',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 46,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'totalSupply',
              address: '0xe1406825186d63980fd6e2ec61888f7b91c4bae4',
              target: '',
              pairsTokenAddresses: [],
            },
          ],
          gas: {
            source: '',
            transferGasUsed: 0,
          },
          totalBitcoinLocked: {
            btcLocked: 23.27238764,
            btcLockedInUsd: 951631.20298724,
            fetchAt: new Date('2022-04-11T15:00:08.1964614'),
          },
        },
      ],
    },
    {
      id: 19,
      name: 'RskSwap',
      video: 'dTCwssZ116A',
      path: 'rskswap',
      type: ScorableType.FinancialApp,
      totalValueLocked: {
        loading: false,
        tvl: 869077.3814527917,
        fetchAt: new Date('2022-04-11T14:57:29.6315623'),
      },
      totalBitcoinLocked: {
        btcLocked: 21.18510622954876,
        btcLockedInUsd: 866280.1788324784,
        fetchAt: new Date('2022-04-11T15:00:06.8805803'),
      },
      image: 'rskswap.svg',
      website: 'rskswap.com',
      email: 'hello@rskswap.com',
      gitHub: {
        organization: 'Think-and-Dev',
        repository: 'rskswap-com',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.4474818'),
      },

      reddit: '',
      discord: '',
      telegram: {
        channel: 'RskSwap',
        membersCount: 181,
        updatedDate: new Date('2022-04-10T21:37:19.311137'),
      },
      youtube: '',
      forum: '',
      instagram: '',
      facebook: '',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 47,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: true,
          howWeGetTbl: [
            {
              function: 'pairs',
              address: '',
              target: '',
              pairsTokenAddresses: ['0x967f8799af07df1534d48a95a5c9febe92c53ae0'],
            },
          ],

          totalBitcoinLocked: {
            btcLocked: 21.18510622954876,
            btcLockedInUsd: 866280.1788324784,
            fetchAt: new Date('2022-04-11T15:00:06.8805803'),
          },
        },
      ],
    },
    {
      id: 31,
      name: 'Saddle',
      video: 'dTCwssZ116A',
      path: 'saddle',
      type: ScorableType.FinancialApp,
      totalValueLocked: {
        loading: false,
        tvl: 290110188.17886627,
        fetchAt: new Date('2022-04-11T14:57:30.388649'),
      },
      totalBitcoinLocked: {
        btcLocked: 19.771335158694466,
        btcLockedInUsd: 808469.6659741754,
        fetchAt: new Date('2022-04-11T15:00:08.1964614'),
      },
      image: 'saddle.svg',
      website: 'saddle.finance',
      email: 'hello@saddle.finance',
      gitHub: {
        organization: 'saddle-finance',
        repository: 'saddle-contract',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.4475112'),
      },

      reddit: '',
      discord: 'saddle',
      telegram: {
        channel: 'saddle_finance',
        membersCount: 2164,
        updatedDate: new Date('2022-04-10T21:37:19.3111569'),
      },
      youtube: '',
      forum: '',
      instagram: '',
      facebook: '',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 46,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'balanceOf',
              address: '0x3e01dd8a5e1fb3481f0f589056b428fc308af0fb',
              target: '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
              pairsTokenAddresses: [],
            },
          ],

          totalBitcoinLocked: {
            btcLocked: 19.771335158694466,
            btcLockedInUsd: 808469.6659741754,
            fetchAt: new Date('2022-04-11T15:00:08.1964614'),
          },
        },
      ],
    },
    {
      id: 14,
      name: 'PieDAO BTC++ (BTC++)',
      video: 'dTCwssZ116A',
      path: 'btcplusplus',
      type: ScorableType.Token,
      totalValueLocked: {
        loading: false,
        tvl: 6724324.996202347,
        fetchAt: new Date('2022-04-11T14:57:29.0637083'),
      },
      totalBitcoinLocked: {
        btcLocked: 12.871912441802618,
        btcLockedInUsd: 526345.3716577508,
        fetchAt: new Date('2022-04-11T15:00:08.1964614'),
      },
      image: 'btcplusplus.png',
      website: 'btc.piedao.org',
      email: '',
      gitHub: {
        organization: 'pie-dao',
        repository: 'pie-smart-pools',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.4474695'),
      },
      medium: {page: 'medium.com/piedao'},
      reddit: '',
      discord: 'eJTYNUF',
      youtube: '',
      forum: 'forum.piedao.org',
      instagram: '',
      facebook: '',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 46,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'totalSupply',
              address: '0x0327112423f3a68efdf1fcf402f6c5cb9f7c33fd',
              target: '',
              pairsTokenAddresses: [],
            },
          ],
          gas: {
            source: '',
            transferGasUsed: 0,
          },
          totalBitcoinLocked: {
            btcLocked: 12.871912441802618,
            btcLockedInUsd: 526345.3716577508,
            fetchAt: new Date('2022-04-11T15:00:08.1964614'),
          },
        },
      ],
    },
    {
      id: 40,
      name: 'Iron',
      video: 'dTCwssZ116A',
      path: 'iron',
      type: ScorableType.FinancialApp,
      totalValueLocked: {
        loading: false,
        tvl: 2072125.465680902,
        fetchAt: new Date('2022-04-11T14:57:30.8446117'),
      },
      totalBitcoinLocked: {
        btcLocked: 3.34837882,
        btcLockedInUsd: 136918.55832862,
        fetchAt: new Date('2022-04-11T15:00:07.2409893'),
      },
      image: 'iron.svg',
      website: 'iron.finance',
      email: '',
      gitHub: {
        organization: 'ironfinance',
        repository: 'iron-core-v2',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.4475978'),
      },
      medium: {page: 'ironfinance.medium.com'},
      reddit: '',
      discord: 'RtA37hgGrK',
      telegram: {
        channel: 'IronFinanceAnn',
        membersCount: 4412,
        updatedDate: new Date('2022-04-10T21:37:19.3111717'),
      },
      youtube: '',
      forum: '',
      instagram: '',
      facebook: '',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 23,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'balanceOf',
              address: '0x3e01dd8a5e1fb3481f0f589056b428fc308af0fb',
              target: '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
              pairsTokenAddresses: [],
            },
          ],

          totalBitcoinLocked: {
            btcLocked: 3.34837882,
            btcLockedInUsd: 136918.55832862,
            fetchAt: new Date('2022-04-11T15:00:07.2409893'),
          },
        },
      ],
    },
    {
      id: 15,
      name: 'ACoconut BTC (acBTC)',
      video: 'dTCwssZ116A',
      path: 'acbtc',
      type: ScorableType.Token,
      totalValueLocked: {
        loading: false,
        tvl: 243249.72238047206,
        fetchAt: new Date('2022-04-11T14:57:29.0772897'),
      },
      totalBitcoinLocked: {
        btcLocked: 1.3219140019063798,
        btcLockedInUsd: 54054.38545195377,
        fetchAt: new Date('2022-04-11T15:00:08.1964614'),
      },
      image: 'acbtc.svg',
      website: 'acbtc.fi',
      email: 'hello@nuts.finance',
      gitHub: {
        organization: 'nutsfinance',
        repository: 'acBTC',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.447472'),
      },
      medium: {page: 'medium.com/nuts-foundation'},
      reddit: '',
      discord: 'mvANFYe',
      youtube: '',
      forum: '',
      instagram: '',
      facebook: '',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 46,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'totalSupply',
              address: '0xef6e45af9a422c5469928f927ca04ed332322e2e',
              target: '',
              pairsTokenAddresses: [],
            },
          ],

          totalBitcoinLocked: {
            btcLocked: 1.3219140019063798,
            btcLockedInUsd: 54054.38545195377,
            fetchAt: new Date('2022-04-11T15:00:08.1964614'),
          },
        },
      ],
    },
    {
      id: 11,
      name: 'ANY Bitcoin (anyBTC)',
      video: 'dTCwssZ116A',
      path: 'anybtc',
      type: ScorableType.Token,
      totalValueLocked: {
        loading: false,
        tvl: 0,
        fetchAt: new Date('2022-04-11T14:57:28.7006838'),
      },
      totalBitcoinLocked: {
        btcLocked: 0.44634749,
        btcLockedInUsd: 18251.59521359,
        fetchAt: new Date('2022-04-11T15:00:08.1964614'),
      },
      image: 'anybtc.svg',
      website: 'anyswap.exchange',
      email: '',
      gitHub: {
        organization: 'anyswap',
        repository: 'CrossChain-Bridge',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.4474622'),
      },
      medium: {page: 'medium.com/@anyswap'},
      reddit: '',
      discord: '',
      telegram: {
        channel: 'anyswap',
        membersCount: 22613,
        updatedDate: new Date('2022-04-10T21:37:19.3111219'),
      },
      youtube: '',
      forum: '',
      instagram: '',
      facebook: '',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 46,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'totalSupply',
              address: '0x51600b0cff6bbf79e7767158c41fd15e968ec404',
              target: '',
              pairsTokenAddresses: [],
            },
          ],
          gas: {
            source: '',
            transferGasUsed: 0,
          },
          totalBitcoinLocked: {
            btcLocked: 0.44634749,
            btcLockedInUsd: 18251.59521359,
            fetchAt: new Date('2022-04-11T15:00:08.1964614'),
          },
        },
      ],
    },
    {
      id: 38,
      name: 'Qubit',
      video: 'dTCwssZ116A',
      path: 'qubit',
      type: ScorableType.FinancialApp,
      totalValueLocked: {
        loading: false,
        tvl: 1259492.6083083008,
        fetchAt: new Date('2022-04-11T14:57:30.5071967'),
      },
      totalBitcoinLocked: {
        btcLocked: 0,
        btcLockedInUsd: 0,
        fetchAt: new Date('2022-04-11T15:00:07.353794'),
      },
      image: 'qbt.svg',
      website: 'qbt.fi',
      email: 'hello@pancakebunny.finance',
      gitHub: {
        organization: 'PancakeBunny-finance',
        repository: 'qubit-finance',

        type: 1,
        updatedDate: new Date('2022-04-10T21:51:02.4475927'),
      },
      medium: {page: 'medium.com/@QubitFin'},
      reddit: '',
      discord: 'JGJBWRxX2Y',
      telegram: {
        channel: 'QubitFinOfficial',
        membersCount: 6072,
        updatedDate: new Date('2022-04-10T21:37:19.3111692'),
      },
      youtube: '',
      forum: '',
      instagram: '',
      facebook: '',
      about: 'This is the about text',
      contracts: [
        {
          blockchainId: 48,
          projectId: ScorableId.WBtc,
          hasUniSwapV2: false,
          howWeGetTbl: [
            {
              function: 'balanceOf',
              address: '0x3e01dd8a5e1fb3481f0f589056b428fc308af0fb',
              target: '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
              pairsTokenAddresses: [],
            },
          ],

          totalBitcoinLocked: {
            btcLocked: 0,
            btcLockedInUsd: 0,
            fetchAt: new Date('2022-04-11T15:00:07.353794'),
          },
        },
      ],
    },
    {
      id: 46,
      type: ScorableType.Blockchain,
      name: 'Ethereum',
      about: 'This is the about text',
      video: '',
      image: 'ethereum.svg',
      path: 'ethereum',
      scoring: {
        testing: true,
        score: {value: 825, max: 1000, percentage: 82.5},
        bitcoinAffinityIndex: {value: 825, max: 1000, percentage: 82.5},
        securityIndex: {value: 825, max: 1000, percentage: 82.5},
        decentralizationIndex: {value: 825, max: 1000, percentage: 82.5},
        privacyIndex: {value: 825, max: 1000, percentage: 82.5},
        scalabilityIndex: {value: 825, max: 1000, percentage: 82.5},
        fairnessIndex: {value: 825, max: 1000, percentage: 82.5},
        economicIndex: {value: 825, max: 1000, percentage: 82.5},
        communityTractionIndex: {value: 825, max: 1000, percentage: 82.5},
        oracleIndex: {value: 825, max: 1000, percentage: 82.5},
      },
      totalValueLocked: {
        loading: false,
        tvl: 1259492.6083083008,
        fetchAt: new Date('2022-04-11T14:57:30.5071967'),
      },
      totalBitcoinLocked: {
        btcLocked: 0,
        btcLockedInUsd: 0,
        fetchAt: new Date('2022-04-11T15:00:07.353794'),
      },
      contracts: [],
    },
    {
      id: 47,
      type: ScorableType.Blockchain,
      name: 'Rsk',
      about: 'This is the about text',
      video: '',
      image: 'rsk.svg',
      path: 'rsk',
      scoring: {
        testing: true,
        score: {value: 825, max: 1000, percentage: 82.5},
        bitcoinAffinityIndex: {value: 825, max: 1000, percentage: 82.5},
        securityIndex: {value: 825, max: 1000, percentage: 82.5},
        decentralizationIndex: {value: 825, max: 1000, percentage: 82.5},
        privacyIndex: {value: 825, max: 1000, percentage: 82.5},
        scalabilityIndex: {value: 825, max: 1000, percentage: 82.5},
        fairnessIndex: {value: 825, max: 1000, percentage: 82.5},
        economicIndex: {value: 825, max: 1000, percentage: 82.5},
        communityTractionIndex: {value: 825, max: 1000, percentage: 82.5},
        oracleIndex: {value: 825, max: 1000, percentage: 82.5},
      },
      totalValueLocked: {
        loading: false,
        tvl: 1259492.6083083008,
        fetchAt: new Date('2022-04-11T14:57:30.5071967'),
      },
      totalBitcoinLocked: {
        btcLocked: 0,
        btcLockedInUsd: 0,
        fetchAt: new Date('2022-04-11T15:00:07.353794'),
      },
      contracts: [],
    },
    {
      id: 48,
      type: ScorableType.Blockchain,
      name: 'Binance',
      about: 'This is the about text',
      video: '',
      image: 'binance.svg',
      path: 'binance',
      scoring: {
        testing: true,
        score: {value: 825, max: 1000, percentage: 82.5},
        bitcoinAffinityIndex: {value: 825, max: 1000, percentage: 82.5},
        securityIndex: {value: 825, max: 1000, percentage: 82.5},
        decentralizationIndex: {value: 825, max: 1000, percentage: 82.5},
        privacyIndex: {value: 825, max: 1000, percentage: 82.5},
        scalabilityIndex: {value: 825, max: 1000, percentage: 82.5},
        fairnessIndex: {value: 825, max: 1000, percentage: 82.5},
        economicIndex: {value: 825, max: 1000, percentage: 82.5},
        communityTractionIndex: {value: 825, max: 1000, percentage: 82.5},
        oracleIndex: {value: 825, max: 1000, percentage: 82.5},
      },
      totalValueLocked: {
        loading: false,
        tvl: 1259492.6083083008,
        fetchAt: new Date('2022-04-11T14:57:30.5071967'),
      },
      totalBitcoinLocked: {
        btcLocked: 0,
        btcLockedInUsd: 0,
        fetchAt: new Date('2022-04-11T15:00:07.353794'),
      },
      contracts: [],
    },
    {
      id: 49,
      type: ScorableType.Blockchain,
      name: 'Arbitrum',
      about: 'This is the about text',
      video: '',
      image: 'arbitrum.svg',
      path: 'arbitrum',
      scoring: {
        testing: true,
        score: {value: 825, max: 1000, percentage: 82.5},
        bitcoinAffinityIndex: {value: 825, max: 1000, percentage: 82.5},
        securityIndex: {value: 825, max: 1000, percentage: 82.5},
        decentralizationIndex: {value: 825, max: 1000, percentage: 82.5},
        privacyIndex: {value: 825, max: 1000, percentage: 82.5},
        scalabilityIndex: {value: 825, max: 1000, percentage: 82.5},
        fairnessIndex: {value: 825, max: 1000, percentage: 82.5},
        economicIndex: {value: 825, max: 1000, percentage: 82.5},
        communityTractionIndex: {value: 825, max: 1000, percentage: 82.5},
        oracleIndex: {value: 825, max: 1000, percentage: 82.5},
      },
      totalValueLocked: {
        loading: false,
        tvl: 1259492.6083083008,
        fetchAt: new Date('2022-04-11T14:57:30.5071967'),
      },
      totalBitcoinLocked: {
        btcLocked: 0,
        btcLockedInUsd: 0,
        fetchAt: new Date('2022-04-11T15:00:07.353794'),
      },
      contracts: [],
    },
    {
      id: 50,
      type: ScorableType.FinancialApp,
      name: 'Tropykus',
      about: 'This is the about text',
      video: '',
      image: 'tropykus.svg',
      path: 'tropykus',
      website: 'tropykus.com',
      email: 'contacto@tropykus.com',
      totalValueLocked: {
        loading: false,
        tvl: 1259492.6083083008,
        fetchAt: new Date('2022-04-11T14:57:30.5071967'),
      },
      totalBitcoinLocked: {
        btcLocked: 0,
        btcLockedInUsd: 0,
        fetchAt: new Date('2022-04-11T15:00:07.353794'),
      },
      contracts: [],
      gitHub: {
        type: 1,
        organization: 'Tropykus',
        repository: 'protocol',

        updatedDate: new Date('2022-04-10T21:51:02.4475927'),
      },
      telegram: {
        channel: 'tropykus',
        membersCount: 100,
        updatedDate: new Date('26/04/2020'),
      },
      youtube: 'channel/UCUgOzJjQAsQS2sFJ0-A87eQ',
      instagram: 'tropykus',
    },
  ]
}
