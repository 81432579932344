import {FC} from 'react'
import {MediumModel} from '../../models/MediumModel'

const Medium: FC<{medium: MediumModel}> = ({medium}) => {
  const handleShowMoreClick = () => {
    const newWindow = window.open(`https://${medium.page}`, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return (
    <div className='col-lg-6'>
      <div className='card mb-5 mb-xl-8'>
        <div className='card-body'>
          <h1>
            <img src='../../media/svg/brand-logos/medium-logo.svg' className='h-20px me-1' alt='' />{' '}
            Medium News
          </h1>
          <p className='text-gray-800 fw-normal mb-0'>All Medium News</p>
        </div>
      </div>

      {/* {medium.articles.slice(0, mediumArticles).map((article, index) => (
        <div className='card mb-5 mb-xl-8' key={index}>
          <div className='card-body pb-0'>
            <div className='d-flex align-items-center mb-5'>
              <div className='d-flex align-items-center flex-grow-1'>
                <div className='d-flex flex-column'>
                  <p className='text-gray-900 text-hover-primary fs-6 fw-bolder mb-1'>
                    {article.creator}
                  </p>
                  <span className='text-gray-400 fw-bold'>{`at ${toMMMddyyyy(
                    article.publishDateTime
                  )}`}</span>
                </div>
              </div>
            </div>
            <div className='mb-5'>
              <a
                target='_blank'
                rel='noreferrer'
                href={article.url}
                className='fw-bolder text-dark fs-4 lh-base text-hover-primary mb-5'
              >
                {article.title}
              </a>
            </div>
            {article.categories.length > 0 && (
              <div className='d-flex align-items-center mb-5'>
                {article.categories.map((category, index) => (
                  <div
                    key={index}
                    className='btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4'
                  >
                    {category}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      ))} */}
      <button
        className='btn btn-primary w-100 text-center'
        id='kt_widget_5_load_more_btn'
        onClick={handleShowMoreClick}
      >
        <span className='indicator-label'>Open Medium page</span>
        <span className='indicator-progress'>
          Loading...
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </span>
      </button>
    </div>
  )
}

export default Medium
