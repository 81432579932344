/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { ContactPage } from '../../modules/contact/components/ContactPage'

const ContactWrapper: FC = () => {
  return (
    <>
      {/* begin::Post */}
      <ContactPage />
      {/* end::Post */}
    </>
  )
}

export { ContactWrapper }
