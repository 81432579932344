import React, {FC, PropsWithChildren} from 'react'
import {ScorableId} from '../../../enums/ScorableId'
import {ScorableType} from '../../../enums/ScorableType'
import {ScorableModel} from '../../project/models/ScorableModel'

type IScoringFormatter = PropsWithChildren<{
  scorables: Array<ScorableModel>
  blockchains: Array<number>
  type: ScorableType
}>

export type NumberFormatted = {number?: number; suffix?: string; loading: boolean}

export const toPercentageString = (number?: number): NumberFormatted =>
  number !== undefined && number !== null
    ? {
        number: Math.round(number * 100) / 100,
        suffix: '%',
        loading: false,
      }
    : {
        loading: true,
      }

const round = (num: number): number => Math.round(num * 100) / 100

export const toPrettyString = (decimal?: number): NumberFormatted =>
  decimal !== undefined && decimal !== null
    ? decimal >= 1000000000
      ? {number: round(decimal / 1000000000), suffix: 'B', loading: false}
      : decimal >= 1000000
      ? {number: round(decimal / 1000000), suffix: 'M', loading: false}
      : decimal >= 10000
      ? {number: round(decimal / 1000), suffix: 'K', loading: false}
      : {number: round(decimal), suffix: '', loading: false}
    : {loading: true}

export const calculateFinancialValues = (
  scorable: ScorableModel,
  isFilteredByBlockchain: boolean
): ScorableModel => {
  if (scorable.type === ScorableType.Blockchain || !isFilteredByBlockchain) {
    return scorable
  }

  const totalBitcoinLocked = {
    ...scorable.totalBitcoinLocked,
    ...scorable.contracts.reduce(
      (previousValue, currentValue) => ({
        btcLocked: previousValue.btcLocked + (currentValue.totalBitcoinLocked.btcLocked || 0),
        btcLockedInUsd:
          previousValue.btcLockedInUsd + (currentValue.totalBitcoinLocked.btcLockedInUsd || 0),
        btcLockedFromYesterday:
          previousValue.btcLockedFromYesterday +
          (currentValue.totalBitcoinLocked.btcLockedFromYesterday || 0),
      }),
      {
        btcLocked: 0,
        btcLockedInUsd: 0,
        btcLockedFromYesterday: 0,
      }
    ),
  }

  totalBitcoinLocked.btcLockedEvolutionFromYesterday =
    totalBitcoinLocked.btcLockedFromYesterday > 0
      ? ((totalBitcoinLocked.btcLocked - totalBitcoinLocked.btcLockedFromYesterday) * 100) /
        totalBitcoinLocked.btcLockedFromYesterday
      : 0

  return {
    ...scorable,
    totalBitcoinLocked,
  }
}

const filterAndCalculate = (
  scorables: Array<ScorableModel>,
  type: ScorableType,
  blockchains: Array<ScorableId>
): Array<any> => {
  const isFilteredByBlockchain =
    scorables.filter((scorable) => scorable.type === ScorableType.Blockchain).length !==
    blockchains.length
  return calculate(filter(scorables, type, blockchains), isFilteredByBlockchain)
}

const filter = (
  scorables: Array<ScorableModel>,
  type: ScorableType,
  blockchains: Array<ScorableId>
): Array<any> => {
  const typeScorables = scorables.filter((scorable) => scorable.type === type)

  if (type === ScorableType.Blockchain) return typeScorables

  return typeScorables
    .filter((scorable) =>
      scorable.contracts.some((contract) => blockchains.includes(contract.blockchainId))
    )
    .map((scorable) => ({
      ...scorable,
      contracts: scorable.contracts.filter((contract) =>
        blockchains.includes(contract.blockchainId)
      ),
    }))
}

const calculate = (scorables: Array<ScorableModel>, isFilteredByBlockchain: boolean): Array<any> =>
  scorables.map((scorable) => calculateFinancialValues(scorable, isFilteredByBlockchain))

const ScoringFormatter: FC<IScoringFormatter> = (props) => {
  const scorables = filterAndCalculate(props.scorables, props.type, props.blockchains)

  const childrenWithProps = React.Children.map(props.children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {data: scorables})
    }
    return child
  })

  return (
    <div className='dataTables_wrapper dt-bootstrap4 no-footer scoring-table'>
      {childrenWithProps}
    </div>
  )
}

export {ScoringFormatter}
