export const actionTypes = {
  Connected: 'WS_CONNECTED',
  Disconnected: 'WS_DISCONNECTED',
  Reconnecting: 'WS_RECONNECTING',
}

export type WebSocketModel = {
  connected: boolean
  reconnecting: boolean
  datetime: Date
}

const initialState: WebSocketModel = {
  connected: false,
  reconnecting: false,
  datetime: new Date(),
}

export const reducer = (state: WebSocketModel = initialState, action: {type: string}) => {
  switch (action.type) {
    case actionTypes.Connected: {
      return {
        connected: true,
        reconnecting: false,
        datetime: new Date(),
      }
    }

    case actionTypes.Disconnected: {
      return {
        connected: false,
        reconnecting: false,
        datetime: new Date(),
      }
    }

    case actionTypes.Reconnecting: {
      return {
        connected: false,
        reconnecting: true,
        datetime: new Date(),
      }
    }

    default:
      return state
  }
}

export const actions = {
  connect: () => ({
    type: actionTypes.Connected,
  }),
  disconnect: () => ({
    type: actionTypes.Disconnected,
  }),
  reconnect: () => ({
    type: actionTypes.Reconnecting,
  }),
}
