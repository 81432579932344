import { ScorableId } from "./ScorableId";

export enum BlockchainId {
  Ethereum = 1,
  RSK = 30,
  Binance = 56,
  Polygon = 137,
  Arbitrum = 42161
}

export const BlockchainsNames = Object.freeze({
  [ScorableId.Ethereum]: "Ethereum",
  [ScorableId.Rsk]: "RSK",
  [ScorableId.Binance]: "Binance",
  [ScorableId.Polygon]: "Polygon",
  [ScorableId.Arbitrum]: "Arbitrum"
});

export const Blockchains = Object.freeze([
  { id: ScorableId.Ethereum, name: BlockchainsNames[ScorableId.Ethereum], image: "ethereum.svg" },
  { id: ScorableId.Rsk, name: BlockchainsNames[ScorableId.Rsk], image: "rsk.svg" },
  { id: ScorableId.Binance, name: BlockchainsNames[ScorableId.Binance], image: "binance.svg" },
  { id: ScorableId.Polygon, name: BlockchainsNames[ScorableId.Polygon], image: "polygon.svg" },
  { id: ScorableId.Arbitrum, name: BlockchainsNames[ScorableId.Arbitrum], image: "arbitrum.svg" },
]);

export const BlockchainsSelectOptions = Object.freeze([
  { value: ScorableId.Ethereum, label: BlockchainsNames[ScorableId.Ethereum] },
  { value: ScorableId.Rsk, label: BlockchainsNames[ScorableId.Rsk] },
  { value: ScorableId.Binance, label: BlockchainsNames[ScorableId.Binance] },
  { value: ScorableId.Polygon, label: BlockchainsNames[ScorableId.Polygon] },
  { value: ScorableId.Arbitrum, label: BlockchainsNames[ScorableId.Arbitrum] },
]);

export const blockchainIds = BlockchainsSelectOptions.map(blockchain => blockchain.value)