import {Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {ScoringWrapper} from '../pages/scoring/ScoringWrapper'
import {ProjectWrapper} from '../pages/project/ProjectWrapper'
import {FaqsWrapper} from '../pages/faqs/FaqsWrapper'
import {CommunityWrapper} from '../pages/community/CommunityWrapper'
import {HomeWrapper} from '../pages/home/HomeWrapper'
import {ContactWrapper} from '../pages/contact/ContactWrapper'
import {RoadmapWrapper} from '../pages/roadmap/RoadmapWrapper'
import {WhatisWrapper} from '../pages/whatis/WhatisWrapper'
import {ArticleWrapper} from '../pages/article/ArticleWrapper'
import {AuthorWrapper} from '../pages/author/AuthorWrapper'

export function PublicRoutes() {
  return (
    <Suspense fallback={<FallbackView />}>
      <Routes>
        <Route index element={<Navigate to='/home' />} />
        <Route path='/scoring' element={<ScoringWrapper />} />
        <Route path='/projects/:path' element={<ProjectWrapper />} />
        <Route path='/community' element={<CommunityWrapper />} />
        <Route path='/community/article/:title' element={<ArticleWrapper />} />
        <Route path='/community/author/:name' element={<AuthorWrapper />} />
        <Route path='/faqs' element={<FaqsWrapper />} />
        <Route path='/home' element={<HomeWrapper />} />
        <Route path='/contactus' element={<ContactWrapper />} />
        <Route path='/roadmap' element={<RoadmapWrapper />} />
        <Route path='/whatisd4b' element={<WhatisWrapper />} />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Routes>
    </Suspense>
  )
}
