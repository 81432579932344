import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'

const Topbar: FC = () => {
  const intl = useIntl()
  return (
    <div className='flex-equal text-end ms-1'>
      <Link to='/contactus' className='btn btn-secondary'>{intl.formatMessage({ id: 'MENU.CONTACTUS' })}</Link>
    </div>
  )
}

export { Topbar }
