/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {CircleIcon} from '../../../../_metronic/helpers/components/CircleIcon'

const HomePage: FC = () => {
  const home = useSelector((state: RootState) => state.home)

  if (home.loading) return <div>Loading...</div>

  return (
    <div className='row mt-20'>
      <div className='col-lg-5'>
        <h4 className='fs-3x home-page__title mb-0'>You love them…</h4>
        <h4 className='fs-5x home-page__title mb-5'>Invest wisely.</h4>
        <p className='fs-2 home-page__tagline'>The DeFi for Bitcoin ecosystem.</p>
      </div>
      <div className='col-lg-7 d4b_animation'>
        {/* BG */}
        <img src='/media/home_banner/bg2.svg' alt='' />
        <img className='three-circles' src='/media/home_banner/bg1.svg' alt='' />
        <img className='dashed-line' src='/media/home_banner/line.svg' alt='' />
        {/* BARS */}
        <div className='bars'>
          <div className='bar'></div>
          <div className='bar'></div>
          <div className='bar'></div>
          <div className='bar'></div>
          <div className='bar'></div>
          <div className='bar'></div>
          <div className='bar'></div>
          <div className='bar'></div>
          <div className='bar'></div>
          <div className='bar'></div>
          <div className='bar'></div>
          <div className='bar'></div>
        </div>
        {/* ICONS */}
        <div className='icons'>
          <div className='icon icon__main'>
            <div className='icon__logo'>
              <CircleIcon className='w-100' path='/media/img/bitcoin.svg' alt='Bitcoin' />
            </div>
          </div>

          {/* SECONDARY */}
          <div className='icon icon__secondary icon__secondary__first'>
            <div className='icon__logo'>
              <CircleIcon
                className='w-100'
                path={`/media/img/${home.scorables[0].image}`}
                alt={home.scorables[0].name}
              />
            </div>
          </div>
          <div className='icon icon__secondary icon__secondary__second'>
            <div className='icon__logo'>
              <CircleIcon
                className='w-100'
                path={`/media/img/${home.scorables[1].image}`}
                alt={home.scorables[1].name}
              />
            </div>
          </div>
          <div className='icon icon__secondary icon__secondary__third'>
            <div className='icon__logo'>
              <CircleIcon
                className='w-100'
                path={`/media/img/${home.scorables[2].image}`}
                alt={home.scorables[2].name}
              />
            </div>
          </div>
          <div className='icon icon__secondary icon__secondary__fourth'>
            <div className='icon__logo'>
              <CircleIcon
                className='w-100'
                path={`/media/img/${home.scorables[3].image}`}
                alt={home.scorables[3].name}
              />
            </div>
          </div>
          <div className='icon icon__secondary icon__secondary__fifth'>
            <div className='icon__logo'>
              <CircleIcon
                className='w-100'
                path={`/media/img/${home.scorables[4].image}`}
                alt={home.scorables[4].name}
              />
            </div>
          </div>

          {/* TERTIARY */}
          <div className='icon icon__tertiary icon__tertiary__first'>
            <div className='icon__logo'>
              <CircleIcon
                className='w-100'
                path={`/media/img/${home.scorables[5].image}`}
                alt={home.scorables[5].name}
              />
            </div>
          </div>
          <div className='icon icon__tertiary icon__tertiary__second'>
            <div className='icon__logo'>
              <CircleIcon
                className='w-100'
                path={`/media/img/${home.scorables[6].image}`}
                alt={home.scorables[6].name}
              />
            </div>
          </div>
          <div className='icon icon__tertiary icon__tertiary__third'>
            <div className='icon__logo'>
              <CircleIcon
                className='w-100'
                path={`/media/img/${home.scorables[7].image}`}
                alt={home.scorables[7].name}
              />
            </div>
          </div>
          <div className='icon icon__tertiary icon__tertiary__fourth'>
            <div className='icon__logo'>
              <CircleIcon
                className='w-100'
                path={`/media/img/${home.scorables[8].image}`}
                alt={home.scorables[8].name}
              />
            </div>
          </div>
          <div className='icon icon__tertiary icon__tertiary__fifth'>
            <div className='icon__logo'>
              <CircleIcon
                className='w-100'
                path={`/media/img/${home.scorables[9].image}`}
                alt={home.scorables[9].name}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {HomePage}
