import {FC, useState} from 'react'
import {TwitterModel} from '../../models/TwitterModel'
import {Timeline} from 'react-twitter-widgets'

const Twitter: FC<{twitter: TwitterModel}> = ({twitter}) => {
  const [loading, setLoading] = useState(true)
  const handleShowMoreClick = () => {
    const newWindow = window.open(
      `https://twitter.com/${twitter.username}`,
      '_blank',
      'noopener,noreferrer'
    )
    if (newWindow) newWindow.opener = null
  }

  return (
    <div className='col-lg-6'>
      <div className='card mb-5 mb-xl-8'>
        <div className='card-body'>
          <h1>
            <img src='../../media/svg/brand-logos/twitter.svg' className='h-20px me-1' alt='' />{' '}
            Twitter News
          </h1>
          <p className='text-gray-800 fw-normal mb-0'>All Twitter News</p>
        </div>
      </div>

      <div className='card mb-5 mb-xl-8 card-custom'>
        <div className={`card-body card-scroll ${loading ? 'h-70px' : 'h-400px'}`}>
          {loading && (
            <p className='text-gray-800 fw-normal mb-4'>
              Loading...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </p>
          )}
          <Timeline
            dataSource={{sourceType: 'profile', screenName: twitter.username}}
            options={{
              chrome: 'noheader, nofooter, noborders',
            }}
            onLoad={() => {
              setLoading(false)
            }}
          />
        </div>
      </div>
      <button
        className='btn btn-primary w-100 text-center'
        id='kt_widget_5_load_more_btn'
        onClick={handleShowMoreClick}
      >
        <span className='indicator-label'>Open Twitter page</span>
        <span className='indicator-progress'>
          Loading...
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </span>
      </button>
    </div>
  )
}

export default Twitter
