/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { WhatisPage } from '../../modules/whatis/components/WhatisPage'

const WhatisWrapper: FC = () => {
  return (
    <>
      {/* begin::Post */}
      <WhatisPage />
      {/* end::Post */}
    </>
  )
}

export { WhatisWrapper }
