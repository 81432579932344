import React, {FC} from 'react'
import {ScoringModel} from '../../models/ScoringModel'
import ScoreBar from './ScoreBar'

type Props = {
  scoring: ScoringModel
}

const ScoringBars: FC<Props> = ({scoring}) => {
  return (
    <>
      <ScoreBar
        name='Bitcoin Affinity'
        colorClassName='bg-baiColor'
        score={scoring.bitcoinAffinityIndex}
      />
      <ScoreBar name='Security' colorClassName='bg-siColor' score={scoring.securityIndex} />
      <ScoreBar
        name='Decentralization'
        colorClassName='bg-diColor'
        score={scoring.decentralizationIndex}
      />
      <ScoreBar name='Privacy' colorClassName='bg-piColor' score={scoring.privacyIndex} />
      <ScoreBar name='Scalability' colorClassName='bg-siColor' score={scoring.scalabilityIndex} />
      <ScoreBar name='Fairness' colorClassName='bg-fiColor' score={scoring.fairnessIndex} />
      <ScoreBar name='Economic' colorClassName='bg-eiColor' score={scoring.economicIndex} />
      <ScoreBar
        name='Community Traction'
        colorClassName='bg-ctiColor'
        score={scoring.communityTractionIndex}
      />
      {/*<ScoreBar name='Oracle' colorClassName='bg-oiColor' score={scoring.oracleIndex} />*/}
    </>
  )
}

export default ScoringBars
