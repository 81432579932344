import clsx from 'clsx'
import {useField} from 'formik'
import React, {FC} from 'react'

interface Props {
  name: string
  placeholder: string
  disabled?: boolean
}

const Textarea: FC<Props> = ({name, placeholder, disabled = false}) => {
  const [field, meta] = useField(name)

  return (
    <>
      <div className='input-group input-group-solid message-input'>
        <span className='input-group-text'>{`${placeholder}:`}</span>
        <textarea
          className={clsx('form-control', meta.touched && meta.error && 'is-invalid')}
          aria-label={placeholder}
          disabled={disabled}
          {...field}
        ></textarea>
        {meta.error && meta.touched && (
          <div id='validationServerUsernameFeedback' className='invalid-feedback text-end'>
            {meta.error}
          </div>
        )}
      </div>
    </>
  )
}

export default Textarea
