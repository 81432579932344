import {FC} from 'react'
import { ScorableId } from '../../../../enums/ScorableId'
import DeveloperActivityBar from '../../github/components/DeveloperActivityBar'

const Overview: FC<{scorableId: ScorableId}> = ({scorableId}) => (
  <div className='card mb-5 mb-xxl-8'>
    <div className='card-header border-0 pt-5'>
      <h3 className='card-title align-items-start flex-column'>
        <span className='card-label fw-bolder fs-3 mb-1'>Development Activity</span>
        <span className='text-muted fw-bold fs-7'>Number of events in the last 3 months</span>
      </h3>
    </div>
    <div className='card-body'>
      <div id='kt_charts_widget_1_chart' style={{height: '350px'}}>
        <DeveloperActivityBar scorableId={scorableId} />
      </div>
    </div>
  </div>
)

export default Overview
