/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { FaqsPage } from '../../modules/faqs/components/FaqsPage'

const FaqsWrapper: FC = () => {
  return (
    <>
      {/* begin::Post */}
      <FaqsPage />
      {/* end::Post */}
    </>
  )
}

export { FaqsWrapper }
