import {FC} from 'react'
import {ScorableModel} from '../models/ScorableModel'

type Props = {
  scorable: ScorableModel
}

const WebsiteIcon: FC<Props> = ({scorable}) => {
  if (!scorable.website) return null

  return (
    <a
      target='_blank'
      rel='noreferrer'
      href={`https://${scorable.website}`}
      className='btn btn-sm btn-bg-light me-3 mb-4'
    >
      <i className='fa fa-earth-americas fa-lg'></i>
    </a>
  )
}

const EmailIcon: FC<Props> = ({scorable}) => {
  if (!scorable.email) return null

  return (
    <a
      target='_blank'
      rel='noreferrer'
      href={`mailto:${scorable.email}`}
      className='btn btn-sm btn-bg-light me-3 mb-4'
    >
      <i className='fa fa-envelope fa-lg'></i>
    </a>
  )
}

const MediumIcon: FC<Props> = ({scorable}) => {
  if (!scorable.medium) return null

  return (
    <a
      target='_blank'
      rel='noreferrer'
      href={`https://${scorable.medium.page}`}
      className='btn btn-sm btn-bg-light me-3 mb-4'
    >
      <i className='fab fa-medium-m fa-lg'></i>
    </a>
  )
}

const RedditIcon: FC<Props> = ({scorable}) => {
  if (!scorable.reddit) return null

  return (
    <a
      target='_blank'
      rel='noreferrer'
      href={`https://reddit.com/r/${scorable.reddit}`}
      className='btn btn-sm btn-bg-light me-3 mb-4'
    >
      <i className='fab fa-reddit fa-lg'></i>
    </a>
  )
}

const DiscordIcon: FC<Props> = ({scorable}) => {
  if (!scorable.discord) return null

  return (
    <a
      target='_blank'
      rel='noreferrer'
      href={`https://discord.com/invite/${scorable.discord}`}
      className='btn btn-sm btn-bg-light me-3 mb-4'
    >
      <i className='fab fa-discord fa-lg'></i>
    </a>
  )
}

const TelegramIcon: FC<Props> = ({scorable}) => {
  if (!scorable.telegram) return null

  return (
    <a
      target='_blank'
      rel='noreferrer'
      href={`https://t.me/${scorable.telegram.channel}`}
      className='btn btn-sm btn-bg-light me-3 mb-4'
    >
      <i className='fab fa-telegram-plane fa-lg'></i>
    </a>
  )
}

const YoutubeIcon: FC<Props> = ({scorable}) => {
  if (!scorable.youtube) return null

  return (
    <a
      target='_blank'
      rel='noreferrer'
      href={`https://youtube.com/${scorable.youtube}`}
      className='btn btn-sm btn-bg-light me-3 mb-4'
    >
      <i className='fab fa-youtube fa-lg'></i>
    </a>
  )
}

const ForumIcon: FC<Props> = ({scorable}) => {
  if (!scorable.forum) return null

  return (
    <a
      target='_blank'
      rel='noreferrer'
      href={`https://${scorable.forum}`}
      className='btn btn-sm btn-bg-light me-3 mb-4'
    >
      <i className='fa fa-comment-dots fa-lg'></i>
    </a>
  )
}

const InstagramIcon: FC<Props> = ({scorable}) => {
  if (!scorable.instagram) return null

  return (
    <a
      target='_blank'
      rel='noreferrer'
      href={`https://instagram.com/${scorable.instagram}`}
      className='btn btn-sm btn-bg-light me-3 mb-4'
    >
      <i className='fab fa-instagram fa-lg'></i>
    </a>
  )
}

const FacebookIcon: FC<Props> = ({scorable}) => {
  if (!scorable.facebook) return null

  return (
    <a
      target='_blank'
      rel='noreferrer'
      href={`https://facebook.com/${scorable.facebook}`}
      className='btn btn-sm btn-bg-light me-3 mb-4'
    >
      <i className='fab fa-facebook fa-lg'></i>
    </a>
  )
}

const TwitterIcon: FC<Props> = ({scorable}) => {
  if (!scorable.twitter) return null

  return (
    <a
      target='_blank'
      rel='noreferrer'
      href={`https://twitter.com/${scorable.twitter.username}`}
      className='btn btn-sm btn-bg-light me-3 mb-4'
    >
      <i className='fab fa-twitter fa-lg'></i>
    </a>
  )
}

const GitHubIcon: FC<Props> = ({scorable}) => {
  if (!scorable.gitHub) return null

  return (
    <a
      target='_blank'
      rel='noreferrer'
      href={`https://github.com/${scorable.gitHub.organization}`}
      className='btn btn-sm btn-bg-light me-3 mb-4'
    >
      <i className='fab fa-github fa-lg'></i>
    </a>
  )
}

export {
  WebsiteIcon,
  EmailIcon,
  MediumIcon,
  RedditIcon,
  DiscordIcon,
  TelegramIcon,
  YoutubeIcon,
  ForumIcon,
  InstagramIcon,
  FacebookIcon,
  TwitterIcon,
  GitHubIcon,
}
