/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {AuthorModel} from '../models/AuthorModel'
import {getAuthor} from '../redux/CommunityApi'
import {toMMMddyyyy} from '../../../utils/date'

type Props = {
  name: string
}

const AuthorPage: FC<Props> = ({name}) => {
  const [author, setAuthor] = useState<AuthorModel>()
  useEffect(() => {
    const fetchAuthor = async () => {
      const article = await getAuthor(name)
      setAuthor(article.data)
    }
    fetchAuthor()
  }, [name])

  if (!author) return null

  return (
    <div className='card'>
      <div className='card-body p-lg-20 pb-lg-0'>
        <div className='d-flex flex-column flex-xl-row'>
          <div className='flex-lg-row-fluid me-xl-15'>
            <div className='mb-17'>
              <div className='d-flex align-items-center mb-14'>
                <div className='text-center flex-shrink-0 me-7 me-lg-13'>
                  <div className='symbol symbol-70px symbol-circle mb-2'>
                    <img src={toAbsoluteUrl(`/media/img/${author.avatar}`)} alt='' />
                  </div>
                </div>
                <div className='mb-0 fs-6'>
                  <h1 className='author-name'>{author.name}</h1>
                  <div className='text-muted fw-bold lh-lg mb-2'>{author.bio}</div>
                </div>
              </div>
            </div>
            {author.articles && (
              <div className='mb-17'>
                <div className='d-flex flex-stack mb-5'>
                  <h3 className='text-black'>Author Posts</h3>
                  {/* <a href="post" className="fs-6 fw-bold link-primary">View All</a> */}
                </div>
                <div className='separator separator-dashed mb-9'></div>
                <div className='row g-10'>
                  {author.articles.map((article, index) => (
                    <div className='col-md-6' key={index}>
                      <div className='card-xl-stretch me-md-6'>
                        <Link className='d-block overlay' to={`/community/article/${article.id}`}>
                          <div
                            className='overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px'
                            style={{
                              backgroundImage: `url('${toAbsoluteUrl(
                                `/media/img/posts/${article.image}`
                              )}')`,
                            }}
                          ></div>
                        </Link>
                        <div className='mt-5'>
                          <div className='fs-6 fw-bolder'>
                            <span className='text-muted'>
                              {toMMMddyyyy(article.publishDate)}
                            </span>
                          </div>
                          <Link
                            className='fs-4 text-dark fw-bolder text-hover-primary text-dark lh-base'
                            to={`/community/article/${article.id}`}
                          >
                            {article.title}
                          </Link>
                          <div className='fw-bold fs-5 text-gray-600 text-dark mt-3 mb-5'>
                            {`${article.miniDescription}...`}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          {/* <div className='flex-column flex-lg-row-auto w-100 w-xl-300px mb-10'>
            <div className='mb-16'>
              <h4 className='text-black mb-7'>Search Blog</h4>
              <div className='position-relative'>
                <span className='svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <rect
                      opacity='0.5'
                      x='17.0365'
                      y='15.1223'
                      width='8.15546'
                      height='2'
                      rx='1'
                      transform='rotate(45 17.0365 15.1223)'
                      fill='black'
                    ></rect>
                    <path
                      d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                      fill='black'
                    ></path>
                  </svg>
                </span>
                <input
                  type='text'
                  className='form-control form-control-solid ps-10'
                  name='search'
                  value=''
                  placeholder='Search'
                />
              </div>
            </div>
            <div className='mb-16'>
              <h4 className='text-black mb-7'>Categories</h4>
              <div className='d-flex flex-stack fw-bold fs-5 text-muted mb-4'>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                    <a href='project'>
                      <div className='symbol-label'>
                        <img
                          src={toAbsoluteUrl('/media/img/balancer.svg')}
                          alt='Balancer'
                          className='w-100 rounded-circle'
                        />
                      </div>
                    </a>
                  </div>
                  <a href='project' className='text-gray-800 text-hover-primary mb-1'>
                    Balancer
                  </a>
                </div>
                <div className='m-0'>24</div>
              </div>
              <div className='d-flex flex-stack fw-bold fs-5 text-muted mb-4'>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                    <a href='project'>
                      <div className='symbol-label'>
                        <img
                          src={toAbsoluteUrl('/media/img/rskswap.svg')}
                          alt='rskSwap'
                          className='w-100 rounded-circle'
                        />
                      </div>
                    </a>
                  </div>
                  <a href='project' className='text-gray-800 text-hover-primary mb-1'>
                    rskSwap
                  </a>
                </div>
                <div className='m-0'>152</div>
              </div>
              <div className='d-flex flex-stack fw-bold fs-5 text-muted mb-4'>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                    <a href='project'>
                      <div className='symbol-label'>
                        <img
                          src={toAbsoluteUrl('/media/img/polygon.svg')}
                          alt='Polygon'
                          className='w-100 rounded-circle'
                        />
                      </div>
                    </a>
                  </div>
                  <a href='project' className='text-gray-800 text-hover-primary mb-1'>
                    Polygon
                  </a>
                </div>
                <div className='m-0'>52</div>
              </div>
              <div className='d-flex flex-stack fw-bold fs-5 text-muted mb-4'>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                    <a href='project'>
                      <div className='symbol-label'>
                        <img
                          src={toAbsoluteUrl('/media/img/venus.svg')}
                          alt='Venus'
                          className='w-100 rounded-circle'
                        />
                      </div>
                    </a>
                  </div>
                  <a href='project' className='text-gray-800 text-hover-primary mb-1'>
                    Venus
                  </a>
                </div>
                <div className='m-0'>305</div>
              </div>
              <div className='d-flex flex-stack fw-bold fs-5 text-muted mb-4'>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                    <a href='project'>
                      <div className='symbol-label'>
                        <img
                          src={toAbsoluteUrl('/media/img/moc.svg')}
                          alt='Moc'
                          className='w-100 rounded-circle'
                        />
                      </div>
                    </a>
                  </div>
                  <a href='project' className='text-gray-800 text-hover-primary mb-1'>
                    MoC
                  </a>
                </div>
                <div className='m-0'>70</div>
              </div>
              <div className='d-flex flex-stack fw-bold fs-5 text-muted'>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                    <a href='project'>
                      <div className='symbol-label'>
                        <img
                          src={toAbsoluteUrl('/media/img/sovryn.svg')}
                          alt='Sovryn'
                          className='w-100 rounded-circle'
                        />
                      </div>
                    </a>
                  </div>
                  <a href='project' className='text-gray-800 text-hover-primary mb-1'>
                    Sovryn
                  </a>
                </div>
                <div className='m-0'>585</div>
              </div>
            </div>
            <div className='m-0'>
              <h4 className='text-black mb-7'>Learn More</h4>
              <div className='d-flex flex-stack mb-7'>
                <div className='symbol symbol-60px symbol-2by3 me-4'>
                  <div
                    className='symbol-label'
                    style={{
                      backgroundImage: `url('${toAbsoluteUrl('/media/img/thumb-1.jpg')}')`,
                    }}
                  ></div>
                </div>
                <div className='m-0'>
                  <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                    All about Bridges
                  </a>
                  <span className='text-gray-600 fw-bold d-block pt-1 fs-7'>
                    We’ve been a focused on making a the sky
                  </span>
                </div>
              </div>
              <div className='d-flex flex-stack mb-7'>
                <div className='symbol symbol-60px symbol-2by3 me-4'>
                  <div
                    className='symbol-label'
                    style={{
                      backgroundImage: `url('${toAbsoluteUrl('/media/img/thumb-2.jpg')}')`,
                    }}
                  ></div>
                </div>
                <div className='m-0'>
                  <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                    Chains and Exchanges
                  </a>
                  <span className='text-gray-600 fw-bold d-block pt-1 fs-7'>
                    We’ve been a focused on making a the sky
                  </span>
                </div>
              </div>
              <div className='d-flex flex-stack mb-7'>
                <div className='symbol symbol-60px symbol-2by3 me-4'>
                  <div
                    className='symbol-label'
                    style={{
                      backgroundImage: `url('${toAbsoluteUrl('/media/img/thumb-3.jpg')}')`,
                    }}
                  ></div>
                </div>
                <div className='m-0'>
                  <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                    All about Protocols
                  </a>
                  <span className='text-gray-600 fw-bold d-block pt-1 fs-7'>
                    We’ve been a focused on making a the sky
                  </span>
                </div>
              </div>
              <div className='d-flex flex-stack'>
                <div className='symbol symbol-60px symbol-2by3 me-4'>
                  <div
                    className='symbol-label'
                    style={{
                      backgroundImage: `url('${toAbsoluteUrl('/media/img/thumb-4.jpg')}')`,
                    }}
                  ></div>
                </div>
                <div className='m-0'>
                  <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                    Maker DAO, a use case
                  </a>
                  <span className='text-gray-600 fw-bold d-block pt-1 fs-7'>
                    We’ve been a focused on making a the sky
                  </span>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export {AuthorPage}
