import {FC} from 'react'
import {ScorableModel} from '../../models/ScorableModel'
import Medium from './Medium'
import Twitter from './Twitter'

const News: FC<{scorable: ScorableModel}> = ({scorable}) => {
  return (
    <div className='row g-6 g-xl-9 mb-6'>
      {scorable.medium && <Medium medium={scorable.medium} />}
      {scorable.twitter && <Twitter twitter={scorable.twitter} />}
    </div>
  )
}

export default News
