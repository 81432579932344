import clsx from 'clsx'
import React, {FC} from 'react'
import {ScoreModel} from '../../models/ScoreModel'

type Props = {
  score: ScoreModel
  name: string
  colorClassName: string
}

const ScoreBar: FC<Props> = ({score, name, colorClassName}) => {
  return (
    <div className='d-flex flex-stack mb-4'>
      <div className='me-4 w-60px w-sm-100px text-end fs-8'>{name}</div>
      <div className='flex-fill me-4 position-relative '>
        <div className='w-100 bg-light-primary h-25px position-absolute top-0 start-0 rounded-1'></div>
        <div
          className={clsx(
            score.percentage > 0 && colorClassName,
            'h-25px z-index-1 top-0 start-0 rounded-1 p-1 pe-2 text-end text-gray-800 position-relative fs-8 text-nowrap'
          )}
          style={{width: `${score.percentage}%`}}
        >
          {score.value}
        </div>
      </div>
      <div>{score.max}</div>
    </div>
  )
}

export default ScoreBar
