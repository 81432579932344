import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'

import * as home from '../../app/modules/home'
import * as scoring from '../../app/modules/scoring'
import * as project from '../../app/modules/project'
import * as socket from '../socket'
import * as github from '../../app/modules/project/github'

export const rootReducer = combineReducers({
  home: home.reducer,
  scoring: scoring.reducer,
  project: project.reducer,
  socket: socket.reducer,
  github: github.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([
    home.watchGetHomeDataAsync(),
    scoring.watchGetInitialDataAsync(),
    scoring.watchGetMoreDataAsync(),
    scoring.watchOnNotifications(),
    project.watchGetPeriodStoriesAsync(),
    github.watchGetGitHubEventsAsync(),
  ])
}
