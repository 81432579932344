import React from 'react'
import {MenuItem} from './MenuItem'
import {useIntl} from 'react-intl'

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      <MenuItem title={intl.formatMessage({id: 'MENU.SCORING'})} to='/scoring' />
      <MenuItem title={intl.formatMessage({id: 'MENU.WHATISD4B'})} to='/whatisd4b' />
      <MenuItem title={intl.formatMessage({id: 'MENU.COMMUNITY'})} to='/community' />
      <MenuItem title={intl.formatMessage({id: 'MENU.ROADMAP'})} to='/roadmap' />
      <MenuItem title={intl.formatMessage({id: 'MENU.FAQS'})} to='/faqs' />
    </>
  )
}
