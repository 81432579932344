import axios from 'axios'
import {ArticleModel} from '../models/ArticleModel'
import {AuthorModel} from '../models/AuthorModel'

const API_URL = process.env.REACT_APP_API_URL || 'api'
const CONTROLLER = 'Community'

export const GET_ARTICLE = `${API_URL}/${CONTROLLER}/getArticle`
export const GET_ARTICLES = `${API_URL}/${CONTROLLER}/getArticles`
export const GET_AUTHOR = `${API_URL}/${CONTROLLER}/getAuthor`
export const GET_AUTHORS = `${API_URL}/${CONTROLLER}/getAuthors`

export function getArticle(id: string) {
  return axios.get<ArticleModel>(GET_ARTICLE, {params: {id}})
}

export function getAuthor(id: string) {
  return axios.get<AuthorModel>(GET_AUTHOR, {params: {id}})
}

export function getArticles() {
  return axios.get<Array<ArticleModel>>(GET_ARTICLES)
}

export function getAuthors() {
  return axios.get<Array<AuthorModel>>(GET_AUTHORS)
}
