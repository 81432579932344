import {FC} from 'react'
import {ScorableModel} from '../../models/ScorableModel'
import ScoringPie from './ScoringPie'
import ScoringBars from './ScoringBars'
import ScoringPieDetails from './ScoringPieDetails'
import ScoringExplanation from './ScoringExplanation'

const D4B: FC<{scorable: ScorableModel}> = ({scorable}) => (
  <div>
    {scorable.scoring && (
      <div className='row g-6 g-xl-9'>
        <div className='col-lg-5'>
          <div className='card card-flush h-lg-100'>
            <div className='card-header mt-6'>
              <div className='card-title flex-column'>
                <h3 className='fw-bolder mb-1'>D4B Scoring Detail</h3>
              </div>
            </div>

            <div className='card-body p-9'>
              <div className='row g-6 g-xl-9 mt-1'>
                <div className='col-sm-12 mt-0'>
                  <div className='mx-auto' style={{maxWidth: '240px'}}>
                    <ScoringPie scoring={scorable.scoring!} />
                  </div>
                  <div className='row mt-10'>
                    <ScoringPieDetails scoring={scorable.scoring!} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-7'>
          <div className='card card-flush h-lg-100'>
            <div className='card-header mt-6'>
              <div className='card-title flex-column'>
                <h3 className='fw-bolder mb-1'>D4B Scoring Indexes</h3>
              </div>
            </div>

            <div className='card-body p-9'>
              <ScoringBars scoring={scorable.scoring!} />
            </div>
          </div>
        </div>
      </div>
    )}
    <div className='card card-flush h-lg-100 mt-10'>
      <div className='card-header mt-6'>
        <div className='card-title flex-column'>
          <h3 className='fw-bolder mb-1'>SCORING EXPLANATION</h3>
        </div>
      </div>
      <div className='card-body p-9'>
        <ScoringExplanation scoring={scorable.scoring!} />
      </div>
    </div>
  </div>
)

export default D4B
