/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'

const RoadmapPage: FC = () => {
  return (
    <div className='card'>
      <div className='card-body p-lg-20'>
        <h3 className='text-black mb-7'>Our Roadmap</h3>
        <p className='text-gray-700'>We are building a great ecosystem to add value to bitcoin.</p>
        <div className='separator separator-dashed mb-9'></div>
        <div className='row g-5'>
          <div className='d-flex flex-center flex-wrap'>
            <div className='mb-5 w-400px'>
              <div className='d-flex flex-column flex-lg-row mt-6'>
                <h3 className='fw-bolder text-primary fs-4 lh-base mb-5'>2022</h3>
              </div>
              <div
                className='stepper stepper-pills stepper-column d-flex flex-column flex-lg-row mt-6'
                id='kt_stepper_example_vertical'
                data-kt-stepper='true'
              >
                <div className='d-flex flex-wrap w-100 w-lg-600px'>
                  <div className='stepper-nav flex-cente'>
                    <div className='stepper-item me-5' data-kt-stepper-element='nav'>
                      <div className='stepper-line min-w-80px'></div>
                      <div className='stepper-icon min-w-80px h-80px'>
                        <i className='stepper-check fas fa-check'></i>
                        <span className='stepper-number'>Q2</span>
                      </div>
                      <div className='stepper-label'>
                        <h3 className='stepper-title'>D4B Launch</h3>
                        <div className='stepper-desc'>
                          Partnerships with bitcoin friendly projects
                        </div>
                      </div>
                    </div>
                    {/* TODO: Use "current" class for the current quarter div */}
                    <div className='stepper-item me-5 current' data-kt-stepper-element='nav'>
                      <div className='stepper-line min-w-80px'></div>
                      <div className='stepper-icon min-w-80px h-80px'>
                        <i className='stepper-check fas fa-check'></i>
                        <span className='stepper-number'>Q3</span>
                      </div>
                      <div className='stepper-label'>
                        <h3 className='stepper-title'>Protocols reviews</h3>
                        <div className='stepper-desc'>
                          Stacks
                          <br />
                          Algorand
                        </div>
                      </div>
                    </div>
                    <div className='stepper-item me-5' data-kt-stepper-element='nav'>
                      <div className='stepper-line min-w-80px'></div>
                      <div className='stepper-icon min-w-80px h-80px'>
                        <i className='stepper-check fas fa-check'></i>
                        <span className='stepper-number'>Q4</span>
                      </div>
                      <div className='stepper-label'>
                        <h3 className='stepper-title'>D4B club</h3>
                        <div className='stepper-desc'>
                          Avalanche
                          <br />
                          Polkadot
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-flex flex-column flex-lg-row mt-6'>
                <h3 className='fw-bolder text-primary fs-4 lh-base mb-5'>2023</h3>
              </div>
              <div
                className='stepper stepper-pills stepper-column d-flex flex-column flex-lg-row mt-6'
                id='kt_stepper_example_vertical'
                data-kt-stepper='true'
              >
                <div className='d-flex flex-wrap w-100 w-lg-600px'>
                  <div className='stepper-nav flex-cente'>
                    <div className='stepper-item me-5' data-kt-stepper-element='nav'>
                      <div className='stepper-line min-w-80px'></div>
                      <div className='stepper-icon min-w-80px h-80px'>
                        <i className='stepper-check fas fa-check'></i>
                        <span className='stepper-number'>Q1</span>
                      </div>
                      <div className='stepper-label'>
                        <h3 className='stepper-title'>Bitcon Conference 2023</h3>
                        <div className='stepper-desc'>Terra</div>
                        <div className='stepper-desc'>Terra</div>
                      </div>
                    </div>
                    <div className='stepper-item me-5' data-kt-stepper-element='nav'>
                      <div className='stepper-line min-w-80px'></div>
                      <div className='stepper-icon min-w-80px h-80px'>
                        <i className='stepper-check fas fa-check'></i>
                        <span className='stepper-number'>Q2</span>
                      </div>
                      <div className='stepper-label'>
                        <h3 className='stepper-title'>D4B DAO</h3>
                        <div className='stepper-desc'>
                          Stellar
                          <br />
                          Fantom
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {RoadmapPage}
