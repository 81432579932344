import React, {FC} from 'react'
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js'
import {Pie} from 'react-chartjs-2'
import {ScoringModel} from '../../models/ScoringModel'

ChartJS.register(ArcElement, Tooltip, Legend)

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false as const,
    },
  },
}

type Props = {
  scoring: ScoringModel
}

const ScoringPie: FC<Props> = ({scoring}) => {
  const data = {
    labels: [
      'Bitcoin Affinity Index',
      'Security Index',
      'Decentralization Index',
      'Privacy Index',
      'Scalability Index',
      'Fairness Index',
      'Economic Index',
      'Community Traction Index',
      'Oracle Index',
    ],
    datasets: [
      {
        label: '',
        data: [
          scoring.bitcoinAffinityIndex,
          scoring.securityIndex,
          scoring.decentralizationIndex,
          scoring.privacyIndex,
          scoring.scalabilityIndex,
          scoring.fairnessIndex,
          scoring.economicIndex,
          scoring.communityTractionIndex,
          scoring.oracleIndex,
        ],
        backgroundColor: [
          '#cfe2ff', //blue
          '#c1b3d9', //violet
          '#f7d6e6', //pink
          '#f1aeb5', //red
          '#ffe5d0', //orange
          '#fff3cd', //yellow
          '#d1e7dd', //green
          '#cff4fc', //lighblue
          '#f8f9fa', //gray
        ],
        borderColor: [
          '#9ec5fe', //blue
          '#c29ffa', //violet
          '#efadce', //pink
          '#ea868f', //red
          '#fecba1', //orange
          '#ffe69c', //yellow
          '#a3cfbb', //green
          '#9eeaf9', //lighblue
          '#e9ecef', //lightgreen
        ],
        borderWidth: 1,
      },
    ],
  }

  return <Pie options={options} data={data} />
}

export default ScoringPie
