import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {call, put, takeLatest, take} from 'redux-saga/effects'
import {getInitialData, getMoreData} from './ScoringApi'
import {eventChannel} from 'redux-saga'
import {createWebSocket} from '../../../../setup/socket/Signal'
import {IActionWithPayload} from '../../../interfaces/IActionWithPayload'
import {ScoringModel, handleNotification} from './ScoringReduxHelpers'
import {InitialDataModel} from '../../project/models/InitialDataModel'

export const actionTypes = {
  InitialDataRequested: 'INITIAL_DATA_REQUESTED',
  InitialDataReceived: 'INITIAL_DATA_RECEIVED',
  InitialDataFailed: 'INITIAL_DATA_FAILED',
  MoreDataRequested: 'MORE_DATA_REQUESTED',
  MoreDataReceived: 'MORE_DATA_RECEIVED',
  MoreDataFailed: 'MORE_DATA_FAILED',
  NotificationReceived: 'NOTIFICATION_RECEIVED',
}

const initialState: ScoringModel = {
  data: {scorables: []},
  loading: false,
  loadingMoreData: false,
}

// this function creates an event channel from a given socket
// Setup subscription to incoming `ping` events
function createSocketChannel(socket: any) {
  // `eventChannel` takes a subscriber function
  // the subscriber function takes an `emit` argument to put messages onto the channel
  return {
    socketChannel: eventChannel((emit) => {
      const notificationHandler = (notification: any) => {
        // puts event payload into the channel
        // this allows a Saga to take this payload from the returned channel
        emit(notification)
      }

      // setup the subscription
      socket.on('ReceiveNotification', notificationHandler)

      // the subscriber must return an unsubscribe function
      // this will be invoked when the saga calls `channel.close` method
      const unsubscribe = () => {
        socket.off('ReceiveNotification', notificationHandler)
      }

      return unsubscribe
    }),
  }
}

export function* watchOnNotifications() {
  const {socket} = yield call(createWebSocket)
  const {socketChannel} = yield call(createSocketChannel, socket)

  while (true) {
    try {
      // An error from socketChannel will cause the saga jump to the catch block
      const {payload} = yield take(socketChannel)

      actions.receiveNotification(payload)
    } catch (err) {
      console.error('socket error:', err)
      // socketChannel is still open in catch block
      // if we want end the socketChannel, we need close it explicitly
      // socketChannel.close()
    }
  }
}

export const reducer = persistReducer(
  {storage, key: 'defi4bitcoin-scoring', whitelist: ['scorables']},
  (state: ScoringModel = initialState, action: IActionWithPayload<any>) => {
    switch (action.type) {
      case actionTypes.InitialDataRequested: {
        return {
          ...state,
          loading: true,
        }
      }

      case actionTypes.InitialDataReceived: {
        const data = action.payload
        return {
          ...state,
          data,
          loading: false,
        }
      }

      case actionTypes.InitialDataFailed: {
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        }
      }

      case actionTypes.MoreDataRequested: {
        return {
          ...state,
          loadingMoreData: true,
        }
      }

      case actionTypes.MoreDataReceived: {
        const scorables = action.payload
        return {
          ...state,
          scorables,
          loadingMoreData: false,
        }
      }

      case actionTypes.MoreDataFailed: {
        return {
          ...state,
          loadingMoreData: false,
          error: action.payload.error,
        }
      }

      case actionTypes.NotificationReceived: {
        return handleNotification(action.payload, state)
      }

      default:
        return state
    }
  }
)

export const actions = {
  requestInitialData: () => ({
    type: actionTypes.InitialDataRequested,
  }),
  receiveInitialData: (initialData: InitialDataModel) => ({
    type: actionTypes.InitialDataReceived,
    payload: initialData,
  }),
  initialDataFailed: (message: string) => ({
    type: actionTypes.InitialDataFailed,
    payload: message,
  }),
  requestMoreData: () => ({
    type: actionTypes.MoreDataRequested,
  }),
  receiveMoreData: (initialData: InitialDataModel) => ({
    type: actionTypes.MoreDataReceived,
    payload: initialData,
  }),
  moreDataFailed: (message: string) => ({
    type: actionTypes.MoreDataFailed,
    payload: message,
  }),
  receiveNotification: (notification: any) => ({
    type: actionTypes.NotificationReceived,
    notification,
  }),
}

function* fetchInitialData() {
  try {
    const {data: initialData} = yield call(getInitialData)
    yield put(actions.receiveInitialData(initialData))
  } catch (e: any) {
    console.error(e)
    yield put(actions.initialDataFailed(e.message))
  }
}

function* fetchMoreData() {
  try {
    const {data: moreData} = yield call(getMoreData)
    yield put(actions.receiveMoreData(moreData))
  } catch (e: any) {
    console.error(e)
    yield put(actions.moreDataFailed(e.message))
  }
}

export function* watchGetInitialDataAsync() {
  yield takeLatest(actionTypes.InitialDataRequested, fetchInitialData)
}

export function* watchGetMoreDataAsync() {
  yield takeLatest(actionTypes.MoreDataRequested, fetchMoreData)
}
