/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {ScoringTable} from './ScoringTable'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../setup/redux/RootReducer'
import {ScoringFormatter} from './ScoringFormatter'
import clsx from 'clsx'
import {Filter} from './Filter'
import {ScorableType} from '../../../enums/ScorableType'
import {useSearchParams, createSearchParams} from 'react-router-dom'
import {ScorableId} from '../../../enums/ScorableId'

const typeParam = 'type'
const blockchainParam = 'blockchain'

const ScoringPage: FC = () => {
  const scoring = useSelector((state: RootState) => state.scoring)
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (!searchParams.has(typeParam)) {
      setSearchParams(createSearchParams({type: ScorableType.FinancialApp.toString()}))
    }
  }, [searchParams, setSearchParams])

  if (scoring.loading) return <div>Loading...</div>

  const handleTypeChange = (projectType: ScorableType) => {
    const newSearchParams = createSearchParams(searchParams)
    newSearchParams.set(typeParam, projectType.toString())
    setSearchParams(newSearchParams)
  }

  const handleBlockchainsChange = (blockchains: ScorableId[]) => {
    const newSearchParams = createSearchParams(searchParams)
    newSearchParams.delete(blockchainParam)
    blockchains.forEach((blockchain) =>
      newSearchParams.append(blockchainParam, blockchain.toString())
    )
    setSearchParams(newSearchParams)
  }

  const typeInt = parseInt(searchParams.get(typeParam)!)
  const type = typeInt as ScorableType

  const blockchains = searchParams.has(blockchainParam)
    ? (searchParams
        .getAll(blockchainParam)
        .filter((x) => !isNaN(parseInt(x)))
        .map((x) => parseInt(x)) as ScorableId[])
    : scoring.data.scorables
        .filter((scorable) => scorable.type === ScorableType.Blockchain)
        .map((x) => x.id)

  return (
    <div className='card'>
      <div className='card-header border-0 pt-6'>
        <div className='card-title my-0'>
          <ul className='nav mx-auto flex-shrink-1 flex-nowrap flex-center flex-wrap border-transparent fs-6 fw-bolder d-block d-sm-flex'>
            <li className='nav-item my-0 my-sm-3'>
              <button
                onClick={() => handleTypeChange(ScorableType.FinancialApp)}
                className={clsx(
                  'btn btn-active-light-primary fw-boldest nav-link btn-color-gray-700 px-3 px-lg-8 mx-1 text-uppercase',
                  type === ScorableType.FinancialApp && 'active'
                )}
              >
                Financial Dapps
              </button>
            </li>
            <li className='nav-item my-0 my-sm-3'>
              <button
                onClick={() => handleTypeChange(ScorableType.Token)}
                className={clsx(
                  'btn btn-active-light-primary fw-boldest nav-link btn-color-gray-700 px-3 px-lg-8 mx-1 text-uppercase',
                  type === ScorableType.Token && 'active'
                )}
              >
                Bitcoin-pegged Tokens
              </button>
            </li>
            <li className='nav-item my-0 my-sm-3'>
              <button
                onClick={() => handleTypeChange(ScorableType.Blockchain)}
                className={clsx(
                  'btn btn-active-light-primary fw-boldest nav-link btn-color-gray-700 px-3 px-lg-8 mx-1 text-uppercase',
                  type === ScorableType.Blockchain && 'active'
                )}
              >
                Blockchains
              </button>
            </li>
          </ul>
        </div>

        {type !== ScorableType.Blockchain && (
          <div className='card-toolbar align-items-start align-items-sm-center'>
            <Filter blockchains={blockchains} onChangeBlockchains={handleBlockchainsChange} />
          </div>
        )}
      </div>
      <div className='card-body pt-0'>
        <ScoringFormatter scorables={scoring.data.scorables} blockchains={blockchains} type={type}>
          <ScoringTable type={type} />
        </ScoringFormatter>
      </div>
    </div>
  )
}

export {ScoringPage}
