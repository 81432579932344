import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'api'
const CONTROLLER = 'Contact'

export const SEND_MESSAGE = `${API_URL}/${CONTROLLER}/sendMessage`

export function sendMessage(fullname: string, email: string, message: string) {
  return axios.post<boolean>(SEND_MESSAGE, {fullname, email, message})
}
