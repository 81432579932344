import {FC} from 'react'
import {ScoringModel} from '../../models/ScoringModel'
import {MathJax} from 'better-react-mathjax'

const ScoringExplanation: FC<{scoring: ScoringModel}> = ({scoring}) => (
  <>
    <div className='row g-6 g-xl-9'>
      <div className='col-lg-6'>
        <h3 className='text-dark mb-7'>Project score</h3>
        <p className='fs-6 text-gray-700'>
          <MathJax>{'Project score \\( = \\sum_{i=1}^{n} ValueOfIndex_{i}\\)'}</MathJax>
        </p>
        <p className='fs-6 text-gray-700'>
          <MathJax>{`Project score \\( = ${scoring.bitcoinAffinityIndex.value} + ${scoring.securityIndex.value} + ${scoring.decentralizationIndex.value} + ${scoring.privacyIndex.value} + ${scoring.scalabilityIndex.value} + ${scoring.fairnessIndex.value} + ${scoring.economicIndex.value} + ${scoring.communityTractionIndex.value} + ${scoring.oracleIndex.value}\\)`}</MathJax>
        </p>
        <p className='fs-6 text-gray-700'>
          <MathJax>{`Project score \\(= ${scoring.score.value}\\)`}</MathJax>
        </p>
      </div>
      <div className='col-lg-6'>
        <h3 className='text-dark mb-7'>Maximum score</h3>
        <p className='fs-6 text-gray-700'>
          <MathJax>{'Maximum score \\( = \\sum_{i=1}^{n} MaxValueOfIndex_{i}\\)'}</MathJax>
        </p>
        <p className='fs-6 text-gray-700'>
          <MathJax>{`Maximum score \\( = ${scoring.bitcoinAffinityIndex.max} + ${scoring.securityIndex.max} + ${scoring.decentralizationIndex.max} + ${scoring.privacyIndex.max} + ${scoring.scalabilityIndex.max} + ${scoring.fairnessIndex.max} + ${scoring.economicIndex.max} + ${scoring.communityTractionIndex.max} + ${scoring.oracleIndex.max}\\)`}</MathJax>
        </p>
        <p className='fs-6 text-gray-700'>
          <MathJax>{`Maximum score \\(= ${scoring.score.max}\\)`}</MathJax>
        </p>
      </div>
    </div>

    <h3 className='text-dark my-7'>D4B score</h3>
    <p className='fs-6 text-gray-700'>
      <MathJax>{`D4B score \\(= {{Project\\_score \\over Maximum\\_score} * 100}\\)`}</MathJax>
    </p>
    <p className='fs-6 text-gray-700'>
      <MathJax>{`D4B score \\(= {{${scoring.score.value} \\over ${scoring.score.max}} * 100}\\)`}</MathJax>
    </p>
    <p className='fs-6 text-gray-700'>
      <MathJax>{`D4B score \\(= ${scoring.score.percentage.toFixed(1)}\\)`}</MathJax>
    </p>
  </>
)

export default ScoringExplanation
