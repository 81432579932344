/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {toMMMddyyyy} from '../../../utils/date'
import {ArticleModel} from '../models/ArticleModel'
import {getArticles} from '../redux/CommunityApi'

const CommunityPage: FC = () => {
  const [articles, setArticles] = useState<ArticleModel[]>([])
  useEffect(() => {
    const fetchArticles = async () => {
      const articles = await getArticles()
      setArticles(articles.data)
    }
    fetchArticles()
  }, [])

  return (
    <div className='card'>
      <div className='card-body p-lg-20'>
        <div className='mb-17'>
          <h3 className='text-black mb-7'>Latest Articles, News &amp; Updates</h3>
          <div className='separator separator-dashed mb-9'></div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='h-100 d-flex flex-column pe-lg-6 mb-lg-0 mb-10'>
                <div className='mb-15'>
                  <div className='fw-bold fs-5 text-gray-600 text-dark mt-4'>
                    D4B Community is a space where your ideas and participation are valuable. Being
                    part of our community allows you...
                  </div>
                </div>

                <div className='d-flex align-items-center rounded py-2 px-2 bg-light-primary mw-600px mb-5'>
                  <div className='text-gray-700 fw-bold fs-6'>
                    <ul className='m-0'>
                      <li className='d-flex align-items-center py-2 fs-5'>
                        <span className='bullet me-5'></span> Submit new protocols to review
                      </li>
                    </ul>
                  </div>
                </div>

                <div className='d-flex align-items-center rounded py-2 px-2 bg-light-primary mw-600px mb-5'>
                  <div className='text-gray-700 fw-bold fs-6'>
                    <ul className='m-0'>
                      <li className='d-flex align-items-center py-2 fs-5'>
                        <span className='bullet me-5'></span> Make reviews and to receive rewards
                        for it
                      </li>
                    </ul>
                  </div>
                </div>

                <div className='d-flex align-items-center rounded py-2 px-2 bg-light-primary mw-600px mb-5'>
                  <div className='text-gray-700 fw-bold fs-6'>
                    <ul className='m-0'>
                      <li className='d-flex align-items-center py-2 fs-5'>
                        <span className='bullet me-5'></span> Participate in the scoring
                        improvements
                      </li>
                    </ul>
                  </div>
                </div>

                <div className='d-flex align-items-center rounded py-2 px-2 bg-light-primary mw-600px mb-5'>
                  <div className='text-gray-700 fw-bold fs-6'>
                    <ul className='m-0'>
                      <li className='d-flex align-items-center py-2 fs-5'>
                        <span className='bullet me-5'></span> Join to social activities on the
                        digital and the physical world
                      </li>
                    </ul>
                  </div>
                </div>

                <div className='d-flex align-items-center rounded py-2 px-2 bg-light-primary mw-600px mb-5'>
                  <div className='text-gray-700 fw-bold fs-6'>
                    <ul className='m-0'>
                      <li className='d-flex align-items-center py-2 fs-5'>
                        <span className='bullet me-5'></span> Take part in the D4B governance
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='mb-17'>
          <div className='d-flex flex-stack mb-5'>
            <h3 className='text-black'>Articles</h3>
            {/* <a href="post" className="fs-6 fw-bold link-primary">View All</a> */}
          </div>
          <div className='separator separator-dashed mb-9'></div>
          <div className='row g-10'>
            {articles.map((article, index) => (
              <div className='col-md-4' key={index}>
                <div className='card-xl-stretch me-md-6'>
                  <div
                    className='overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px'
                    style={{
                      backgroundImage: `url('${toAbsoluteUrl(
                        `/media/img/posts/${article.image}`
                      )}')`,
                    }}
                  ></div>
                  <div className='mt-5'>
                    <Link
                      className='fs-4 text-dark fw-bolder text-hover-primary lh-base'
                      to={`/community/article/${article.id}`}
                    >
                      {article.title}
                    </Link>
                    <div className='fw-bold fs-5 text-gray-600 text-dark mt-3'>
                      {`${article.miniDescription}...`}
                    </div>
                    <div className='fs-6 fw-bolder mt-3'>
                      <Link
                        className='text-gray-700 fw-bolder text-hover-primary'
                        to={`/community/author/${article.author.id}`}
                      >
                        {article.author.name}
                      </Link>
                      <span className='text-muted'>
                        {` on ${toMMMddyyyy(article.publishDate)}`}
                      </span>
                    </div>
                    <div className='fs-6 fw-bolder mt-5 d-flex flex-stack'>
                      <Link className='btn btn-primary' to={`/community/article/${article.id}`}>
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className='mb-17'>
          <div className='d-flex flex-stack mb-5'>
            <h3 className='text-black'>Our partners</h3>
          </div>
          <div className='card mb-4 text-center'>
            <div className='card-body py-12'>
              <a target='_blank' rel='noreferrer' href='https://moneyonchain.com/' className='mx-8'>
                <img
                  src={toAbsoluteUrl('/media/img/moc.svg')}
                  alt='Money On Chain'
                  className='w-150px rounded-circle'
                />
              </a>
              <a target='_blank' rel='noreferrer' href='https://defiantapp.tech/' className='mx-8'>
                <img
                  src={toAbsoluteUrl('/media/img/defiant.svg')}
                  alt='Defiant'
                  className='w-150px rounded-circle'
                />
              </a>
              <a target='_blank' rel='noreferrer' href='https://www.rsk.co/' className='mx-8'>
                <img
                  src={toAbsoluteUrl('/media/img/rsk.svg')}
                  alt='RSK'
                  className='w-150px rounded-circle'
                />
              </a>
              <a
                target='_blank'
                rel='noreferrer'
                href='https://www.wakeupnft.xyz/'
                className='mx-8'
              >
                <img
                  src={toAbsoluteUrl('/media/img/wakeup.png')}
                  alt='WakeUp'
                  className='w-150px rounded-circle'
                />
              </a>
            </div>
          </div>
          <div className='text-center d-flex bg-light flex-column justify-content-center p-10 h-100'>
            <h1 className='text-dark fw-bolder my-5'>Are you a bitcoin lover?</h1>
            <Link to='/contactus' className='fs-2 text-gray-700 text-hover-primary'>
              Join us!
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export {CommunityPage}
