import axios from 'axios'
import {ScorableId} from '../../../../enums/ScorableId'
import {GitHubEventsModel} from '../models/GitHubEventsModel'

const API_URL = process.env.REACT_APP_API_URL || 'api'
const CONTROLLER = `GitHub`

export const GET_EVENTS_URL = `${API_URL}/${CONTROLLER}/getEvents`

export function getEvents(scorableId: ScorableId) {
  return axios.get<Array<GitHubEventsModel>>(GET_EVENTS_URL, {params: {scorableId}})
}
