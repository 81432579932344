import {call, put, takeLatest} from 'redux-saga/effects'
import {LogoScorableModel} from '../models/LogoScorableModel'
import {LogoScorableReduxModel} from '../models/LogoScorableReduxModel'
import {getLogoData} from '../../scoring/redux/ScoringApi'
import {IActionWithPayload} from '../../../interfaces/IActionWithPayload'

export const actionTypes = {
  LogoDataRequested: 'LOGO_DATA_REQUESTED',
  LogoDataReceived: 'LOGO_DATA_RECEIVED',
  LogoDataFailed: 'LOGO_DATA_FAILED',
}

const initialState: LogoScorableReduxModel = {
  scorables: [],
  loading: true,
}

export const reducer = (
  state: LogoScorableReduxModel = initialState,
  action: IActionWithPayload<any>
): LogoScorableReduxModel => {
  switch (action.type) {
    case actionTypes.LogoDataRequested: {
      return {
        ...state,
        scorables: [],
        loading: true,
      }
    }

    case actionTypes.LogoDataReceived: {
      const scorables = action.payload
      return {
        ...state,
        scorables,
        loading: false,
      }
    }

    case actionTypes.LogoDataFailed: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    }

    default:
      return state
  }
}

export const actions = {
  requestLogoData: () => ({
    type: actionTypes.LogoDataRequested,
  }),
  receiveLogoData: (scorables: LogoScorableModel[]) => ({
    type: actionTypes.LogoDataReceived,
    payload: scorables,
  }),
  LogoDataFailed: (message: string) => ({
    type: actionTypes.LogoDataFailed,
    payload: message,
  }),
}

function* fetchLogoData() {
  try {
    const {data: scorables} = yield call(getLogoData)
    yield put(actions.receiveLogoData(scorables))
  } catch (e: any) {
    console.log(e)
    yield put(actions.LogoDataFailed(e.message))
  }
}

export function* watchGetHomeDataAsync() {
  yield takeLatest(actionTypes.LogoDataRequested, fetchLogoData)
}
