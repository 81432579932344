import {FC} from 'react'
import {BtcLockedEvolution} from './BtcLockedEvolution'
import DeveloperActivity from './DeveloperActivity'
import {ScorableModel} from '../../models/ScorableModel'
import Video from './Video'
import About from './About'

const Overview: FC<{scorable: ScorableModel}> = ({scorable}) => (
  <div className='row g-6 g-xl-9'>
    <div className='col-lg-6'>
      <About scorable={scorable} />
      {scorable.video && <Video scorable={scorable} />}
    </div>
    <div className='col-lg-6'>
      <BtcLockedEvolution scorableId={scorable.id} />
      <DeveloperActivity scorableId={scorable.id} />
    </div>
  </div>
)

export default Overview
