import {DateTime} from 'luxon'

const serverZone = process.env.REACT_APP_SERVER_ZONE
const locale = 'en'
const options = {zone: serverZone}

function parse(date: any) {
  const dateTime =
    typeof date.getMonth === 'function'
      ? DateTime.fromJSDate(date, options)
      : DateTime.fromISO(date, options)
  return dateTime.setLocale(locale).toLocal()
}

export function toMMMddyyyy(date: any): string {
  var d = parse(date)
  return d.toLocaleString({month: 'short', day: '2-digit', year: 'numeric'})
}

export function toMMMdd(inputFormat: string): string {
  var d = parse(inputFormat)
  return d.toLocaleString({month: 'short', day: '2-digit'})
}

export function toLocaleDateTimeShortString(date: any): string {
  const d = parse(date)
  return d.toLocaleString(DateTime.DATETIME_SHORT)
}
