/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {Navigate, useParams} from 'react-router-dom'
import {AuthorPage} from '../../modules/community/components/AuthorPage'

const AuthorWrapper: FC = () => {
  const {name} = useParams()

  if (!name) {
    return <Navigate to='/error/404' />
  }

  return (
    <>
      {/* begin::Post */}
      <AuthorPage name={name} />
      {/* end::Post */}
    </>
  )
}

export {AuthorWrapper}
