import {FC} from 'react'
import {ScorableModel} from '../../models/ScorableModel'

const Video: FC<{scorable: ScorableModel}> = ({scorable}) => (
  <div className='card card-flush'>
    <div className='card-header mt-6'>
      <div className='card-title flex-column'>
        <h3 className='fw-bolder mb-1'>Project video</h3>
        <div className='fs-6 fw-bold text-gray-400'>Explaining {scorable.name}</div>
      </div>
    </div>
    <div className='card-body p-9 pt-5'>
      <div>
        <iframe
          title='youtube'
          className='embed-responsive-item card-rounded h-300px w-100'
          src={`https://www.youtube.com/embed/${scorable.video}`}
          allowFullScreen={true}
        ></iframe>
      </div>
    </div>
  </div>
)

export default Video
