import {FC, useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Select, {CSSObjectWithLabel, SingleValue} from 'react-select'
import {RootState} from '../../../../../setup'
import {PeriodEnum} from '../../../../enums/PeriodEnum'
import {ScorableId} from '../../../../enums/ScorableId'
import {UnitEnum} from '../../../../enums/UnitEnum'
import {IReactSelectOption} from '../../../../interfaces/IReactSelectOption'
import {actions} from '../../redux/ProjectRedux'
import {BtcLockedGraph} from './btcLocked/BtcLockedGraph'

const periodsOptions: IReactSelectOption<PeriodEnum>[] = [
  {value: PeriodEnum.OneDay, label: '1D'},
  {value: PeriodEnum.FiveDays, label: '5D'},
  {value: PeriodEnum.OneMonth, label: '1M'},
  {value: PeriodEnum.SixMonths, label: '6M'},
  {value: PeriodEnum.OneYear, label: '1Y'},
]

const unitsOptions: IReactSelectOption<UnitEnum>[] = [
  {value: UnitEnum.Btc, label: 'BTC'},
  {value: UnitEnum.Usd, label: 'USD'},
]

const selectStyles = {
  indicatorSeparator: () => ({}),
  control: (base: CSSObjectWithLabel) => ({
    ...base,
    border: 0,
    boxShadow: 'none',
    backgroundColor: '#F5F8FA',
  }),
}

const BtcLockedEvolution: FC<{scorableId: ScorableId}> = ({scorableId: projectId}) => {
  const [period, setPeriod] = useState<PeriodEnum>(PeriodEnum.OneMonth)
  const [unit, setUnit] = useState<UnitEnum>(UnitEnum.Btc)
  const ref = useRef<any>(null)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.requestPeriodStories(projectId, period))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, period])

  const project = useSelector((state: RootState) => state.project)

  const handlePeriodChange: (
    newValue: SingleValue<IReactSelectOption<PeriodEnum> | undefined>
  ) => void = (newValue) => {
    setPeriod(newValue!.value)
  }

  const handleUnitChange: (
    newValue: SingleValue<IReactSelectOption<UnitEnum> | undefined>
  ) => void = (newValue) => {
    setUnit(newValue!.value)
  }

  return (
    <div className='card card-flush mb-5'>
      <div className='card-header mt-6'>
        <div className='card-title flex-column'>
          <h3 className='fw-bolder mb-1'>Total Bitcoin Locked</h3>
        </div>
        <div className='card-toolbar'>
          <Select
            styles={selectStyles}
            options={unitsOptions}
            onChange={handleUnitChange}
            value={unitsOptions.find((x) => x.value === unit)}
            className='fw-bolder w-110px me-4'
          />
          <Select
            styles={selectStyles}
            options={periodsOptions}
            onChange={handlePeriodChange}
            value={periodsOptions.find((x) => x.value === period)}
            className='fw-bolder w-110px'
          />
        </div>
      </div>
      <div className='card-body pt-10 pb-0 px-5' ref={ref}>
        <BtcLockedGraph
          project={project}
          parentWidth={ref.current?.offsetWidth}
          period={period}
          unit={unit}
        />
      </div>
    </div>
  )
}

export {BtcLockedEvolution}
