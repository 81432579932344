import React, {PropsWithChildren, useEffect} from 'react'
import {useLocation} from 'react-router'
import {DrawerComponent} from '../../assets/ts/components'

const Content: React.FC<PropsWithChildren<{}>> = ({children}) => {
  const location = useLocation()
  useEffect(() => {
    DrawerComponent.hideAll()
  }, [location])

  // TODO: Make the following nicer, please
  if (window.location.pathname.startsWith('/home')) {
    document.body.classList.add('home-page')
  } else {
    document.body.classList.remove('home-page')
  }
  if (window.location.pathname.startsWith('/contactus')) {
    document.body.classList.add('contact-page')
  } else {
    document.body.classList.remove('contact-page')
  }

  return (
    <>
      {/* begin::Container */}
      <div id='kt_content' className='content flex-row-fluid'>
        {children}
      </div>
      {/* end::Container */}
    </>
  )
}

export {Content}
